.parentContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    gap: 20px;
}

.titleCard {
    display: flex;
    width: 100%;
    height: fit-content;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(232, 237, 241, 1);
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    padding: 20px;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.metric {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
}

.description {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: rgba(107, 107, 107, 1);
}

.regularLabel {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #000000;
}


@media only screen and (min-width: 350px) and (max-width: 800px) {
    .title {
        font-size: 18px;
        line-height: 18px;
    }
    .metric {
        font-size: 20px;
        line-height: 20px;
    }

    .description {
        font-size: 14px;
        line-height: 14px;
    }

    .regularLabel {
        font-size: 14px;
        line-height: 14px;
    }
}

.courseInfoLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #0b6f85;
}

.clickableCourseInfoLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-decoration: underline;
    color: #0b6f85;
    cursor: pointer;
}

.filterController {
    display: flex;
    align-items: center;
}

.datePickerInputBox {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border-radius: 4px;
    border: 1px solid rgba(111, 111, 111, 1);
    cursor: pointer;
    height: 45px;
}

.metricCardsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    align-items: center;
    gap: 30px;
}

.metricCard {
    position: relative;
    flex: 1;
    min-width: 210px;
    height: 165px;
    border-radius: 7px;
    border: 1px solid rgba(232, 237, 241, 1);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    gap: 10px;
}

.metricCardBody {
    display: flex;
    align-items: center;
}

.firstMetricCard {
    /*background: linear-gradient(145deg, #f2ceff 0%, #f4d5ff 4%, #f8e2ff 8%, #fbefff 12%, #fdf5ff 16%, #ffffff 20%);*/
    /*background: linear-gradient(145deg, #C728FF -75%,  #ffffff 25%);*/
    background: radial-gradient(circle at 0px 0px, #C728FF -80%, #ffffff 30%);
}

.secondMetricCard {
    background: radial-gradient(circle at 0px 0px, #8852D2 -80%, #ffffff 30%);
}

.thirdMetricCard {
    background: radial-gradient(circle at 0px 0px, #7064B7 -80%, #ffffff 30%);
}

.fourthMetricCard {
    background: radial-gradient(circle at 0px 0px, #1F6DD4 -80%, #ffffff 30%);
}

.fifthMetricCard {
    background: radial-gradient(circle at 0px 0px, #16789B -80%, #ffffff 30%);

}

.metricCardDivideLine {
    width: 100%;
    height: 1px;
    background: rgba(239, 240, 240, 1);
}

.metricCardLastMonth {
    display: flex;
    width: fit-content;
    padding: 4px 10px;
    border-radius: 16px;
    color: rgba(76, 76, 76, 1);
    font-size: 14px;
    font-weight: 400;
}

.metricCardLastMonthMetric {
    font-weight: 700;
    margin-right: 8px;
}


.lineChartCardContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 #00000040;
    overflow: hidden;
}

.cardContainerHead {
    width: 100%;
    padding: 10px 20px;
    height: 50px;
    background: #FCFCFC;
    border-bottom: 1px solid #EFEFEF;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cardContainerBody {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}


.chartControllerContainer {
    display: flex;
    max-width: 250px;
    flex-direction: column;
    gap: 20px;
}

.lineChartControllerRow {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chartContainer {
    flex: 1;
    min-width: 450px;
    display: flex;
    padding: 30px;
    margin-bottom: 20px;
    position: relative;
}

.overallDiagramDot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #4F75CB;
}

.overallDiagramCountLabel {
    min-width: 45px;
    text-align: center;
    background: #4F75CB40;
    border-radius: 25px;
    padding: 5px 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
}

.pollDiagramDot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #E2726E;
}

.pollDiagramCountLabel {
    min-width: 45px;
    text-align: center;
    background: #E2726E40;
    border-radius: 25px;
    padding: 5px 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
}

.attendanceDiagramDot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #6DA186;
}

.attendanceDiagramCountLabel {
    min-width: 45px;
    text-align: center;
    background: #6DA18640;
    border-radius: 25px;
    padding: 5px 12px;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
}

.chartTooltips {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: #4C4C4C;
}


.chartTooltips.middle:after{
    content: '';
    background-color: #4C4C4C;
    position: absolute;
    right: calc(50% - 6px);
    bottom: -4px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

.chartTooltips.first:after{
    content: '';
    background-color: #4C4C4C;
    position: absolute;
    left: 34px;
    bottom: -4px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

.chartTooltips.last:after{
    content: '';
    background-color: #4C4C4C;
    position: absolute;
    right: 24px;
    bottom: -4px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}


.pieChartTooltips {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: #4C4C4C;
}

.pieChartTooltips.left:after{
    content: '';
    background-color: #4C4C4C;
    position: absolute;
    bottom: calc(50% - 5.5px);
    right: -4px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

.pieChartTooltips.right:after{
    content: '';
    background-color: #4C4C4C;
    position: absolute;
    bottom: calc(50% - 5.5px);
    left: -4px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}

/*.left{*/

/*    transform: translate(calc(-100% - 30px),-80px)*/
/*}*/
/*.right {*/
/*    transform: translate(10px, -100%)*/
/*}*/

.right:after {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    background-color: #4C4C4C;
    transform: rotate(45deg);
    left: -5px;
    /*right: -5px;*/
    top: calc(50% - 3px);
}

.left:after {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    background-color: #4C4C4C;
    transform: rotate(45deg);
    right: -5px;
    top: calc(50% - 3px);
}

.chartTooltipsLabel {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 600;
}


.chartTooltipsRow {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
}

.pieChartLabelContainer {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction:  column;
    align-items: center;
    gap: 5px;
}



.chartTooltipsDiagram {
    width: 15px;
    height: 15px;
    border-radius: 3px;
}

.tableCardContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 #00000040;
    overflow: hidden;
}


.tabsContainer {
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding: 2px;
    background: #F8FAFB;
    border-radius: 8px;
    border: 1px solid #21272D0A;
}

.tab {
    width: 150px;
    text-align: center;
    background: transparent;
    padding: 10px 30px;
    border-radius: 6px;
    border: 1px solid  transparent;
    /*box-shadow: 0 0 4px 0 transparent;*/
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
}

.activeTab {
    width: 150px;
    text-align: center;
    background: #42296E;
    padding: 10px 30px;
    border-radius: 6px;
    border: 1px solid  #DAD0EA;
    box-shadow: 0 0 4px 0 #00000008;
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: #ffffff;
    cursor: pointer;
}

.tableSearchBox {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: #fff;
    border: .063rem solid #f0f0f0;
    border-radius: .461rem;
    box-shadow: .264rem .063rem .688rem 0 rgba(0, 0, 0, .08);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 70px;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

/*===================table===========================*/
.tableWrapper {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}

.firstCell {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 20px !important;
}

.tableCell {
    max-width: 35vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.viewNanCourseReportContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background: #F6FAFB;
    border: 1px solid #E5F3F5;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 #00000040;
}

.viewNanCourseReportIconContainer {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #E1F3F7;
}


.viewNanCourseReportCountContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #FAEFDE;
    padding: 5px 10px;
    color: #344054;
    font-weight: 500;
    font-size: 15px;

}

.cardNumberSkeleton {
    display: flex !important;
    /*margin: auto auto 0 auto !important;*/
}

.cardNumberSkeletonWrapper {
    display: block;
    line-height: 1;
    /*height: 38px;*/
    /*padding: 4px 0px;*/
}

.datepickerPopper {
    z-index: 10;
}

.datepickerPopper [class='react-datepicker'] {
    width: fit-content!important;
    padding-left: 12px!important;
    padding-right: 12px!important;
}

.datepickerPopper [class*='react-datepicker__navigation'] {
    /*margin-top: 3px;*/
}

.datepickerPopper [class*='react-datepicker__month-text--selected'] {
    background: #42296e!important;
    color: #fff!important;
    border-radius: 0.3rem!important;
}

.datepickerPopper [class*='react-datepicker__month-text'] {
    font-weight: 500;
}



