.smallBox {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.08);
    min-width: 400px!important;
    flex: 1;
}


.cardHeader {
    margin-left: 0.5em;
    color: #000;
    font-family: SanFranciscoPro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
}


.toggleRow {
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 40px;
}

.toggleTextRow {
    flex: 1;
    min-width: 0;
    font-size: 15px;
    font-weight: 700;
}

.toggleDescriptionText {
    font-weight: 400;
    color: #5c5c5c;
}