:root {
    --collapsed-right-container-width: 60px;
    --expanded-right-container-width: 400px;
}

.pollTitleContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pollTitleWrapper {
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.parentContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

.leftContainer {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #CACACA;
    height: 100%;

}

.rightContainer {
    position: relative;
    height: 100%;
    background: #FFFFFF;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.rightContainer.collapsed {
    width: var(--collapsed-right-container-width);
    padding: 10px 0;
    gap: 10px;
    align-items: center;
    animation-name: resultsCollapse;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-fill-mode: forwards;
}

.rightContainer.expanded {
    width: var(--expanded-right-container-width);

    animation-name: resultsExpand;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}


@keyframes resultsExpand {
    from {max-width: var(--collapsed-right-container-width)}
    to {max-width: var(--expanded-right-container-width)}
}

@keyframes resultsCollapse {
    from {width: var(--expanded-right-container-width)}
    to {width: var(--collapsed-right-container-width)}
}


.rightContainerControllerIcon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: calc(50% - 14px);
    left: -12px;
    cursor: pointer;
}

.rightContainerControllerIcon:hover circle{
    stroke: #42296E;
    fill: #42296E;
}

.rightContainerControllerIcon:hover path{
    stroke: #FFFFFF;
}

.rightContainerTabIcon {
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
}

.rightContainerTabIcon:hover {
    background-color: #1D1B2014;
}

.rightContainerTabsContainer {
    width: var(--expanded-right-container-width);
    height: 60px;
    flex-shrink: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.rightContainerTab {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
    border-bottom: 2px solid #CACACA;
}

.rightContainerTab:hover {
    background-color: #1D1B2014;
}

.rightContainerTab.active {
    border-bottom: 2px solid #42296E;
}


.backAndForthController {
    height: 60px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    border-bottom: 1px solid #CACACA;
    /*grid-template-columns: auto 1fr auto;*/
}


@media screen and (max-width: 1100px){
    .leftContainer {
        margin-right: 60px;
    }

    .rightContainer {
        position: absolute;
        top: 0;
        right: 0;
    }

    .rightContainer.expanded {
        border-left: 1px solid #CACACA;
    }

    .emptyPlaceHolder {
        width: calc(var(--expanded-right-container-width) - var(--collapsed-right-container-width));
        flex-shrink: 0;
    }
}

.gradientTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    white-space: nowrap;
    background: linear-gradient(238.85deg, #F28CB9 -23.35%, #EF89B8 -21.87%, #BA54A1 2.25%, #993393 20.37%, #8D278E 30.32%, #7B298F 44.92%, #4D2E90 74.01%, #2E3191 91.51%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.title1st {
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    white-space: nowrap;
    background: #42296E;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.title2nd {
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    white-space: nowrap;
    background: #776891;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.goBackContainer{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.goBackText {
    font-size: 15px;
    font-weight: 400;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.badgeContainer {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 4px;
    background: #F9E4F5;
    color: #344054;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.countDownContainer {
    background-color: #FFFFFF;
    width: 130px;
    height: 40px;
    border: 2px solid #007D00;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.035em;
    white-space: nowrap;
    color: #000000;
}

.countDownContainer.urgent {
    border: 2px solid #BF0033;
}

.questionContainer {
    flex: 1;
    width: 100%;
    min-height: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

.questionRow {
    display: flex;
    padding: 15px 10px;
    align-items: center !important;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    gap: 25px;
    margin-left: -10px;
}

.questionRow:hover {
    border-radius: 10px;
    cursor: pointer;
    border-left: 2px solid #6750A4 !important;
    background: linear-gradient(90deg, #F7EFF6 0.08%, rgba(240, 234, 249, 0) 100.08%, #F7EFF6 100.08%);
    padding-left: 8px;
}

.questionRowIndex {
    font-weight: 700;
}

.questionRowContent {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.questionTitleRow {
    display: flex;
    width: 100%;
    align-items: center;
}

.questionBodyContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.questionFooterContainer {
    width: 100%;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reactionContainer {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #0B6F85;
    align-items: center;
    display: flex;
    justify-content: center;
}

.reactionCount {
    position: absolute;
    top: -10px;
    left: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(#F6EEF6, #F6EEF6) padding-box,
    linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%) border-box;
    border: 1px solid transparent;
    color: #000000;
    font-size: 12px;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.optionContainer {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 6px 0;
}

.option {
    display: flex;
    align-items: center;
    flex: auto;
    min-height: 50px;
    position: relative;
    height: 100%;
    padding: 5px 20px;
    overflow: hidden;
    gap: 10px;
}

.chartContainerSharePoll {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.optionsIndex {
    margin-right: 10px;
}

.optionsText {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    word-wrap: break-word;
    word-break: break-word;
    z-index: 1;
}

.optionCount {
    display: flex;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(#F6EEF6, #F6EEF6) padding-box,
    linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%) border-box;
    border: 1px solid transparent;
    z-index: 1;
    font-size: 16px;
}

.paginationItem, .paginationItemActive, .paginationItemDisabled {
    display: flex;
    width: 32px !important;
    height: 32px;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    justify-content: center;
    align-items: center;

    font-size: 13px;
    font-weight: 600;
    line-height: normal;

    color: #000000;
    background-color: #FFFFFF;
}

.paginationItem:hover {
    background: #EBF6F9;
    cursor: pointer;
}

.paginationItemActive {
    border: none;
    color: #FFFFFF;
    background: #0B6F85;
}

.paginationItemDisabled {
    border: none;
    background-color: #B0CBD1;
}

.panelCardsContainer {
    flex: 1;
    width: var(--expanded-right-container-width);
    min-height: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 15px;
    padding: 15px 10px;
}

.panelCard {
    flex-shrink: 0;
    width: 100%;
    border-radius: 8px;
    background: #FAFAFA;
    border: 1px solid #CACACA;
    transition: 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.panelCardHeader {
    width: 100%;
    height: 48px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.panelCardHeaderTitle {
    flex: 1;
    min-width: 0;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.panelCardBody {
    width: 100%;
    border-top: 1px solid #CACACA;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: hidden;
    flex: 1;
    min-height: 0;
}

.panelCardsFooter {
    width: 100%;
    display: flex;
    align-items: center;
}

.panelCardBodySubTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.035em;
    color: #575757;
}

.pollCodeButton {
    display: flex;
    padding: 5px 8px;
    width: 130px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: linear-gradient(#F6EEF6, #F6EEF6) padding-box,
    linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%) border-box;
    border: 2px solid transparent;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.questionBuilderDefaultCaption {
    color: #212529;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    margin: 10px 0 25px;
    text-align: center;
}

.questionBuilderDefaultLabel {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.questionBuilderDefaultDesc {
    color: #6B6B6B;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.courseSelect {
    width: 100%;
    font-size: 15px;
}

.dividerLine {
    flex-shrink: 0;
    height: 1px;
    width: 100%;
    background-color: #CACACA;
}

.questionTitleFITBText {
    font-weight: 400;
    font-size: 18px;
    line-height: 50px;
    text-align: justify;
    letter-spacing: 0.035em;
    color: #000000;
    word-wrap: break-word;
    word-break: break-word;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}




