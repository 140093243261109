:root {
  --collapsed-side-bar-width: 60px;
  --side-bar-width: 240px;
}


@media only screen and (max-width: 950px) {
  .mainSectionStyle {
    position: relative;
    display: flex;
    overflow-x: auto;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* margin-left: 2.5%; */
  }
}

@media only screen and (min-width: 951px) {
  .mainSectionStyle {
    position: relative;
    display: flex;
    overflow-x: auto;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: var(--collapsed-side-bar-width);
  }
}

.mainSection {
  position: relative;
  display: flex;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}

.collapsedSidebar {
  margin-left: 0px;
}

@media only screen and (min-width: 901px) {
  .collapsedSidebar {
    margin-left: var(--collapsed-side-bar-width);
  }
}

.openSidebar {
  margin-left: var(--side-bar-width);
}

.collapseAbleMainSection {
  margin-left: var(--collapsed-side-bar-width);
}

.anonymousMainSection {
  margin-left: 0;
}

.sidebarStyle {
  position: relative;
  width: 24%;
  background-color: #FAFCFD;
  z-index: 1;
  overflow-x: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.contentStyle {
  position: relative;
  height: var(--main-height);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.contentStyleWoNavbar {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}


.questionSideBar {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 20%;
  background-color: #fff;
  z-index: 1;
  overflow-x: hidden;
  box-shadow: -1.12986px 0px 4px 0px rgba(0, 0, 0, 0.10);
  height: var(--main-height);
  overflow-y: auto;
  min-width: 300px;
  gap: 15px;
}

.questionDetailBar {
  position: relative;
  width: 20%;
  background-color: #FAFCFD;
  z-index: 1;
  overflow-x: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  height: var(--main-height);
  overflow-y: auto;
}

@media only screen and (max-width: 1000px) {
  .questionSideBar {
    width: 30% !important;
    min-width: 200px;
  }

  .questionDetailBar {
    width:30% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .questionSideBar {
    margin-left: 0 !important;
  }
}

.sideBarContainerViewer{
  position: absolute;
  left: 0;
  width: var(--side-bar-width);
  height: calc(100vh - 52px);
  background-color: #FFFFFF;
  box-sizing: border-box;
  z-index: 3;
  background: #F1F2F7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  transition: all 0.5s ease;
  overflow-y: auto;
}

.sideBarContainer{
  position: absolute;
  left: 0;
  /*width: var(--side-bar-width);*/
  width: var(--collapsed-side-bar-width);
  height: calc(100vh - 52px);
  box-sizing: border-box;
  z-index: 1000;
  background: #f1f2f9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  transition: all 0.5s ease;
  overflow-y: hidden;
}

@media only screen and (max-width: 1199px) {
  .sideBarContainerViewer {
    height: 100vh;
  }
}

.collapseAbleSideBarContainer {
  width: var(--collapsed-side-bar-width);
  overflow-y: hidden;
}

.sideBarContainer:hover, .collapseAbleSideBarContainer:hover {
  width: var(--side-bar-width);
  overflow-y: auto;
}


.sidebarMenu {
  height: var(--main-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebarLink {
  display: flex;
  align-items: center;
  height: 45px;
  color : #000000;
  white-space : nowrap;
  text-decoration : none;
  font-family : SanFranciscoPro;
  font-weight : 700;
  font-size: 18px;
  line-height: 50px;
  border-radius: 7px;
  cursor: pointer;
}

.sidebarLink:hover {
  -webkit-text-fill-color: #331F56;
}

.iconFront,
.iconBack {

}
.iconBack {
  transform: perspective(5000px) rotateY(180deg);
}

.iconBack.flipped {
  transform: perspective(5000px) rotateY(0deg);
}

.iconFront {
  transform: perspective(5000px) rotateY(0deg);
}

.iconFront.flipped {
  transform: perspective(5000px) rotateY(-180deg);
}


.activeSideBarLink {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  white-space : nowrap;
  text-decoration : none;
  border-radius: 7px;
  /*background-color: #E4E6F4;*/
  background-color: #DAD0EA;
  cursor: pointer;
}

.activeSideBarLink:hover {
  color: #FFFFFF;
}

.expandedMenuContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background-color: #E4E6F4;
}

.collapsedMenuContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}


element.style {
  background-color: rgb(150, 200, 223);
  /* padding-left: 60px; */
  text-align: center;
  width: 100%;
}
.createOptionWrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 6px 6px 6px;
}

.createOption {
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  cursor: pointer;
  /*transition: all 0.5s ease;*/
}

.activeCreateOption {
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  cursor: pointer;
  background-color: #DAD0EA;
  border-radius: 5px;
  /*transition: all 0.5s ease;*/
}

.createOption:hover {
  -webkit-text-fill-color: #331F56;
}

.activeSideBarLink .gradientText {
  font-size: 19px;
  font-weight: 400;
  color: #F1EFF7;
}

.sidebarText {
  color: #6D5F85;
  font-family: SanFranciscoPro;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.677px; /* 169.792% */
  letter-spacing: 0.849px;
}

.sidebarTitle {
  color: #42296E;
  font-family: SanFranciscoPro;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  font-style: normal;
  height: 25px; /* 100% */
  margin-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebarDivideLine {
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background-color: #C8CBD9;
}

.activeSideBarLink .sidebarText, .activeCreateOption .sidebarText {
  color: #331F56;
  font-family: SanFranciscoPro;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.677px; /* 169.792% */
  letter-spacing: 0.849px;
}

.createOptionWrap .gradientText, .sidebarLink .gradientText {
  font-size: 19px;
  font-weight: 400;
  color: black;
}

.gradientText:hover, .sidebarLink .gradientText:hover {
  background-image: -webkit-linear-gradient(154.85deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.activeSubIcon {
  perspective: 500px;
  transform-style: preserve-3d;
  animation: rotateAnimation 0.8s forwards; /* Use 'forwards' to keep the final state */
}

@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}


/*Please do not edit it........*/

.LeftSideBarContainer {
  position: absolute;
  left: 0;
  top: 0;
  overflow-x: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  height: 100vh;
  background: #F1F2F7;
  transition: all 0.5s ease;
  box-sizing: border-box;
  z-index: 2;
}


