.container {
    width: 100%;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    background: #FCFCFC;
    height: 54px;
    padding: 0 15px;
    border-bottom: 1px solid #EFEFEF;

    display: flex;
    align-items: center;
    gap: 7px;
}

.headerTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
}

.body {
    width: 100%;
    padding: 15px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}


.responseCard {
    width: 100%;
    min-height: 90px;
    padding: 15px 20px;
    background: #FFFFFF;
    border: 1px solid #E8EDF1;
    border-radius: 7px;

    display: flex;
    flex-direction: column;
    gap: 10px;


    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
}

.likeAndDislikeContainer {
    width: 140px;
    height: 36px;
    padding: 0 20px;
    border-radius: 20px;
    background: #ECEAF1;

    display: flex;
    align-items: center;
    gap: 7px;
}

.thumbUpIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/thumb-up-purple.svg");
}

.thumbUpIcon.active, .thumbUpIcon:hover {
    background-image: url("../../images/thumb-up-purple-filled.svg");
}

.thumbDownIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/thumb-down-purple.svg");
}

.thumbDownIcon.active, .thumbDownIcon:hover {
    background-image: url("../../images/thumb-down-purple-filled.svg");
}

.label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #000000;
}


.mainContainerRow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;


    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}
