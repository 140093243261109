.container {
    display: flex;
    flex-direction: column;
    padding: 30px 5% 30px 5%;
    align-items: center;
    background-color: #FCFBFF;
    gap: 30px;
    flex: 1;
}

/*===================table===========================*/
.tableContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
}

.tableContainer table {
        table-layout: fixed;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 25px; */
    letter-spacing: 0;
    text-align: left !important;
    vertical-align: middle !important;
    background-color: white;
    border-spacing: 0 !important;
    border-collapse: separate !important;
    border: 0.031rem solid #EDEDED !important;
    border-radius: 5px 5px 0 0 !important;
    /* overflow: hidden !important; */
}

.tableContainer table th {
    /* padding: 20px !important; */
    vertical-align: middle !important;
    font-size: 15px;
}

.tableContainer tr > th {
    background-color: transparent!important;
    padding: 0px !important;
}

.tableContainer tr > td {
    padding: 0px !important;
}

.tableContainer thead>tr {
    /* border: 0.031rem solid rgba(208, 208, 208, 1) !important; */
}

.tableContainer tbody>tr {
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
}

.tableContainer table thead, tbody, tfoot {
    border-top: 0 !important;
}

.tableContainer table > thead > tr > th{
    font-weight: 600 !important;
}

.tableContainer .tableBtnUnit {
    line-height: 0 !important;
    padding: 0 !important;
}

.tableContainer .tableBtn {
    display: inline-block;
    width: 25px;
    height: 25px;
}

.tableContainer .tableBtn:hover {
    cursor: pointer;
}

.tableContainer .tableMenuBtn {
    background-image: url("../../images/Menu.svg");
}

.tableContainer .tableViewBtn {
    margin-right: 10px;
    background-image: url("../../images/View.svg");
}

.tableContainer .tableEditBtn {
    margin-right: 10px;
    background-image: url("../../images/pencil.svg");
}

.tableContainer .tableDeleteBtn {
    margin-right: 10px;
    background-image: url("../../images/trash.svg");
}

.tableContainer .tablePublishBtn {
    margin-right: 10px;
    background-image: url("../../images/Publish.svg");
}

.tableContainer .tableShareBtn {
    margin-right: 10px;
    background-image: url("../../images/share.svg");
}

.tableContainer .tableSharePollBtn {
    background-image: url("../../images/share-poll.svg");
}

/* .tableContainer .tablePublishBtn {
    display: inline-block;
    border-radius:0.25rem;
    padding: 0.625rem 0.75rem;
    border: 0.125rem solid rgba(10, 130, 14, 1);
    color: rgba(10, 130, 14, 1);
    line-height: 20px;
}

.tableContainer .tablePublishBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(10, 130, 14, 1);
} */

/*customize bootstrap pagination*/
.tableContainer [class*='page-item'][class*='active'] {
    border: none;
}

.tableContainer [class*='page-item'][class*='active'] [class='page-link'] {
    background-color: rgba(0, 107, 155, 1);
    color: rgba(255, 255, 255, 1);
    border-color: rgba(0, 107, 155, 1);

}

.tableContainer [class*='page-link'] {
    color: rgba(0, 107, 155, 1);
}

.tableContainer [class*='page-link']:hover {
    color: rgba(0, 107, 155, 1);
}

.firstCell {
    padding-left: 10px !important;
}

.tableCell {

}

.feedbackDetailDropdown {
    border-radius: 2.742px;
    border: 1.87px solid #42296E;
    display: flex;
    gap: 8.122px;
    padding: 7.723px 9.656px;
}

.feedbackDetailDropdown *{
    stroke: #42296E !important;
}


.feedbackDetailDropdownSelected {
    border-radius: 2.742px;
    display: flex;
    gap: 8.122px;
    padding: 7.723px 9.656px;
}

.feedbackDetailDropdownSelected *{
    stroke: #FFF!important;
}

.feedbackFile:hover {
    text-decoration: underline;
}


.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid #EDEDED !important;
    border-radius: 5px 5px 0 0 !important;
}

.skeletonHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    height: 62px;
    display: grid;             
    grid-template-columns: 14.9% 14.9% 14.9% 13.5% 13.5% 16.2% 12.1%;
    align-items: center;
    border-bottom: 0.031rem solid #EDEDED !important;
    padding-left: 10px;
    text-align: left;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    vertical-align: middle !important;
    justify-content: left !important;
    text-align: left !important;
    width: 100%;
}

.skeletonActionHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
}

.skeletonBodyContainer {
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 14.9% 14.9% 14.9% 13.5% 13.5% 16.2% 12.1%;
    align-items: center;
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px;
    padding: 10px 0px;
    display: flex !important;
    justify-content: left;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonActionBodyCell {
    display: flex !important;
    justify-content: left;
}

.pollTitleTitle {
    font-size: 20px;
    line-height: 22px;
        font-weight: 700;
}

.pollTitleText {
    font-size: 20px;
    line-height: 22px;
        font-weight: 400;
}

.FeedbackSubrow:hover {
    background-color: #F9F9F9 !important;
    box-shadow: none !important;
}

.FeedbackSubrow {
    background-color: #F9F9F9 !important;
    animation: expandAnimation 0.5s ease-in-out;
    margin: 0px 10px !important;
}


@keyframes expandAnimation {
    from {
        height: 0;
        transform: scaleY(0);
        transform-origin: top;
      }
      to {
        height: 100%;
        transform: scaleY(1);
        transform-origin: top;
      }
  }
  
  @keyframes collapseAnimation {
    from {
      height: 100%;
      transform: scaleY(1);
      transform-origin: top;
    }
    to {
      height: 0;
      transform: scaleY(0);
      transform-origin: top;
    }
  }
  
  .FeedbackSubrowClosing {
    animation: collapseAnimation 0.5s ease-in-out;
  }


  .noData {
    text-align: center !important;
}