.parentContainer {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    background-color: #FFFFFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.parentContainer.mobile {
    padding-bottom: 0;
}

.progressBarContainer {
    position: relative;
    width: 100%;
    height: 10px;
    border: 1px solid #DBDBDB;
    margin-bottom: 30px;
}

.progressBar {
    position: absolute;
    top: -1px;
    left: 0;
    height: 10px;
    background-color: #0B6F85;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: .5s ease;
}

.progressBarDesc {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    transform: translate(50%, 0);
    color: #000000;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: .5s ease;
}

.progressBarDesc.left {
    transform: translate(calc(100% + 10px), 0);
}

.progressBarDesc.right {
    transform: translate(-5px, 0);
}

.pollBreakBar {
    width: calc(100% - 24px);
    align-self: center;
    margin: 10px 0;
    background-color: #c3cdff;
    padding: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    gap: 5px;
}


.mainCard {
    flex: 1;
    min-height: 500px;
    width: calc(100% - 20px);
    align-self: center;
    overflow: hidden;

    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 #00000040;

    display: flex;
    flex-direction: column;

}

.mainCard.mobile {
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}


.cardHeader {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #EAEAEA;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 12px;
}

.cardTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #000000;
}

.badge {
    width: 110px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;


    white-space: nowrap;
    color: #344054;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.timerContainer {
    width: 120px;
    height: 35px;
    border-radius: 16px;
    border: 1px solid #D4D4D5;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    white-space: nowrap;
    color: #344054;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cardBody {
    position: relative;
    flex: 1;
    min-height: 0;
    width: 100%;
    display: flex;
}

.questionListContainer {
    flex-shrink: 0;
    width: 152px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #EAEAEA;
    overflow-y: auto;
}

.questionListContainer::-webkit-scrollbar {
    border-left: 1px solid #D4D4D4;
}

.questionListContainer.tablet {
    width: 88px;
}


.questionListContainer.mobile {
    position: absolute;
    top: 0;
    right: 0;
    width: 88px;
    height: calc(100% - 60px);
    transition: .5s ease;
    border-left: 1px solid #EAEAEA;
    border-right: none;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #00000040;
    z-index: 1;
    overflow-x: hidden;
}


.questionListItem {
    flex-shrink: 0;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border-bottom: 1px solid #D4D4D4;
    padding: 0 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.questionListItem.active {
    background: #E7EFF0;
}

.questionListItem.mobile {
    width: 88px;
}

.questionListItem:not(.active):hover {
    background: #EFEFEF;
}



.questionListQuestionTypeCircle {
    position: relative;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #B3D2D9;
    background-color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;
}

.fullCircle {
    border: 2px solid #0B6F85;;
}
/*.halfCircle {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: 50%;*/
/*    border-right: 2px solid #0B6F85;*/
/*}*/

.questionListItemText {
    flex: 1;
    min-width: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mainContainer {
    flex: 1;
    min-width: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mainContainerBody {
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px
}

.mainContainerFooter {
    width: 100%;
    height: 60px;
    border-top: 1px solid #EAEAEA;
    padding: 0 12px;
    display: flex;
    align-items: center;
}


.mainContainerRow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;


    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.thumbUpIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/thumb-up-purple.svg");
}

.thumbUpIcon.active, .thumbUpIcon:hover {
    background-image: url("../../images/thumb-up-purple-filled.svg");
}

.thumbDownIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/thumb-down-purple.svg");
}

.thumbDownIcon.active, .thumbDownIcon:hover {
    background-image: url("../../images/thumb-down-purple-filled.svg");
}

.expandIcon {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 #00000040;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.gridLayout {
    flex: 1;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    gap: 10px;
}

.straightLayout {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.imageBox {
    grid-row: 1 / -1;
    grid-column: 2 / 2;
}

.imageContainer {
    flex-shrink: 0;
    width: 270px;
    height: 270px;
    border-radius: 3px;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
}

.coordinate {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    box-shadow: 0 0 3px 0 #00000059;
    background-color: #0B6F8540;

}


.optionsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}


.optionBar {
    width: 100%;
    min-height: 60px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 10px 20px;
    box-shadow: 0 0 1px 0 #00000033;

    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    color: #4D4D4D;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}

.optionBar.active {
    color: #000000;
    background-color: #F4F1FF;
    border: 1px solid #DAD0EA;
    box-shadow: 0 0 4px 0 #00000040;
}

.optionBar.notTouchAble:not(.active):hover {
    background-color: #EFEFEF;
    box-shadow: 0 0 4px 0 #00000040;
    border: 1px solid #E6E6E6;
}

.notAllowed {
    cursor: not-allowed;
}

.optionIndex {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #DAD0EA;
    border-radius: 4px;
    background: #DAD0EA;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #000000;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.optionIndex.active {
    background: #FFFFFF;
    border: 1px solid #DAD0EA;
    box-shadow: 0 0 1px 0 #00000040;
}

.shortAnswerTextArea {
    width: 100%;
    height: 150px;
    padding: 15px;
    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000040;
    resize: none;

    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.FITBInputBox {
    width: 170px;
    padding: 7px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000040;

    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 5px;
}

.WCOptionContainer {
    width: 100%;
    height: 60px;
    position: relative;
}


.WCOptionInputBox {
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000033;
    display: flex;
    align-items: center;
    gap: 10px;

    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.WCOptionManagerBarTouchAble {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    overflow: hidden;
}

.WCOptionManagerBar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    padding-right: 20px;
}


.WCOptionDeleteBar {
    width: 130px;
    height: 100%;
    background: #F8DCDB;
    border: 1px solid #E3A7A533;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.WCOptionCharacterRemain {
    flex-shrink: 0;
    width: 30px;
    text-align: center;
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.WCOptionInputBox:not(:focus) + .WCOptionManagerBarTouchAble .WCOptionCharacterRemain {
    color: #000000;
}

.shortAnswerTextArea:focus, .FITBInputBox:focus, .WCOptionInputBox:focus  {
    border: 1px solid #E6E6E6!important;
    background: #F5F5F5;
    box-shadow: 0 0 2px 0 #00000040!important;

}

.shortAnswerTextArea:not(:focus), .FITBInputBox:not(:focus), .WCOptionInputBox:not(:focus) {
    color: #000000;
}

.FITBInputBox:-webkit-autofill,
.FITBInputBox:-webkit-autofill:focus,
.FITBInputBox:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: inherit;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 1px 0 #00000040;
}


.shortAnswerTextArea::placeholder,
.FITBInputBox::placeholder,
.WCOptionInputBox::placeholder
{
    color: #4D4D4D;
    opacity: 1;
}

.MHGridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    align-items: center;
    column-gap: 20px;
}

.MHVerticalContainer {
    width: 100%;
    display: grid;
    align-items: center;
    row-gap: 10px;
    grid-auto-columns: minmax(0, 1fr);
}



.MHPremise {
    width: 100%;
    min-height: 47px;
    padding: 5px 20px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000033;
    display: flex;
    align-items: center;

    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.MHDivider {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #D4D4D4;
}

.RKOptionContainer {
    width: 100%;
    min-height: 60px;
    padding: 15px 20px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000033;

    display: flex;
    align-items: center;
    gap: 10px;


    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #4D4D4D;
}


.RKOptionContainer.active {
    background: #F4F1FF;
    border: 1px solid #DAD0EA;
    box-shadow: 0 0 4px 0 #00000040;
    color: #000000;
}

.pollPanelContainer {
    position: fixed;
    top: 48px;
    left: 10px;
    width: calc(100% - 20px);
    padding: 20px;
    border: 1px solid #D0D0D0;
    border-radius: 7px;
    box-shadow: 0 1px 5px 0 #0000001F;
    background: #FFFFFF;

    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
}

.navBarTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}









