.containerStep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 19px 41px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
    background: #F4F4F4;
    color: #000;
    font-family: SanFranciscoPro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
    margin-bottom: 20px;
    max-width: 1166px;
}

.stepNo {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: #42296E;
    border-radius: 50%;
    color: #FFF;
    font-family: SanFranciscoPro;
    font-size: 24.832px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.869px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.completedContainer {
    display: flex;
    height: 76px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-radius: 6px;
    border: 1px solid #219304;
    background: #F1FFEE;
    color: #2D2D2D;
    font-family: SanFranciscoPro;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.63px;
    margin-bottom: 20px;
    max-width: 1166px;
}

.extImage {
    width: 306px;
    height: 132.5px;
}

.defaultImg {
    width: 324px;
    height: 54px;
}

.line {
    height: 0.4px;
    background: #909090;
    margin-left: 15px;
    display: flex;
    flex: auto;
}
