.container {
    position: relative;
    font-family: SanFranciscoPro;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
}

.questionTypeDropdownContainer {
    border-radius: 4px;
    border: 2px solid #42296E;
    height: 35px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.top.menuContainer {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 110px;
    max-width: 150px;
    width: fit-content;
    z-index: 100;
    visibility: hidden;
}

.bottom.menuContainer {
    position: fixed;
    top: 0;
    left: 0;
    /*min-width: 110px;*/
    max-width: 150px;
    width: fit-content;
    z-index: 100;
    visibility: hidden;
}

.createMenuContainer {
    position: absolute;
    min-width: 110px;
    max-width: 183px;
    top: calc(100% + 5px);
    z-index: 100;
}

.focusIcon:focus-visible {
    outline: auto !important;
}


.menuList {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 7px;
    box-shadow: 0px 0.5px 5px 0px #0000001F;
    overflow: auto;

    font-family: SanFranciscoPro;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
}

.top.menuContainer:after{
    content: '';
    border-bottom: 1px solid #D0D0D0;
    border-right: 1px solid #D0D0D0;
    background-color:white;
    position: absolute;
    right: 18px;
    bottom: -4px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    box-shadow: 0 1px 5px 0 #0000001F;
    z-index: 1;
}

.bottom.menuContainer:after{
    content: '';
    border-top: 1px solid #D0D0D0;
    border-left: 1px solid #D0D0D0;
    background-color:white;
    position: absolute;
    right: 18px;
    top: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    box-shadow: 0 1px 5px 0 #0000001F;
    z-index: 1;
}

.menuItem {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 10px;
    background-color: #FFFFFF;
    z-index: 100;

}

.menuItemText {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menuItem:last-child {
    border-width: 0;
}

.menuItem:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.backDrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
}


.kebabMenuMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
    visibility: hidden;
    cursor: default;
}
