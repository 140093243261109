.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000
}

.landscapeView {
    padding: 5vh 10vw;
}

.profileView {
    padding: 10vh 0;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.7;
    z-index: -1
}

.topBar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: linear-gradient(rgb(16, 18, 20), rgba(14, 22, 36, 0)) 0 center no-repeat;
}