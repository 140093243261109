.subText {
    font-style: normal;
    font-weight: 400;
    font-size: max(1.25vw, 15px);
    line-height: max(1.5vw, 20px);
    text-align: left;
    background-color: #FFFFFF;
    color: #6c6c6c;
    margin-left: 9px;
    box-shadow: none;
}

.questionBuilderSubText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #6c6c6c;
    margin-left: 9px;
    box-shadow: none;
}