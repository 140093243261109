.title {
        font-size: 18px;
    font-weight: 600;
    user-select: none;
}

.divideLine {
    height: 1px;
    background-color: #c8c8c7;
}

.tableParentContainer {
    width: 100%;
    flex: 1;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.goBackLink {
    display: flex;
    align-items: center;
    align-self: flex-start;
    cursor: pointer;
    gap: 5px;
}

.goBackLink:hover {
    color: #42296E;
}

.tableParentContainer table {
        font-size: 14px;
    table-layout: fixed;
    letter-spacing: 0;
    text-align: center !important;
    vertical-align: middle !important;
    background-color: white;
    overflow-y: auto;
    margin-bottom: 0!important;
}

.tableParentContainer table tr {
    height: fit-content!important;
}

.tableParentContainer table th,.tableParentContainer table td  {
    padding: 0.8rem !important;
}


.controllerContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.normalContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.animationContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
