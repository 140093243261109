.noResultContainer {
    display: flex;
    flex: 1;
    margin-top: 50px;
    padding: 30px 5%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.noResultImage {
    width: 335px;
    height: 175px; 
    height: fit-content;
}

.noResultTitle {
        font-size: 30px;
    font-weight: 700;
    line-height: 25.05px;
    text-align: center;

    margin-top: 40px;
}

.noResultDesc {
        font-size: 24px;
    font-weight: 400;
    line-height: 20.04px;
    text-align: center;

    margin-top: 25px;
}

@media screen and (max-width: 480px) {
    .noResultTitle {
        font-size: 18px;
        line-height: normal;
    }

    .noResultDesc {
        font-size: 14px;
        line-height: normal;
        margin-top: 20px;
    }

    .noResultImage {
        width: 175px !important;
    }
}

.container {
    display: flex;
    padding: 30px 5% 30px 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    background-color: #FCFBFF;
    min-height: 100%;
    overflow-y: auto;
}

.c2 {
    padding: 30px 5% 30px 5%;
    justify-content: center;
    width: 100%;
}

.titleBox {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 43px;
    margin-bottom: 22px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/links/manage_courses_icon.svg");
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;

}

.searchBox {
    font-family: SanFranciscoPro;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-bottom: 30px;
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
}

.searchBoxForm {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.searchBoxKeywordInput {
    height: 45px;
    width: 40%;
    padding-left: 37px;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxDateInput {
    height: 35px;
    width: 130px;
    margin-right: 15px;
    border: 0.083rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus, .searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxKeywordInput:focus-within {
    box-shadow: 0 0 0 2px #42296E!important;
}

.searchBoxSearchBtn {
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

/*===================table===========================*/
.tableContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
}

.tableContainer table {
        table-layout: fixed;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 25px; */
    letter-spacing: 0;
    text-align: center !important;
    vertical-align: middle !important;
    background-color: white;
    border-spacing: 0 !important;
    border-collapse: separate !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.tableContainer table th {
    /* padding: 20px !important; */
    text-align: left;
    vertical-align: middle !important;
}

.tableContainer tr > th {
    background-color: #F7F6FB!important;
    padding: 0px 0px 0px 20px !important;
}

.tableContainer tr > td {
    padding: 0px 0px 0px 20px !important;
}

.tableContainer table thead > tr > th:nth-child(1) {
    border-radius: 10px 0px 0px 0px;
}
.tableContainer table thead > tr > th:nth-last-child(1) {
    border-radius: 0px 10px 0px 0px;
} 

.tableContainer thead>tr {
    /* border: 0.031rem solid rgba(208, 208, 208, 1) !important; */
}

.tableContainer tbody>tr {
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
}

.tableContainer table thead, tbody, tfoot {
    border-top: 0 !important;
}

.tableContainer table > thead > tr > th{
    font-weight: 600 !important;
}

.tableContainer .tableBtnUnit {
    line-height: 0 !important;
    padding: 0 !important;
}

.tableContainer .tableBtn {
    display: inline-block;
    width: 33px;
    height: 32px;
}

.tableContainer .tableBtn:hover {
    cursor: pointer;
}

.tableContainer .tableEditBtn {
    margin-right: 10px;
    background-image: url("../../images/pencil.svg");
}

.tableContainer .tableDeleteBtn {
    background-image: url("../../images/trash.svg");
}

.tableContainer .tablePublishBtn {
    display: inline-block;
    border-radius:0.25rem;
    padding: 0.625rem 0.75rem;
    border: 0.125rem solid rgba(10, 130, 14, 1);
    color: rgba(10, 130, 14, 1);
    line-height: 20px;
}

.tableContainer .tablePublishBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(10, 130, 14, 1);
}

/*customize bootstrap pagination*/
.tableContainer [class*='page-item'][class*='active'] {
    border: none;
}

.tableContainer [class*='page-item'][class*='active'] [class='page-link'] {
    background-color: rgba(0, 107, 155, 1);
    color: rgba(255, 255, 255, 1);
    border-color: rgba(0, 107, 155, 1);

}

.tableContainer [class*='page-link'] {
    color: rgba(0, 107, 155, 1);
}

.tableContainer [class*='page-link']:hover {
    color: rgba(0, 107, 155, 1);
}


/*=================responsive====================*/
@media only screen and (min-width: 250px) and (max-width: 950px) {
    .searchBoxKeywordInput {
        margin-left: 5px;
        padding-left: 27px;
        background: url("../../images/zoom.svg") no-repeat left;
        background-position-x: 5px;
        border: 0.066rem solid rgba(208, 208, 208, 1) !important;
    }

    .searchBoxDateInput {
        width: 80px;
        min-width: 50px;
    }

    .searchBoxSearchBtn {
        margin-right: 5px;
        padding: 0.5rem 0.6rem;
        border: 0.125rem solid rgba(0, 107, 155, 1);
        color: rgba(0, 107, 155, 1);
        font-size: 0.625rem;
        line-height: 0.625rem;
    }

    .tableContainer table {
        font-size: 1rem;
    }
}

.createButton{
    border-radius: 0.211rem;
    padding: 1.07rem 2.188rem 1.07rem 2.188rem;
    border: 0.253rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
    height: 15%;
}

.createButton:hover {
    background-color: rgba(2, 119, 173, 1);
    color: rgba(255, 255, 255, 1);
}

.center{
    margin: 5%;
    justify-content: center;
    text-align: center;
}

.text{
    justify-content: center;
    margin: 5%;
    text-align: center;
}