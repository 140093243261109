.expShadow {
  width: 92%;
  margin-top: 3.5rem;
}

.expContainer {
  width: 62%;
  margin-top: calc(2vw);
  margin-bottom: calc(3vw);
  padding: 4vw 0 2vw 0;
}

.expCenterLayout {
  display: flex;
  height: fit-content;
  justify-content: center;
  flex-wrap: wrap;
}

.correctOptionText {
  color: #000 !important;
}


.normalOptionText {
  color: #000 !important;
}


@media only screen and (min-width: 250px) and (max-width: 800px) {
  .expContainer {
      width: 85vw !important;
  }
  .expIllustration {
      height: 48vw !important;
      width: 80vw !important;
  }

}

@media only screen and (max-height: 800px) {
  .expShadow {
      margin-bottom: 3rem !important;
  }
}

.expIllustration {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: rgba(0,0,0,0);
  height: 14rem;
  padding: 0;
  width: 23rem;
  border-bottom: 0;
  background-image: url('./images/dashboard illustration.svg');
}

@media only screen and (max-width: 1200px) {
  .shadow {
    height: calc(100vh - 75px) !important;
    position: absolute;
    top: 0;
    width: 100vw;
    margin: 0;
    box-shadow: none;
  }

  .optionText {
    max-width: 70vw;
  }
}

@media only screen and (min-width: 1200px) {
  .shadow {
    width: 90vw;
    margin-top: 1rem;
  }
  .optionText {
    max-width: 40vw;
  }
}

.container {
  width: 80%;
  margin-top: calc(1.5vw);
  margin-bottom: calc(1.5vw);
  padding: 4vw 0 2vw 0;
}

@media only screen and (max-width: 480px) {
  .containerTop {
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 90px;
    height: fit-content;
  }

  .locationContainer {
    width: calc(100% - 48px);
    margin: -0.5vw 0vw 1.5vw 0px;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 60px;
    height: fit-content;
  }
  
  .containerBottom {
    width: 100%;
    padding: 0px 21px 0px 21px;
    position: relative;
    box-shadow: none;
    border: none;
  }

  .containerCountdown {
    height: 45px;
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0;
    position: relative;
    box-shadow: none;
    border: none;
    display: block;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1200px) {
  .containerTop {
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 90px;
    height: fit-content;
  }

  .locationContainer {
    width: calc(100% - 48px);
    margin: -0.5vw 0vw 1.5vw 0px;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 60px;
    height: fit-content;
  }
  
  .containerBottom {
    width: 100%;
    padding: 0px 61px 0px 61px;
    position: relative;
    box-shadow: none;
    border: none;
  }

  .containerCountdown {
    height: 45px;
    margin: 1.5vw 0vw 1.5vw 0vw;
    padding: 0;
    position: relative;
    box-shadow: none;
    border: none;
  }
}

@media only screen and (min-width: 1200px) {
  .containerTop {
    margin: 1.5vw 0vw 1.5vw 0px;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 90px;
    height: fit-content;
  }

  .locationContainer {
    width: calc(100% - 42px);
    margin: -0.5vw 0vw 1.5vw 0px;
    padding: 0vw 3vw 0vw 3vw;
    min-height: 60px;
    height: fit-content;
  }
  
  .containerBottom {
    width: 100%;
    background: #FCFBFF;
    padding: 0px 61px 0px 61px;
    position: relative;
  }
}

.resultsContainer {
  width: 87.5%;
  margin: 1.5vw 0vw 1.5vw 0vw;
  padding: 1vw 6vw 1vw 6vw;
}

.centerLayout {
  display: flex;
  height: fit-content;
  background-color: #FCFBFF;
}

.pollShareAllLayOut {
  width: 100%;
  /*height: 100%; */
  margin-top: 0px;
  background-color: #FCFBFF;
  flex: 1;
}

.attendanceLayout {
  /* width: 100%; */
  padding: min(5vh, 40px) min(5vw, 40px);
  background-color: #FCFBFF;
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: min(20px, 5vh);
}

.attendanceTitle {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.attendanceAttentionContainer {
  background-color: #C3CDFF;
  padding: 15px;
  font-size: 14.5px;
  border-radius: 6.25px;
  display: flex;
  gap: 10px;
  align-items: center;
}


.attendanceBodyContainer {
  padding: 5vh 5vw;
  border-top: 2px solid #42296E;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FFFFFF;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  min-height: fit-content !important;
  position: relative;
}

.attendanceHeaderContainer {
  width: 100%;
  max-height: 90px;
}


.attendanceFooterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  align-content: center;
}


@media only screen and (min-width: 1200px) {
  .attendanceTitle {
    font-size: 28px;
  }
}


@media only screen and (max-width: 1199px) {
  .attendanceTitle {
    font-size: 18px;
  }
}




.lottieContainer {
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: -100px; */
  /* margin: -100px; */
  /* background-color: #123456; */
  z-index: -1;
}

.lottieContainer:hover {
  /* background-color: #654321; */
  cursor: default !important;
}

.endedPollBackground {
  background: #FCFBFF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 30px;
  gap: 20px;
}

.endedPolllSubtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: normal;
  text-align: center;
  color: #000000;
}

.endedPolllSubtext {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.035em;
  color: #545454;
}

.endedPollText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.035em;
  color: #545454;
}


.endedPollIcon {
  flex-shrink: 0;
}


.chartContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chartContainerSharePoll {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.optionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.option {
  display: flex;
  align-items: center;
  flex: auto;
  min-height: 55px;
  position: relative;
  height: 100%;
  padding: 5px 20px;
  overflow: hidden;
  gap: 10px;
}

.optionImageContainer {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
  background-color: #C8C7C7;
  flex-shrink: 0;
}

.optionIndex {
  position: relative;
  z-index: 1;
  font-weight: 700;
}

.optionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
}

.optionSelectedMark {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  z-index: 1;
}

.optionCountBar {
  top: 0;
  left: 0;
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 10px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.optionCountBarMC {
  top: 0;
  left: 0;
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #F7EFF6;
  border-radius: 10px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.optionCountBar.correct{
  background-color: var(--foundation-violet-light-active, #C4BDD2);
}

.countValueContainer {
  z-index: 1;
  margin-left: 20px;
  font-weight: 700;
  justify-content: end; 
  display: flex;
}

.optionTextToolTip {
  background-color: #6C6C6C;
  color: #FFFFFF;
    padding: 10px;
  opacity: 0.8;
  z-index: 1;
  border-radius: 5px;
}

.optionTextToolTip::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #6C6C6C;
  bottom: -7px;
}

.maxWidth {
  width: 100% !important;
}

.LoginSelect {
  width: 100%;
  height: 50px;
  border: 1.11px solid #939393;
  border-radius: 2.48157px 2.48157px 0px 0px;
    font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  display: grid; 
  grid-template-columns: 80% 20%;
    color: #000;
}


.choiceoption {
  width: 100%;
  height: 50px;
  border: 1.11px solid #939393;
  border-radius: 2.48157px 2.48157px 0px 0px;
    font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: block;
    color: #000;
  background-color:#FAFCFD ;
  padding-left: 15px;
  align-items: center;
  display: grid; 
  border-top: none;
  cursor: pointer;
}

.choiceoption:hover {
  background-color:#CACACA; 
}

.MatchingNarrow {
  flex-direction: column;
  border-top: 2px dashed grey; 
}

.MatchingNarrow {
  flex-direction: column;
  border-top: 2px dashed grey; 
}

.onlineIcon {
  background-color: #E6F3E6;
  color: var(--color-guide-successcolors-400, #005C00);
    font-weight: 700;
  font-size: 18px;
  padding: 5px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: default;
  gap: 10px; 
  border-radius: 47px;
  width: 130px;
  height: 40px;
  border: 2.5px solid var(--color-guide-successcolors-400, #005C00);
  background: var(--color-guide-successcolors-50, #E6F3E6);
}


.offlineIcon {
  background-color: #FBEAEB;
  color: #991F27;
    font-weight: 700;
  font-size: 18px;
  border: 1px solid #991F27;
  border-radius: 20px;
  padding: 5px 10px;
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: default;
  gap: 5px;
}


.mobileIcon {
  padding: 5px 5px;
}

@media only screen and (max-width: 1199px) {
  .onlineIcon, .offlineIcon {
    font-size: 14px;
    gap: 2px;
    display: flex;
    width: 28px;
    height: 28px;
    padding: 3.9px;
    justify-content: center;
    align-items: center;
    gap: 3.28px;
    flex-shrink: 0;
    border-radius: 24.44px;
  }
}

.refreshLocation {
    font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
    color: #000;
}

.pollBreakBar {
  background-color: rgb(195, 205, 255);
  padding: 8px 15px;
  font-family: SanFranciscoPro;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 5px;
}

.yourAnswerCard {
  display: flex;
  flex-direction: column;
  border: 1px solid #42296E;
  border-radius: 6px;
  background-color: #FFF;
  padding: 10px;
  margin: 15px 0;
  gap: 10px;

  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.yourAnswerLabel {
}

.yourAnswerText {
}


.pollTitleContainer {
  width: 100%;
  min-height: 80px;
  border-radius: 8px;
  border-top: 1px solid #EAEAEA;
  background: #FFF;
  box-shadow: 0 0 4px 0 #00000040;
  display: flex;
  align-items: center;
  padding: 20px;
}

.pollTitleContainerTitle{
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #000000;
  word-break: break-word;
}

.emptyBoxTitle {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.emptyBoxDesc {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}


.waitingIllustrationContainer {
  position: relative;
  width: 285px;
  height: 285px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px dashed #42296E80;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waitingIllustrationWrapper {
  position: relative;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background: radial-gradient(81.39% 84.82% at -6.17% 12.35%, #67E8F91A 0%, #C084FC1A 100%); /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  display: flex;
  align-items: center;
  justify-content: center;
}

.questionSharedBadge {
  flex-shrink: 0;
  width: 220px;
  height: 48px;
  border-radius: 4px;
  background: #E4E3F6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  color: #344054;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;

}

.generalContainer {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.waitingRoomTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
}

.waitingRoomDesc {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
}

.getReadyCountDownContainer {
  width: 140px;
  height: 140px;
  border: 1px solid #42296E;
  border-radius: 50%;
  background: #F4F0F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  font-size: 100px;
  line-height: 120px;
  font-weight: 500;
  color: #42296E;
}

.getReadyCountDownContainer.small {
  font-size: 70px;
  font-weight: 500;
  line-height: 84px;
  color: #42296E;
}


@keyframes blink-out {
  0%, 25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.countDownBlink {
  animation: blink-out 1s linear infinite;
}


.pollCloseSupportContainer {
  width: 100%;
  min-height: 100px;
  max-width: 800px;

  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 #00000040;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.pollCloseIconProfile {
  flex-shrink: 0;
  width: 120px;
  height: 100%;
  background: #F3EBFF;


    display: flex;
  align-items: center;
  justify-content: center;
}


