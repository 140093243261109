.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topBarContainer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid #c8c8c7;
    background: linear-gradient(90.07deg,#031434 12.79%,#2a4e83 42.74%);
}

.title {
        font-size: 20px;
    font-weight: 600;
    cursor: default;
    user-select: none;
    color: #FFFFFF;
}

.description {
        font-size: 14px;
}

.sideBarContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: var(--side-bar-width);
    height: 100%;
    box-sizing: border-box;
    z-index: 3;
    background: #F1F2F7;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
    transition: all 0.5s ease;
    overflow-y: auto;
}

.sidebarMenu {
    height: var(--main-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebarTitle {
    color: #42296E;
    font-family: SanFranciscoPro;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    font-style: normal;
    height: 25px;
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebarDivideLine {
    width: 100%;
    height: 1px;
    margin: 20px 0 20px 0;
    background-color: #C8CBD9;
}


.sidebarLink {
    display: flex;
    align-items: center;
    height: 50px;
    color : #000000;
    white-space : nowrap;
    text-decoration : none;
    font-family : SanFranciscoPro, serif;
    font-weight : 700;
    font-size: 18px;
    line-height: 50px;
    border-radius: 7px;
    cursor: pointer;
}

.sidebarLink:hover {
    -webkit-text-fill-color: #331F56;
}

.activeSubIcon {
    perspective: 500px;
    transform-style: preserve-3d;
    animation: rotateAnimation 0.8s forwards; /* Use 'forwards' to keep the final state */
}

@keyframes rotateAnimation {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.activeSideBarLink {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    white-space : nowrap;
    text-decoration : none;
    border-radius: 7px;
    background-color: #E4E6F4;
    cursor: pointer;
}

.sidebarText {
    color: #6D5F85;
    font-family: SanFranciscoPro;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.677px; /* 169.792% */
    letter-spacing: 0.849px;
}


.activeSideBarLink .sidebarText {
    color: #331F56;
    font-family: SanFranciscoPro;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.677px; /* 169.792% */
    letter-spacing: 0.849px;
}

.createOptionWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}


.createOption {
    width: 100%;
    white-space: nowrap;
    line-height: 48px;
    cursor: pointer;
}

.createOption:nth-of-type(1) {
    margin-bottom: 0;
}

.majorContainer {
    margin-left: 60px;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}
