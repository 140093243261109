.tooltipTrigger {
    position: relative;
    display: inline;
}

.tooltip{
    top: 0;
    left: 0;
    height: auto;
    background-color: #4C4C4C;
    color: #FFFFFF;
    border-radius: 3px;
    padding: 10px;
    position: fixed;
    /*visibility: hidden;*/
    z-index: 100;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;

}

.left{
    top: 0;
    left: 0;
    content: " ";
    position: fixed;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent  transparent transparent #4C4C4C;
    z-index: 100;
    /*visibility: hidden;*/
    /*z-index: -1000;*/
}

.right{
    top: 0;
    left: 0;
    content: " ";
    position: fixed;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #4C4C4C transparent transparent;
    z-index: 100;
    /*visibility: hidden;*/
    /*z-index: -1000;*/
}

.top {
    top: 0;
    left: 0;
    content: " ";
    position: fixed;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #4C4C4C transparent transparent transparent;
    z-index: 100;
    /*visibility: hidden;*/
    /*z-index: -1000;*/
}

.bottom {
    top: 0;
    left: 0;
    content: " ";
    position: fixed;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #4C4C4C transparent;
    z-index: 100;
    /*visibility: hidden;*/
    /*z-index: -1000;*/
}

@media (hover:none) {
    .visibleInTouchableDevice {
        visibility: visible;
        z-index: 1000;
    }
}

