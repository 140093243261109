
.editorBox {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 4px 0 #00000040;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
}

.editorContentBox {

}

.characterCountBox {
    color: #222f3eb3;
    font-size: 13px;
    line-height: normal;
    position: absolute;
    right: 10px;
    bottom: -1px;
}



.editorContentBox [class*='tiptap'] {
    caret-color: unset;
    margin: 10px 10px 13px 10px;
    color: #000;
}

.editorContentBox [class*='tiptap']:focus {
    outline: none
}

.editorContentBox [class*='tiptap'] p:first-of-type:last-of-type {
    margin-bottom: 0;
}

.editorContentBox [class*='tiptap'] p[class*='is-editor-empty']:first-child::before {
    color: #adadad;
    font-size: 15px;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-editor'] {
    background: #202020;
    color: #fff;
    font-family: monospace;
    padding: 2px 5px;
    caret-color: #fff;
}

.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-render']{
    padding: 0 .25rem
}

.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-render--editable'] {
    cursor: pointer;
    transition: background .2s
}

.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-render--editable']:hover {
    background: #eee
}

.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-editor'], .editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-render'] {
    border-radius: .25rem;
    display: inline-block;
}



.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-editor'] {
    background: #202020;
    color: #fff;
    font-family: monospace;
    padding: .2rem .5rem;
    caret-color: #fff;
}

.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-editor'],.editorContentBox [class*='tiptap'] [class*='Tiptap-mathematics-render'] {
    border-radius: .25rem;
    display: inline-block;
}


/*----------------------------------------Katex start ------------------------------*/
.katex {
    font: 1.21em KaTeX_Main,Times New Roman,serif;
    line-height: 1.2;
    text-indent: 0;
    text-rendering: auto
}

.katex * {
    -ms-high-contrast-adjust: none!important;
    border-color: currentColor
}

.katex .katex-version:after {
    content: "0.16.15"
}

.katex .katex-mathml {
    clip: rect(1px,1px,1px,1px);
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.katex .katex-html>.newline {
    display: block
}

.katex .base {
    position: relative;
    white-space: nowrap;
    /*width: -moz-min-content;*/
    width: min-content
}

.katex .base,.katex .strut {
    display: inline-block
}

.katex .textbf {
    font-weight: 700
}

.katex .textit {
    font-style: italic
}

.katex .textrm {
    font-family: KaTeX_Main
}

.katex .textsf {
    font-family: KaTeX_SansSerif
}

.katex .texttt {
    font-family: KaTeX_Typewriter
}

.katex .mathnormal {
    font-family: KaTeX_Math;
    font-style: italic
}

.katex .mathit {
    font-family: KaTeX_Main;
    font-style: italic
}

.katex .mathrm {
    font-style: normal
}

.katex .mathbf {
    font-family: KaTeX_Main;
    font-weight: 700
}

.katex .boldsymbol {
    font-family: KaTeX_Math;
    font-style: italic;
    font-weight: 700
}

.katex .amsrm,.katex .mathbb,.katex .textbb {
    font-family: KaTeX_AMS
}

.katex .mathcal {
    font-family: KaTeX_Caligraphic
}

.katex .mathfrak,.katex .textfrak {
    font-family: KaTeX_Fraktur
}

.katex .mathboldfrak,.katex .textboldfrak {
    font-family: KaTeX_Fraktur;
    font-weight: 700
}

.katex .mathtt {
    font-family: KaTeX_Typewriter
}

.katex .mathscr,.katex .textscr {
    font-family: KaTeX_Script
}

.katex .mathsf,.katex .textsf {
    font-family: KaTeX_SansSerif
}

.katex .mathboldsf,.katex .textboldsf {
    font-family: KaTeX_SansSerif;
    font-weight: 700
}

.katex .mathitsf,.katex .mathsfit,.katex .textitsf {
    font-family: KaTeX_SansSerif;
    font-style: italic
}

.katex .mainrm {
    font-family: KaTeX_Main;
    font-style: normal
}

.katex .vlist-t {
    border-collapse: collapse;
    display: inline-table;
    table-layout: fixed
}

.katex .vlist-r {
    display: table-row
}

.katex .vlist {
    display: table-cell;
    position: relative;
    vertical-align: bottom
}

.katex .vlist>span {
    display: block;
    height: 0;
    position: relative
}

.katex .vlist>span>span {
    display: inline-block
}

.katex .vlist>span>.pstrut {
    overflow: hidden;
    width: 0
}

.katex .vlist-t2 {
    margin-right: -2px
}

.katex .vlist-s {
    display: table-cell;
    font-size: 1px;
    min-width: 2px;
    vertical-align: bottom;
    width: 2px
}

.katex .vbox {
    align-items: baseline;
    display: inline-flex;
    flex-direction: column
}

.katex .hbox {
    width: 100%
}

.katex .hbox,.katex .thinbox {
    display: inline-flex;
    flex-direction: row
}

.katex .thinbox {
    max-width: 0;
    width: 0
}

.katex .msupsub {
    text-align: left
}

.katex .mfrac>span>span {
    text-align: center
}

.katex .mfrac .frac-line {
    border-bottom-style: solid;
    display: inline-block;
    width: 100%
}

.katex .hdashline,.katex .hline,.katex .mfrac .frac-line,.katex .overline .overline-line,.katex .rule,.katex .underline .underline-line {
    min-height: 1px
}

.katex .mspace {
    display: inline-block
}

.katex .clap,.katex .llap,.katex .rlap {
    position: relative;
    width: 0
}

.katex .clap>.inner,.katex .llap>.inner,.katex .rlap>.inner {
    position: absolute
}

.katex .clap>.fix,.katex .llap>.fix,.katex .rlap>.fix {
    display: inline-block
}

.katex .llap>.inner {
    right: 0
}

.katex .clap>.inner,.katex .rlap>.inner {
    left: 0
}

.katex .clap>.inner>span {
    margin-left: -50%;
    margin-right: 50%
}

.katex .rule {
    border: 0 solid;
    display: inline-block;
    position: relative
}

.katex .hline,.katex .overline .overline-line,.katex .underline .underline-line {
    border-bottom-style: solid;
    display: inline-block;
    width: 100%
}

.katex .hdashline {
    border-bottom-style: dashed;
    display: inline-block;
    width: 100%
}

.katex .sqrt>.root {
    margin-left: .2777777778em;
    margin-right: -.5555555556em
}

.katex .fontsize-ensurer.reset-size1.size1,.katex .sizing.reset-size1.size1 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size1.size2,.katex .sizing.reset-size1.size2 {
    font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size1.size3,.katex .sizing.reset-size1.size3 {
    font-size: 1.4em
}

.katex .fontsize-ensurer.reset-size1.size4,.katex .sizing.reset-size1.size4 {
    font-size: 1.6em
}

.katex .fontsize-ensurer.reset-size1.size5,.katex .sizing.reset-size1.size5 {
    font-size: 1.8em
}

.katex .fontsize-ensurer.reset-size1.size6,.katex .sizing.reset-size1.size6 {
    font-size: 2em
}

.katex .fontsize-ensurer.reset-size1.size7,.katex .sizing.reset-size1.size7 {
    font-size: 2.4em
}

.katex .fontsize-ensurer.reset-size1.size8,.katex .sizing.reset-size1.size8 {
    font-size: 2.88em
}

.katex .fontsize-ensurer.reset-size1.size9,.katex .sizing.reset-size1.size9 {
    font-size: 3.456em
}

.katex .fontsize-ensurer.reset-size1.size10,.katex .sizing.reset-size1.size10 {
    font-size: 4.148em
}

.katex .fontsize-ensurer.reset-size1.size11,.katex .sizing.reset-size1.size11 {
    font-size: 4.976em
}

.katex .fontsize-ensurer.reset-size2.size1,.katex .sizing.reset-size2.size1 {
    font-size: .8333333333em
}

.katex .fontsize-ensurer.reset-size2.size2,.katex .sizing.reset-size2.size2 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size2.size3,.katex .sizing.reset-size2.size3 {
    font-size: 1.1666666667em
}

.katex .fontsize-ensurer.reset-size2.size4,.katex .sizing.reset-size2.size4 {
    font-size: 1.3333333333em
}

.katex .fontsize-ensurer.reset-size2.size5,.katex .sizing.reset-size2.size5 {
    font-size: 1.5em
}

.katex .fontsize-ensurer.reset-size2.size6,.katex .sizing.reset-size2.size6 {
    font-size: 1.6666666667em
}

.katex .fontsize-ensurer.reset-size2.size7,.katex .sizing.reset-size2.size7 {
    font-size: 2em
}

.katex .fontsize-ensurer.reset-size2.size8,.katex .sizing.reset-size2.size8 {
    font-size: 2.4em
}

.katex .fontsize-ensurer.reset-size2.size9,.katex .sizing.reset-size2.size9 {
    font-size: 2.88em
}

.katex .fontsize-ensurer.reset-size2.size10,.katex .sizing.reset-size2.size10 {
    font-size: 3.4566666667em
}

.katex .fontsize-ensurer.reset-size2.size11,.katex .sizing.reset-size2.size11 {
    font-size: 4.1466666667em
}

.katex .fontsize-ensurer.reset-size3.size1,.katex .sizing.reset-size3.size1 {
    font-size: .7142857143em
}

.katex .fontsize-ensurer.reset-size3.size2,.katex .sizing.reset-size3.size2 {
    font-size: .8571428571em
}

.katex .fontsize-ensurer.reset-size3.size3,.katex .sizing.reset-size3.size3 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size3.size4,.katex .sizing.reset-size3.size4 {
    font-size: 1.1428571429em
}

.katex .fontsize-ensurer.reset-size3.size5,.katex .sizing.reset-size3.size5 {
    font-size: 1.2857142857em
}

.katex .fontsize-ensurer.reset-size3.size6,.katex .sizing.reset-size3.size6 {
    font-size: 1.4285714286em
}

.katex .fontsize-ensurer.reset-size3.size7,.katex .sizing.reset-size3.size7 {
    font-size: 1.7142857143em
}

.katex .fontsize-ensurer.reset-size3.size8,.katex .sizing.reset-size3.size8 {
    font-size: 2.0571428571em
}

.katex .fontsize-ensurer.reset-size3.size9,.katex .sizing.reset-size3.size9 {
    font-size: 2.4685714286em
}

.katex .fontsize-ensurer.reset-size3.size10,.katex .sizing.reset-size3.size10 {
    font-size: 2.9628571429em
}

.katex .fontsize-ensurer.reset-size3.size11,.katex .sizing.reset-size3.size11 {
    font-size: 3.5542857143em
}

.katex .fontsize-ensurer.reset-size4.size1,.katex .sizing.reset-size4.size1 {
    font-size: .625em
}

.katex .fontsize-ensurer.reset-size4.size2,.katex .sizing.reset-size4.size2 {
    font-size: .75em
}

.katex .fontsize-ensurer.reset-size4.size3,.katex .sizing.reset-size4.size3 {
    font-size: .875em
}

.katex .fontsize-ensurer.reset-size4.size4,.katex .sizing.reset-size4.size4 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size4.size5,.katex .sizing.reset-size4.size5 {
    font-size: 1.125em
}

.katex .fontsize-ensurer.reset-size4.size6,.katex .sizing.reset-size4.size6 {
    font-size: 1.25em
}

.katex .fontsize-ensurer.reset-size4.size7,.katex .sizing.reset-size4.size7 {
    font-size: 1.5em
}

.katex .fontsize-ensurer.reset-size4.size8,.katex .sizing.reset-size4.size8 {
    font-size: 1.8em
}

.katex .fontsize-ensurer.reset-size4.size9,.katex .sizing.reset-size4.size9 {
    font-size: 2.16em
}

.katex .fontsize-ensurer.reset-size4.size10,.katex .sizing.reset-size4.size10 {
    font-size: 2.5925em
}

.katex .fontsize-ensurer.reset-size4.size11,.katex .sizing.reset-size4.size11 {
    font-size: 3.11em
}

.katex .fontsize-ensurer.reset-size5.size1,.katex .sizing.reset-size5.size1 {
    font-size: .5555555556em
}

.katex .fontsize-ensurer.reset-size5.size2,.katex .sizing.reset-size5.size2 {
    font-size: .6666666667em
}

.katex .fontsize-ensurer.reset-size5.size3,.katex .sizing.reset-size5.size3 {
    font-size: .7777777778em
}

.katex .fontsize-ensurer.reset-size5.size4,.katex .sizing.reset-size5.size4 {
    font-size: .8888888889em
}

.katex .fontsize-ensurer.reset-size5.size5,.katex .sizing.reset-size5.size5 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size5.size6,.katex .sizing.reset-size5.size6 {
    font-size: 1.1111111111em
}

.katex .fontsize-ensurer.reset-size5.size7,.katex .sizing.reset-size5.size7 {
    font-size: 1.3333333333em
}

.katex .fontsize-ensurer.reset-size5.size8,.katex .sizing.reset-size5.size8 {
    font-size: 1.6em
}

.katex .fontsize-ensurer.reset-size5.size9,.katex .sizing.reset-size5.size9 {
    font-size: 1.92em
}

.katex .fontsize-ensurer.reset-size5.size10,.katex .sizing.reset-size5.size10 {
    font-size: 2.3044444444em
}

.katex .fontsize-ensurer.reset-size5.size11,.katex .sizing.reset-size5.size11 {
    font-size: 2.7644444444em
}

.katex .fontsize-ensurer.reset-size6.size1,.katex .sizing.reset-size6.size1 {
    font-size: .5em
}

.katex .fontsize-ensurer.reset-size6.size2,.katex .sizing.reset-size6.size2 {
    font-size: .6em
}

.katex .fontsize-ensurer.reset-size6.size3,.katex .sizing.reset-size6.size3 {
    font-size: .7em
}

.katex .fontsize-ensurer.reset-size6.size4,.katex .sizing.reset-size6.size4 {
    font-size: .8em
}

.katex .fontsize-ensurer.reset-size6.size5,.katex .sizing.reset-size6.size5 {
    font-size: .9em
}

.katex .fontsize-ensurer.reset-size6.size6,.katex .sizing.reset-size6.size6 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size6.size7,.katex .sizing.reset-size6.size7 {
    font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size6.size8,.katex .sizing.reset-size6.size8 {
    font-size: 1.44em
}

.katex .fontsize-ensurer.reset-size6.size9,.katex .sizing.reset-size6.size9 {
    font-size: 1.728em
}

.katex .fontsize-ensurer.reset-size6.size10,.katex .sizing.reset-size6.size10 {
    font-size: 2.074em
}

.katex .fontsize-ensurer.reset-size6.size11,.katex .sizing.reset-size6.size11 {
    font-size: 2.488em
}

.katex .fontsize-ensurer.reset-size7.size1,.katex .sizing.reset-size7.size1 {
    font-size: .4166666667em
}

.katex .fontsize-ensurer.reset-size7.size2,.katex .sizing.reset-size7.size2 {
    font-size: .5em
}

.katex .fontsize-ensurer.reset-size7.size3,.katex .sizing.reset-size7.size3 {
    font-size: .5833333333em
}

.katex .fontsize-ensurer.reset-size7.size4,.katex .sizing.reset-size7.size4 {
    font-size: .6666666667em
}

.katex .fontsize-ensurer.reset-size7.size5,.katex .sizing.reset-size7.size5 {
    font-size: .75em
}

.katex .fontsize-ensurer.reset-size7.size6,.katex .sizing.reset-size7.size6 {
    font-size: .8333333333em
}

.katex .fontsize-ensurer.reset-size7.size7,.katex .sizing.reset-size7.size7 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size7.size8,.katex .sizing.reset-size7.size8 {
    font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size7.size9,.katex .sizing.reset-size7.size9 {
    font-size: 1.44em
}

.katex .fontsize-ensurer.reset-size7.size10,.katex .sizing.reset-size7.size10 {
    font-size: 1.7283333333em
}

.katex .fontsize-ensurer.reset-size7.size11,.katex .sizing.reset-size7.size11 {
    font-size: 2.0733333333em
}

.katex .fontsize-ensurer.reset-size8.size1,.katex .sizing.reset-size8.size1 {
    font-size: .3472222222em
}

.katex .fontsize-ensurer.reset-size8.size2,.katex .sizing.reset-size8.size2 {
    font-size: .4166666667em
}

.katex .fontsize-ensurer.reset-size8.size3,.katex .sizing.reset-size8.size3 {
    font-size: .4861111111em
}

.katex .fontsize-ensurer.reset-size8.size4,.katex .sizing.reset-size8.size4 {
    font-size: .5555555556em
}

.katex .fontsize-ensurer.reset-size8.size5,.katex .sizing.reset-size8.size5 {
    font-size: .625em
}

.katex .fontsize-ensurer.reset-size8.size6,.katex .sizing.reset-size8.size6 {
    font-size: .6944444444em
}

.katex .fontsize-ensurer.reset-size8.size7,.katex .sizing.reset-size8.size7 {
    font-size: .8333333333em
}

.katex .fontsize-ensurer.reset-size8.size8,.katex .sizing.reset-size8.size8 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size8.size9,.katex .sizing.reset-size8.size9 {
    font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size8.size10,.katex .sizing.reset-size8.size10 {
    font-size: 1.4402777778em
}

.katex .fontsize-ensurer.reset-size8.size11,.katex .sizing.reset-size8.size11 {
    font-size: 1.7277777778em
}

.katex .fontsize-ensurer.reset-size9.size1,.katex .sizing.reset-size9.size1 {
    font-size: .2893518519em
}

.katex .fontsize-ensurer.reset-size9.size2,.katex .sizing.reset-size9.size2 {
    font-size: .3472222222em
}

.katex .fontsize-ensurer.reset-size9.size3,.katex .sizing.reset-size9.size3 {
    font-size: .4050925926em
}

.katex .fontsize-ensurer.reset-size9.size4,.katex .sizing.reset-size9.size4 {
    font-size: .462962963em
}

.katex .fontsize-ensurer.reset-size9.size5,.katex .sizing.reset-size9.size5 {
    font-size: .5208333333em
}

.katex .fontsize-ensurer.reset-size9.size6,.katex .sizing.reset-size9.size6 {
    font-size: .5787037037em
}

.katex .fontsize-ensurer.reset-size9.size7,.katex .sizing.reset-size9.size7 {
    font-size: .6944444444em
}

.katex .fontsize-ensurer.reset-size9.size8,.katex .sizing.reset-size9.size8 {
    font-size: .8333333333em
}

.katex .fontsize-ensurer.reset-size9.size9,.katex .sizing.reset-size9.size9 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size9.size10,.katex .sizing.reset-size9.size10 {
    font-size: 1.2002314815em
}

.katex .fontsize-ensurer.reset-size9.size11,.katex .sizing.reset-size9.size11 {
    font-size: 1.4398148148em
}

.katex .fontsize-ensurer.reset-size10.size1,.katex .sizing.reset-size10.size1 {
    font-size: .2410800386em
}

.katex .fontsize-ensurer.reset-size10.size2,.katex .sizing.reset-size10.size2 {
    font-size: .2892960463em
}

.katex .fontsize-ensurer.reset-size10.size3,.katex .sizing.reset-size10.size3 {
    font-size: .337512054em
}

.katex .fontsize-ensurer.reset-size10.size4,.katex .sizing.reset-size10.size4 {
    font-size: .3857280617em
}

.katex .fontsize-ensurer.reset-size10.size5,.katex .sizing.reset-size10.size5 {
    font-size: .4339440694em
}

.katex .fontsize-ensurer.reset-size10.size6,.katex .sizing.reset-size10.size6 {
    font-size: .4821600771em
}

.katex .fontsize-ensurer.reset-size10.size7,.katex .sizing.reset-size10.size7 {
    font-size: .5785920926em
}

.katex .fontsize-ensurer.reset-size10.size8,.katex .sizing.reset-size10.size8 {
    font-size: .6943105111em
}

.katex .fontsize-ensurer.reset-size10.size9,.katex .sizing.reset-size10.size9 {
    font-size: .8331726133em
}

.katex .fontsize-ensurer.reset-size10.size10,.katex .sizing.reset-size10.size10 {
    font-size: 1em
}

.katex .fontsize-ensurer.reset-size10.size11,.katex .sizing.reset-size10.size11 {
    font-size: 1.1996142719em
}

.katex .fontsize-ensurer.reset-size11.size1,.katex .sizing.reset-size11.size1 {
    font-size: .2009646302em
}

.katex .fontsize-ensurer.reset-size11.size2,.katex .sizing.reset-size11.size2 {
    font-size: .2411575563em
}

.katex .fontsize-ensurer.reset-size11.size3,.katex .sizing.reset-size11.size3 {
    font-size: .2813504823em
}

.katex .fontsize-ensurer.reset-size11.size4,.katex .sizing.reset-size11.size4 {
    font-size: .3215434084em
}

.katex .fontsize-ensurer.reset-size11.size5,.katex .sizing.reset-size11.size5 {
    font-size: .3617363344em
}

.katex .fontsize-ensurer.reset-size11.size6,.katex .sizing.reset-size11.size6 {
    font-size: .4019292605em
}

.katex .fontsize-ensurer.reset-size11.size7,.katex .sizing.reset-size11.size7 {
    font-size: .4823151125em
}

.katex .fontsize-ensurer.reset-size11.size8,.katex .sizing.reset-size11.size8 {
    font-size: .578778135em
}

.katex .fontsize-ensurer.reset-size11.size9,.katex .sizing.reset-size11.size9 {
    font-size: .6945337621em
}

.katex .fontsize-ensurer.reset-size11.size10,.katex .sizing.reset-size11.size10 {
    font-size: .8336012862em
}

.katex .fontsize-ensurer.reset-size11.size11,.katex .sizing.reset-size11.size11 {
    font-size: 1em
}

.katex .delimsizing.size1 {
    font-family: KaTeX_Size1
}

.katex .delimsizing.size2 {
    font-family: KaTeX_Size2
}

.katex .delimsizing.size3 {
    font-family: KaTeX_Size3
}

.katex .delimsizing.size4 {
    font-family: KaTeX_Size4
}

.katex .delimsizing.mult .delim-size1>span {
    font-family: KaTeX_Size1
}

.katex .delimsizing.mult .delim-size4>span {
    font-family: KaTeX_Size4
}

.katex .nulldelimiter {
    display: inline-block;
    width: .12em
}

.katex .delimcenter,.katex .op-symbol {
    position: relative
}

.katex .op-symbol.small-op {
    font-family: KaTeX_Size1
}

.katex .op-symbol.large-op {
    font-family: KaTeX_Size2
}

.katex .accent>.vlist-t,.katex .op-limits>.vlist-t {
    text-align: center
}

.katex .accent .accent-body {
    position: relative
}

.katex .accent .accent-body:not(.accent-full) {
    width: 0
}

.katex .overlay {
    display: block
}

.katex .mtable .vertical-separator {
    display: inline-block;
    min-width: 1px
}

.katex .mtable .arraycolsep {
    display: inline-block
}

.katex .mtable .col-align-c>.vlist-t {
    text-align: center
}

.katex .mtable .col-align-l>.vlist-t {
    text-align: left
}

.katex .mtable .col-align-r>.vlist-t {
    text-align: right
}

.katex .svg-align {
    text-align: left
}

.katex svg {
    fill: currentColor;
    stroke: currentColor;
    fill-rule: nonzero;
    fill-opacity: 1;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
    display: block;
    height: inherit;
    position: absolute;
    width: 100%
}

.katex svg path {
    stroke: none
}

.katex img {
    border-style: none;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0
}

.katex .stretchy {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%
}

.katex .stretchy:after,.katex .stretchy:before {
    content: ""
}

.katex .hide-tail {
    overflow: hidden;
    position: relative;
    width: 100%
}

.katex .halfarrow-left {
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 50.2%
}

.katex .halfarrow-right {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 50.2%
}

.katex .brace-left {
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 25.1%
}

.katex .brace-center {
    left: 25%;
    overflow: hidden;
    position: absolute;
    width: 50%
}

.katex .brace-right {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 25.1%
}

.katex .x-arrow-pad {
    padding: 0 .5em
}

.katex .cd-arrow-pad {
    padding: 0 .55556em 0 .27778em
}

.katex .mover,.katex .munder,.katex .x-arrow {
    text-align: center
}

.katex .boxpad {
    padding: 0 .3em
}

.katex .fbox,.katex .fcolorbox {
    border: .04em solid;
    box-sizing: border-box
}

.katex .cancel-pad {
    padding: 0 .2em
}

.katex .cancel-lap {
    margin-left: -.2em;
    margin-right: -.2em
}

.katex .sout {
    border-bottom-style: solid;
    border-bottom-width: .08em
}

.katex .angl {
    border-right: .049em solid;
    border-top: .049em solid;
    box-sizing: border-box;
    margin-right: .03889em
}

.katex .anglpad {
    padding: 0 .03889em
}

.katex .eqn-num:before {
    content: "(" counter(katexEqnNo) ")";
    counter-increment: katexEqnNo
}

.katex .mml-eqn-num:before {
    content: "(" counter(mmlEqnNo) ")";
    counter-increment: mmlEqnNo
}

.katex .mtr-glue {
    width: 50%
}

.katex .cd-vert-arrow {
    display: inline-block;
    position: relative
}

.katex .cd-label-left {
    display: inline-block;
    position: absolute;
    right: calc(50% + .3em);
    text-align: left
}

.katex .cd-label-right {
    display: inline-block;
    left: calc(50% + .3em);
    position: absolute;
    text-align: right
}

.katex-display {
    display: block;
    margin: 1em 0;
    text-align: center
}

.katex-display>.katex {
    display: block;
    text-align: center;
    white-space: nowrap
}

.katex-display>.katex>.katex-html {
    display: block;
    position: relative
}

.katex-display>.katex>.katex-html>.tag {
    position: absolute;
    right: 0
}

.katex-display.leqno>.katex>.katex-html>.tag {
    left: 0;
    right: auto
}

.katex-display.fleqn>.katex {
    padding-left: 2em;
    text-align: left
}

/*----------------------------------------Katex end ------------------------------*/