:root {
    --small-height: 35px;
    --small-padding: 0 15px;
    --medium-height: 40px;
    --medium-padding: 0 20px;
    --large-height: 47px;
    --large-padding: 0 20px;
}

.small {
    height: var(--small-height);
    padding: var(--small-padding);
}

.medium {
    height: var(--medium-height);
    padding: var(--medium-padding);
}

.large {
    height: var(--large-height);
    padding: var(--large-padding);
}

.container {
    position: relative;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    background-color: #FFFFFF;
    cursor: pointer;
}

.notificationContainer {
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    background-color: #FFFFFF;
    cursor: default;
}

.presenter {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #6F6F6F;
    border-radius: 4px;
    gap: 5px;
}

/*.presenter:focus {*/
/*    border: 1px solid #0B6F85 !important;*/
/*    box-shadow: 0px 0px 4px 0px #0B6F8540 !important;*/
/*}*/


.presenter:hover {
    background-color: #F5F5F5;
}

.opened {
    border-bottom-color: transparent;
    border-radius: 4px 4px 0 0;
}

.invalid {
    border: 1px solid #BF0033;
}

.disabled{
    opacity: .5;
    background-color: #E6E6E6;
    cursor: not-allowed;
}

.presenterText {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: default;
    background: transparent;
}


.menuListContainer {
    position: fixed;
    width: 100%;
    max-height: 100px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background-color: #FFFFFF;
}

.menuList {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    /*overflow-y: auto;*/
    border-width: 1px;
    border-color: #6F6F6F;
    border-style: solid;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
}

.notificationMenuList {
    position: absolute;
    width: 100px;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-color: #D0D0D0;
    z-index: 100;
    left: 209px;
}

.notificationMenuListNew {
    top: 406px !important;
}

.notificationMenuListEdit {
    top: 281px !important;
}

.menuList::-webkit-scrollbar-track, .notificationMenuList::-webkit-scrollbar-track {
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: #D0D0D0;
}

.searchBar {
    flex-shrink: 0;
    display: flex;
    gap: 5px;
    align-items: center;
    border: 1px solid #6F6F6F;
    border-bottom: none;
    cursor: default;
}

.menuItem {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #6F6F6F;
    background-color: #FFFFFF;
    cursor: pointer;
}

.menuItem:last-child {
    border-bottom: none;
}

.menuItem:not(:first-child):not(:last-child){
    border-top: none;
}

.menuItem:first-child {
    border-top: 0;
    border-radius: 0;
}

.menuItemText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menuItem:hover:not(.selected) {
    background-color: #ECECEC;
}

.selected {
    background-color: #E7F1F3;
}

.iconContainer {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: default;
}
.invalidIcon {
    position: absolute;
    margin-left: 3px;
    width: 25px;
    height: 25px;
    background: url("../../images/invalidIcon.svg");
    background-size: 25px 25px;
}

.invalidIcon:hover {
    background: url("../../images/invalidIcon_hover.svg");
}

