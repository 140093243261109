.container {
    display: flex;
    flex: 1;
    height: 100%;
}

.goBackLink {
    display: flex;
    align-items: center;
    align-self: flex-start;
    cursor: pointer;
    gap: 5px;
}

.goBackLink:hover {
    color: #42296E;
}

.questionEditViewParentContainer {
    display: flex;
    flex-direction: column;
    gap: min(5%, 30px);
    background-color: #FCFBFF;
    padding: 30px;
    height: 100%;
    width: 100%;
}