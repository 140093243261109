.box {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    box-shadow: 0 0 0 1px #B9B9B9!important;
    border-radius: 3px;
    background-color: #FFFFFF;
    line-height: normal;
    font-size: 1rem;
}

.box:not([class*='disabled']):hover,.box:not([class*='disabled']) input:hover  {
    cursor: text;
}

.box:focus-within {
    box-shadow: 0 0 0 2px #42296E!important;
}

.box input {
    max-width: 100%;
    width: inherit;
    background-color: #FFFFFF;
    padding: 0;
    margin: 0;
    flex: 1;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.box input:focus {
    box-shadow: none;
}

.box input:-webkit-autofill{
    transition: background-color 5000s ease-in-out 0s;
}

.circle {
    background-color: #0B6F85;
}

.disabled{
    opacity: .5;
    background-color: #E6E6E6;
}
.disabled input {
    background-color: #E6E6E6;
}

.invalid {
    box-shadow: 0 0 0 1px #BF0033!important;
}

input[placeholder], input[placeholder]:focus {
    text-overflow:ellipsis!important;
    white-space: nowrap!important;
    overflow: hidden!important;
}
input::-moz-placeholder, input::-moz-placeholder:focus {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
input:-moz-placeholder, input:-moz-placeholder:focus {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
input:-ms-input-placeholder, input:-ms-input-placeholder:focus {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

