.navBarContainer {
    width: 100%;
    height: 52px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #F4F2F9 0%, #F7F8FB 18.45%, #F8F9FC 60.68%, #F3ECF8 100%);
    border-bottom: 1px solid #ECECEC;
    gap: 5px;
}

.responsiveNavBarContainer {
    background: linear-gradient(90deg, #F4F2F9 0%, #F7F8FB 18.45%, #F8F9FC 60.68%, #F3ECF8 100%);
}

.logoContainer {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid #BDBDBD;
}

.clickable {
    cursor: pointer;
}

.pathContainer {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    color: #3E2769;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navBarContainer [class*='nav-link'] {
    padding: 0;
}