.container {
    width: 100%;
    flex: 1;
    display: flex;
    padding: 0 100px;
    flex-direction: column;
    align-items: center;
    background-color: #f6f4fa;
}

.title {
        font-style: normal;
    font-weight: 600;
    font-size: 23px;
    color: #000000;
}

.description {
        font-size: 16px;
}


.title:after {
    content: "";
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    font-size: 20px;
    font-weight: 600;
}


@keyframes loading-text {
    0% {
        content: "";
    }
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
    }
}




.reStartButton {
    padding: 5px 10px !important;
    background-color: #FFFFFF;
    color: #42296e;
    font-weight: 600;
    height: 40px;
    border: 2px solid #42296e;
    border-radius: 3px;
    font-style: normal;
    font-size: 15px;
    margin-top: 10px;
}

.reStartButton:hover {
    background: #42296e;
    color: #FFFFFF;
}

.animationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

