.react-datepicker-component .react-datepicker-input {
    min-width: auto !important;
}

.react-datepicker-component .react-datepicker-input input {
    padding-right: 40px !important;

}

.recycleCheckbox [class='form-check-input']:checked {
    background-color: 
    #0B6F85;
    border-color: 
    #0B6F85;
  }

  .recycleCheckbox [class='form-check-input']:indeterminate {
    background-color: 
    #0B6F85;
    border-color: 
    #0B6F85;
  }