@import "@fontsource/roboto";

.container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 0%;
}

.formWrapper {
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftWrapper {
  height: 100%;
  overflow-y: auto;
  background: #FAFCFD;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 38px;
  color: #464646;
  margin-top: min(8%, 35px);
  margin-bottom: min(8%, 35px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #1D1D1D;
}

.title3 {
  width: 100%;
    font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #464646;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.title2 {
    font-style: normal;
  font-weight: 600;
  font-size: 17.4221px;
  line-height: 26px;
  color: #464646;
}

.keepMeSignInCheckbox [class='form-check-input']:checked {
  background-color: #ffd346;
  border-color: #ffd346;
}

.signInButton {
  padding: 10px 15px !important;
  background: #ffd786;
  color: #000000;
  font-weight: 600;
  width: 100%;
  height: 66px;
  border: 0.87px solid #F1B843;
  border-radius: 7px;
  font-family: 'SanFranciscoPro';
  font-style: normal;
  font-size: 19.026px;
  line-height: 22px;
  margin-bottom: 30px;
}

.signInButton:hover {
  background: #F1B843;
  color: #000;
}

.disabledSignInButton {
  background-color:#DADADA;
  padding: 10px 15px !important;
  color: #000000;
  font-weight: 600;
  width: 100%;
  height: 66px;
  border-radius: 7px;
  font-family: 'SanFranciscoPro';
  font-style: normal;
  font-size: 19.026px;
  line-height: 22px;
  margin-bottom: 30px;
}


.bottomText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #464646;
  text-align: center;
  margin: 5%;
}

.bottomText:hover {
  color: #0277AD;
  cursor: pointer;
}

.forgotPasswordText {
  width: fit-content;
  justify-self: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #42296E;
  text-align: center;
  margin: 20px 0;
  text-decoration: underline;
  cursor: pointer;
}



.bottomTextA {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #464646;
  text-align: center;
  margin-right: 1%;
}

.bottomTextB {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0277AD;
  text-align: center;
  cursor: pointer;
}

.alert {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #C93E3E;
  background-color: transparent;
  border: none;
}

.inputTitle {
    font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #464646;
  margin: 0%;
  vertical-align: middle;
}

.inputBox {
  flex-shrink: 0;
  height: 50px;
  font-size: 16px !important;
  line-height: 16px !important; 
}

.inputTitleBoxReset {
  line-height: 42px;
  padding-right: 0%;
}

.inputTitleBoxLogin {
  line-height: 42px;
  width: max(90px, 100%);
}

.inputTitleBoxForgot {
  line-height: 42px;
  width: max(150px, 30%);
}

.pwHintIcon {
  margin-right: 2%;
}

.pwHintText {
  font-family: 'SanFranciscoPro';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

.paswwordInputBoxHolder .eye {
  display: none;
}

.paswwordInputBoxHolder:focus-within .eye {
  display: flex;
}



.emailInHolder{
  width: 70%;
}

.loginInHolder{
  width: 100% !important;
  }

.paswwordLoginInputBoxHolder{
  width: 100% !important;
}

.paswwordLoginInputBoxHolder .eye {
  display: none;
}

.paswwordLoginInputBoxHolder:focus-within .eye {
  display: flex;
}

.separateLine {
  background-color: rgba(170, 170, 170, 1);
  height: 0.5px;
  width: 75%;
  margin-bottom: 5%;
}

.joinPollBtn {
  width: 70%;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  border: 2px solid rgba(2, 119, 173, 1);
  color: rgba(2, 119, 173, 1);
  padding: 22px 0;
  border-radius: 7px;
}

.joinPollBtn:hover {
  color: rgba(250, 252, 253, 1);;
  background-color: rgba(2, 119, 173, 1);
  cursor: pointer;
}

.boldOption {
  font-weight: bold;
  width: 100%;
  height: 50px;
  border: 1.11px solid #939393;
  border-radius: 2.48157px 2.48157px 0px 0px;
    font-size: 16px;
  line-height: 16px;
  align-items: center;
  display: grid; 
  grid-template-columns: 90% 10%;
    color: #000;
}

.webLoginFooter {
    font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #464646;
}

.YuJaFooter {
  width: 70% ;
  margin-top: 60px;
}

.NormalFooter {
  width: 70% ;
  margin-top: 200px;
}

.LoginSelect {
  width: 100%;
  height: 50px;
  border: 1.11px solid #939393;
  border-radius: 2.48157px 2.48157px 0px 0px;
    font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  display: grid; 
  grid-template-columns: 80% 20%;
    color: #000;
}

.choiceOption {
  width: 100%;
  height: 50px;
  border: 1.11px solid #939393;
  border-radius: 2.48157px 2.48157px 0px 0px;
    font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  color: #000;
  background-color:#FAFCFD ;
  padding: 0 15px;
  align-items: center;
  border-top: none;
  cursor: pointer;
}

.selectedLoginMethodOption {
  background-color: #e8f0fe;
}

.titleResetPwd {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #464646;
  margin-top: min(8%, 35px);
  margin-bottom: min(8%, 35px);
  text-align: center;
  gap: 10px;
}

.goBackRow {
  position: absolute;
  left: 5%;
  top: 0;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.resetBar {
  width: 100%;
  background-color: #FFE2E4;
  padding: 8px 15px;
  font-family: SanFranciscoPro;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 10px;
}

.attentionIcon path {
  fill: #AC002E;
}

@media screen and (max-width: 949px) {
  .titleResetPwd {
    font-size: 28px;
    line-height: 28px;
  }

  .title {
    font-size: 28px;
    line-height: 28px;
  }
}