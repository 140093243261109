.container {
    display: flex;
    flex-direction: column;
    padding: 30px 5% 30px 5%;
    align-items: center;
    background-color: #FCFBFF;
    gap: 30px;
    flex: 1;
}

.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid #EDEDED !important;
    border-radius: 5px 5px 0 0 !important;
}

.skeletonHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    height: 62px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    border-bottom: 0.031rem solid #EDEDED !important;
    padding-left: 10px;
    text-align: center;
    background-color:  #F7F6FB!important;
}

.titleBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/links/questionbank_active_icon.svg");
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;
}


.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
        font-weight: 600;
    vertical-align: middle !important;
    justify-content: left !important;
    text-align: center !important;
    width: 100%;
}

.skeletonActionHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
}

.skeletonBodyContainer {
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    border: 1px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px;
    padding: 10px 0px;
    display: flex !important;
    justify-content: left;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonActionBodyCell {
    display: flex !important;
    justify-content: center;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    gap: 20px;
}

.tableWrapper {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}

.firstCell {
    padding-left: 10px !important;
}

.tableCell {

}

.paginationContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}


.searchBox {
    font-family: SanFranciscoPro;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    width: 100% !important;
    height: 60px;
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
    /* padding: 20px; */
    justify-content: center;
}

.searchBoxForm {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 10px;
    width: 50vw;
    align-items: center;
}

.searchFeedbackForm {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 20px;
    width: 50vw;
    align-items: center;
}

.searchBoxKeywordInput {
    /* height: 45px; */
    /* width: 260px; */
    padding-left: 37px;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxDateInput {
    height: 35px;
    width: 130px;
    margin-right: 15px;
    border: 0.083rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus, .searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem #42296E;
}

.searchBoxSearchBtn {
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}