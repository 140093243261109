.question-main-container {
  position: relative !important;
}

.question-row {
  margin-top: 1.25rem !important;
  margin-bottom: 0.875rem !important;
}

.question-header-col {
  flex: 0 0 auto !important;
  width: 58.33333333% !important;
}

.question-input-col {
  flex: 0 0 auto !important;
  width: 76% !important;
}

@media only screen and (max-width: 1650px) {
  .question-input-col {
    width: 71% !important;
  }

  .question-header-col {
    width: 40.33333333% !important;
  }

  /* .dropdownText {
    font-size: 11px !important;
  } */
}

.img-container-ci-poll {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 950px) {
  .img-container-ci-poll {
    max-width: 250px;
    max-height: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .question-input-col {
    width: 71% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .question-input-col {
    width: 65% !important;
  }
}

.fitb-row {
  margin-top: 0.3rem !important;
  margin-bottom: 1.25rem !important;
}

.fitb-blank {
  color: #029ce2;
  font-weight: 700;
  font-style: normal;
}

.radio-button-column {
  flex: 0 0 auto !important;
  width: 9.733333% !important;
}

.fitb-add-answer-col {
  flex: 0 0 auto !important;
  width: 5.33333333% !important;
}

.TF-radio-button-column {
  flex: 0 0 auto !important;
  width: 15% !important;
  margin-right: 0px !important;
  margin-left: 0.879rem !important;
}

.TF-delete-icon {
  flex: 0 0 auto !important;
  width: 4.333333% !important;
}

.drag-icon-column {
  flex: 0 0 auto !important;
  width: 10px !important;
  margin-right: 10px; 
}

.cont-radio {
  flex: 0.2 !important;
}

.cont-radio-survey {
  flex: 0 !important;
  padding-left: 2px !important;
}

.questions-page-icons {
  width: 2.25rem;
}

input[type="file"] {
  display: none;
}

.fitb-containers {
  min-height: 5.188rem;
  background: #ffffff;
}

.fitb-titles {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #000000;
  margin-left: 9px !important;
  padding: 0px !important;
}

.fitb-desc {
  font-size: 14px;
  color: rgb(83, 83, 83);
  font-style: italic;
}

.fitb-input-col {
  flex: 0 0 auto !important;
  width: 100% !important;
  padding: 0px !important;
}

@media only screen and (max-width: 950px) {
  .fitb-input-col {
    width: 100% !important;
  }
}

.add-blank-col {
  padding: 0px !important;
}

@media only screen and (max-width: 950px) {
  .add-blank-col {
    width: 100% !important;
    margin-right: 10px;
  }
}

.fitb-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  margin: 5px;
  color: #6c6c6c;
}

.fitb-blank-col {
  flex: 0 0 auto !important;
  width: 10.66666667% !important;
}

.ccontainer {
  padding-top: 1rem;
}

.text-required {
  color: red !important;
}

.btn-primary {
  color: #fff;
  background-color: rgb(33, 89, 179) !important;
  border-color: rgb(33, 89, 179) !important;
}
.margin-right {
  margin-right: 20px;
}

.image-margin {
  margin-bottom: 5px;
  margin-left: 3.75px;
}

.dropdown-image-margin {
  margin-bottom: 2px;
  margin-left: 3.75px;
}

.dropdown-menu {
  width: 100% !important;
}

.navDropdown .dropdown-menu {
  padding: 0px !important;
}

/* .poll-navdropdown-item {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px;
  line-height: 20px;
  color: #000000 !important;
  background: #fefefe;
  box-sizing: border-box;
  border-radius: 0px;
  width: 100%;
} */

.question-type-title {
    font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  text-align: left;
  color: #000;
}

.crow-e {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 80% !important;
}

.margin-bottom {
  margin-bottom: 20px;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.customized-form {
  /* width: 90%; */
  /* margin: auto; */
}

.accessibilityIcon{
  padding-left: 10px;
}

.image-upload-container, .image-upload-container-disabled {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 62px;
  background: #F7EFF6;
  border: 2px dashed #42296E;
  border-radius: 4px;
  margin: 15px 0;
  cursor: pointer;
}

.image-upload-container-disabled {
  cursor: not-allowed !important;
}

.question-builder-image-upload-container {
  margin-top: 0;
}

.upload-icon {
  margin-left: 50.5px;
}

.drag-drop-text {
  display: inline;
  width: 171px;
    font-style: normal;
  font-weight: 400;
  font-size: 15.818px;
  color: #444444;
  margin: 0;
}

.drag-drop-text-narrow {
  display: inline;
    font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #444444;
  margin: 0;
}

.or-text {
  display: inline;
  margin-left: 78px;
  width: 15px;
  height: 10px;
    font-style: normal;
  font-weight: 400;
  font-size: 15.4754px;
  line-height: 15px;
  color: #444444;
}

.browse-box {
  box-sizing: border-box;
  margin-left: 55.5px;
  margin-top: 10px;
  padding: 10px;
  gap: 8.41px;
  width: 60px;
  height: 32px;
  background: #FFFFFF;
  border: 1.68204px solid #0277AD;
  border-radius: 4.20511px;
}

.browse-text {
  display: inline;
  width: 40px;
  height: 12px;
    font-style: normal;
  font-weight: 250;
  font-size: 12px;
  line-height: 12px;
  color: #0277AD;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.image-preview-container {
  text-align: end;
  justify-content: center;
  margin-bottom: 25px;
}

.preview {
  max-width: 90%;
  max-height: 90%;
}


@media only screen and (max-height: 750px){
  .preview {
    max-height: 80%;
  }
}

.close-button {
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.MH-response {
  width: 100% !important; 
}

.Create-quiz-question-type {
  border-radius: 39.828px;
  border: 1.069px solid #42296E;
  width: 165.243px;
  height: 38.133px;
  padding: 6.355px; 
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(239deg, rgba(242, 140, 185, 0.08) -23.35%, rgba(239, 137, 184, 0.08) -21.87%, rgba(186, 84, 161, 0.08) 2.25%, rgba(153, 51, 147, 0.08) 20.37%, rgba(141, 39, 142, 0.08) 30.32%, rgba(123, 41, 143, 0.08) 44.92%, rgba(77, 46, 144, 0.08) 74.01%, rgba(46, 49, 145, 0.08) 91.51%);
}

.CreateQuestionTypeContainer {
  border-radius: 4.205px;
  border: 2px solid #42296E;
  height: 33px;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.41px; 
}

.droppable-multiple-choice-question-options {
  /*height: 60vh;*/
  /*overflow-x: hidden;*/
}

.droppable-ranking-choice-question-options {
  overflow-y: auto; 
  height: 60vh;
  overflow-x: hidden;
}

.question-builder-options {
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-height: 1700px) {
  .droppable-multiple-choice-question-options {
    /*height: 50vh;*/
  }
}

@media only screen and (max-height: 1050px) {
  .droppable-multiple-choice-question-options {
    /*height: 40vh;*/
  }
}

@media only screen and (max-height: 850px){
  .droppable-multiple-choice-question-options {
    /*height: 30vh;*/
  }


  .droppable-ranking-choice-question-options {
    height: 50vh;
  }
}

@media only screen and (max-height: 735px){
  .droppable-multiple-choice-question-options {
    /*height: 20vh;*/
  }

  .droppable-ranking-choice-question-options {
    height: 40vh;
  }
}

@media only screen and (max-height: 600px){
  .droppable-multiple-choice-question-options {
    /*height: 15vh;*/
  }

  .droppable-ranking-choice-question-options {
    height: 30vh;
  }
}

@media only screen and (max-height: 480px){
  .droppable-multiple-choice-question-options {
    /*height: 10vh;*/
  }

}

.radius-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 45px !important;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.radius-label {
  /* line-height: 23px; */
  margin-right: 10px;
}

.radius-value {
  margin-right: 3px;
}

/* .radius-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none !important;
  background: #432A6F !important;
} */

.radius-slider {
  width: 100%;
  margin-left: 5px;
  margin-right: 15px;
  /* overflow: hidden; */
  height: 10px;
  cursor: pointer;
  padding: 0;
  border-radius: 5px !important;
  /* background-color: transparent; */
  /* accent-color : #432A6F; */
}

.radius-slider,
.radius-slider::-webkit-slider-runnable-track,
.radius-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.radius-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  /* background: #E1DCE9; */
  /* background: linear-gradient(to right, #432A6F 0%, #432A6F ${val}%,#E1DCE9 ${val}%, #E1DCE9 100%); */
  /* border-radius: 5px !important; */
  /* -moz-fill-lower: #432A6F; */
}

.radius-slider::-webkit-slider-thumb {
  position: relative;
  height: 100px;
  width: 10px;
  margin-top: -45px;
  background: #7D65A7;
  /* border-radius: 50%; */
  clip-path: rect(40px auto 60px 0px);
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  border: 0.7px solid #432A6F; */
}

.radius-slider::-webkit-slider-thumb::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 5000px; /* make this bigger than the widest range input element */
  left: -5002px; /* this should be -2px - width */
  top: 8px; /* don't change this */
  background: #777;
  border: 3px solid #432A6F; /* Add border to the slider thumb */
}

/* .radius-slider::-moz-fill-lower, .radius-slider::-ms-fill-lower {
  background: #432A6F !important;
} */

/* .radius-slider::-ms-fill-upper {
  background: #E1DCE9;
} */

.leaflet-control-zoom {
  border-radius: 5px !important;
  border-width: 0px !important;
  box-shadow: 0 1px 4px rgba(0,0,0,.3) !important;
  overflow: hidden !important;
}

.leaflet-bar a {
  color: #42296E;
  /* background-color: #FFF; */
}

.leaflet-bar a:hover {
  color: #FFF;
  background-color: #42296E !important;
}

.leaflet-disabled, .leaflet-disabled:hover {
  color: #311C56 !important;
  background-color: #C2BBCF !important;
}

/* .leaflet-sidebar-tabs, .leaflet-sidebar-content {
  display: none !important;
}

.sidebar-content {
  width: 150px !important;
  max-height: 200px !important;
  overflow-y: auto;
}

.sidebar-item {
  width: 100% !important;
  height: 40px !important;
  border-bottom: 1px solid #E1DCE9;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #FFF;
}

.leaflet-left {
  left: 165px !important;
} */

.leaflet-pane {
  z-index: 1 !important;
}

.leaflet-top {
  z-index: 1 !important;
}

/* .saved-locations-list-header {
  margin: 10px 0;
} */

.saved-locations-max-text {
  font-weight: 400;
  font-size: 14px;
  color: red;
  display: flex;
  margin-top: 5px;
}

.saved-locations-row {
  width: 100% !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.saved-locations-label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  width: 125px;
}

.locate-me-active {
  /* background: #FFF;
  color: #FFF; */
  padding: 0px !important;
}

.fitb-title-footer {
  width: 100% !important;
  display: flex;
  align-items: flex-end;
  padding: 5px 0 0 10px;
}

.fitb-char-count {
  /* width: 30px; */
  height: 15px;
  text-align: right;
  line-height: 15px;
  font-size: 12px;
  color: #747778 !important;
  white-space: nowrap;
}

/* .fitb-builder-char-count {
  width: 30px;
  height: 15px;
  text-align: right;
  line-height: 15px;
  font-size: 12px;
  color: #000 !important;
  opacity: 0.5;
} */

.error-display {
  color: #B40030;
	transition: all 0.5s ease-in-out 0s;
	width: 100%;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
}

.option-image-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}


.saved-location-instruction-container {

}



.create-question-guidance-container {
  display: inline-block;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  line-height: 14px;
  color: #535353;
  margin-top: 5px;
  /*margin-bottom: 15px;*/
  font-style: italic;
}

.create-question-guidance {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  z-index: 100;
  padding-top: 15px;
}


.question-guidance-left {
  left: 0;
}

.question-guidance-right {
  right: 0;
}

.question-guidance-left:after {
  background-color: #F7F4FC;
  /*box-shadow: 0 .5px 5px 0 #0000001f;*/
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  left: 60px;
  top: 7px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-left: 1px solid #E4E4E4;
  border-top: 1px solid #E4E4E4;
  z-index: 1;
}

.question-guidance-right:after {
  background-color: #F7F4FC;
  /*box-shadow: 0 .5px 5px 0 #0000001f;*/
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  right: 40px;
  top: 7px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-left: 1px solid #E4E4E4;
  border-top: 1px solid #E4E4E4;
  z-index: 1;
}

.create-question-guidance-video-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #F7F4FC;
  border: 1px solid #E4E4E4;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 #00000066;
  overflow: auto;
  cursor: default;
}


.create-question-guidance-body {
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: #757575;
  font-size: 14px;
  font-style: normal;
  gap: 8px;
  z-index: 10;
}

.create-question-guidance-row {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.create-question-guidance-title {
  font-weight: 600;
  color: #38225E;
  font-size: 18px;
}


@media only screen and (max-width: 1000px) {
  .create-question-guidance-row {
    font-size: 12px;
  }

  .create-question-guidance-title {
    font-size: 15px;
  }

  .create-question-guidance-body {
    padding: 10px;
  }
}