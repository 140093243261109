.container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SanFranciscoPro;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #686868;
}

.circle {
    background-color: #0B6F85;
}

.disabled {
    opacity: .5;
    background-color: #E6E6E6;
    cursor: not-allowed;
}

.label {
    margin-left: 8px;
    flex: 1;
}

.disabledLabel {
    opacity: .5;
}