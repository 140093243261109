.container {
    display: flex;
    flex-direction: column;
    padding: 30px 5% 30px 5%;
    align-items: center;
    background-color: #FCFBFF;
    flex: 1;
}

.c2 {
    padding: 30px 5% 30px 5%;
    justify-content: center;
    width: 100%;
}

.titleBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.focusIcon:focus {
    outline: auto !important;
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/links/active_manage_users_icon.svg");
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;

}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin-top: 30px;
}

.card {
    display: flex !important;
    justify-content: center !important;
    width: 24%;
    margin: 0 0.5%;
    padding: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 7px;
    box-shadow: 1.7px 1.7px 9.2px 0.8px #00000014;
}

.cardNumber {
    font-size: 30px;
    font-weight: 400;
        line-height: 38px;
    text-align: center;
    color: #42296E;
}

.cardNumberSkeleton {
    display: flex !important;
    margin: auto auto 0 auto !important;
}

.cardNumberSkeletonWrapper {
    display: block;
    line-height: 1;
    height: 38px;
    padding: 4px 0px;
}

.cardCaption {
    font-size: 13px;
    font-weight: 400;
        line-height: 16px;
    text-align: center;
    color: #666666;
}

.searchBox {
    font-family: SanFranciscoPro;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: 30px 0;
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
}

.searchBoxForm {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.searchBoxKeywordInput {
    height: 45px;
    width: 40%;
    padding-left: 37px;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxDateInput {
    height: 35px;
    width: 130px;
    margin-right: 15px;
    border: 0.083rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus,
.searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxSearchBtn {
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

/*===================table===========================*/
.tableContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.tableWrapper {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}
    
.tableContainer table {
    table-layout: fixed;
    letter-spacing: 0;
}

.tableContainer td:last-child, .tableContainer th:last-child {
    text-align: center;
}



    .createUserButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        margin-left: 35px;
        margin-top: 15px;
        width: 170px;
        height: 50px;

        background: #FFFFFF;
        border: 1.73px solid #0277AD;
        border-radius: 4.68287px;
        /* background: url("../../images/plus\ 1.svg") no-repeat left; */

                font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.035em;

        color: #0277AD;
        white-space: nowrap;
    }

    .createUserButton:hover {
        cursor: pointer;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(0, 107, 155, 1);
    }

    .deleteSelectedBtn {
        margin-left: 10px;
        right: 7%;
        /* display: flex !important; */
        /* justify-self: flex-end !important; */
    }

    /*=================responsive====================*/
    @media only screen and (min-width: 250px) and (max-width: 950px) {
        .searchBoxKeywordInput {
            margin-left: 5px;
            padding-left: 27px;
            background: url("../../images/zoom.svg") no-repeat left;
            background-position-x: 5px;
            border: 0.066rem solid rgba(208, 208, 208, 1) !important;
        }
    
        .searchBoxDateInput {
            width: 80px;
            min-width: 50px;
        }
    
        .searchBoxSearchBtn {
            margin-right: 5px;
            padding: 0.5rem 0.6rem;
            border: 0.125rem solid rgba(0, 107, 155, 1);
            color: rgba(0, 107, 155, 1);
            font-size: 0.625rem;
            line-height: 0.625rem;
        }
    }
    
    .createButton {
        border-radius: 0.211rem;
        padding: 1.07rem 2.188rem 1.07rem 2.188rem;
        border: 0.253rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
        height: 15%;
    }
    
    .createButton:hover {
        background-color: rgba(2, 119, 173, 1);
        color: rgba(255, 255, 255, 1);
    }
    
    .center {
        margin: 5%;
        justify-content: center;
        text-align: center;
    }
    
    .text {
        justify-content: center;
        margin: 5%;
        text-align: center;
    }
 
    .checkboxCell {
        /* display: flex !important; */
        padding: 0 !important;
        /* justify-content: center; */
        vertical-align: middle !important;
        text-align: center;
        line-height: 0px !important;
    }

    /*.opHeaderCell {*/
    /*    padding: 0 !important;*/
    /*    font-weight: 600 !important;*/
    /*    text-align: center;*/
    /*}*/

    /*.otherHeaderCell {*/
    /*    padding: 0 !important;*/
    /*    font-weight: 600 !important;*/
    /*}*/

.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderContainer {
    height: 62px;
    display: grid;
    grid-template-columns: 3.89% 10% 10% 10% 8% 19% 10% 10% 10% 8%;
    align-items: center;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
    background-color:  #F7F6FB!important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
        font-weight: 600;
    vertical-align: middle !important;
    margin: auto auto auto 0 !important;
}

.skeletonCheckboxCellWrapper {
    display: block;
    line-height: 1;
    height: 48px;
    padding: 14px 0px;
}

.skeletonOpHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
    margin: auto auto !important;
    justify-content: left;
}

.skeletonBodyContainer {
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 3.89% 10% 10% 10% 8% 19% 10% 10% 10% 8%;
    align-items: center;
    border: 1px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px;
    padding: 10px 0px;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonOpBodyCell {
    display: flex !important;
    margin: auto auto 0 auto !important;
}

.noData {
    text-align: center !important;
}
