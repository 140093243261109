.menuBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    padding: 5px;
    border-bottom: 2px solid #EFEFEF;
}

.menuItem {
    position: relative;
    width: 32px;
    height: 26px;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #344054;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
}

.menuItem:not(:has(.selectedColorBarContainer)):not(.active):not(.disabled):hover {
    background-color: #cce2fa;
    border: 1px solid #cce2fa;
}

.menuItem.active {
    background-color: #a6ccf7;
    border: 1px solid #a6ccf7;
}


.menuItem:has(.selectedColorBarContainer):hover {
    border: 1px solid #cce2fa;
}


.menuItem.disabled {
    cursor: not-allowed;
}


.expandedMenuBar {
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    padding: 5px;
    background-color: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 #00000040;
    z-index: 1;
    cursor: default;
}

.selectedColorBarContainer {
    position: absolute;
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    z-index: 1;
}

.selectedColorBarContainer:hover {
    background-color: #cce2fa;
}

.selectedColorBar {
    position: absolute;
    bottom: 3px;
    left: 3px;
    width: 18px;
    height: 3px;
}

.colorChangeDropDown {
    width: 16px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorChangeDropDown:hover {
    background-color: #cce2fa;
}

.colorPicker {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
