:root {
    --dashboard-creat-btn-width: 180px;
    --panel-title-height: 50px;
}

.dashBoardContainer {
    height: 100%;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FCFBFF;
    padding: 3% 3%;
}

.leftContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
        font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0.035rem;
    border-radius: 7px;
    background: transparent;
}

.panelContainer {
        font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 1.7px 1.7px 9.2px 0.8px #00000014;
}

.panelSubContainer {
        font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0.035rem;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 1.7px 1.7px 9.2px 0.8px #00000014;
    height: 45% !important;
}

.v q {
        font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0.035rem;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 1.7px 1.7px 9.2px 0.8px #00000014;
}

@media only screen and (max-width: 1024px) {
    .leftContainer, .panelSubContainer, .panelContainer {
        font-size: 16px;
    }

    .activePollIllustration {
        display: none;
    }

    .enrolledCourseIcon {
        display: none;
    }

    .recentResultTable {
        font-size: 13px!important;
    }
}

@media only screen and (max-width: 1350px) {
    .supportCenterIllustration {
        display: none;
    }
}

.createPollPanel {
    height: 12%;
    min-height: 90px;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    align-items: center;
    justify-content: space-between;
}

.creatBtn {
    display: flex;
    justify-content: center;
    width: var(--dashboard-creat-btn-width);
    align-items: center;
    color: #FFFFFF;
    background: linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
}

.tableBtn {
    width: 24px;
    height: 24px;
    margin: auto;
}

.tableBtn:hover {
    cursor: pointer;
}

.tableMenuBtn {
    background-image: url("./images/menu-icon.svg");
}

.creatBtn:hover {
    background: linear-gradient(84.85deg, #BA54A1 4.78%, #993393 36.23%, #8D278E 43.38%, #7B298F 53.88%, #4D2E90 74.8%, #2E3191 87.39%);
}

.panelTitle {
    display: flex;
    padding: 0 20px;
    align-items: center;
    height: 65px;
    border-radius: 7px 7px 0 0 ;
    border: 1px solid #EFEFEF;
    background: #fcfcfc;
}

.panelSubTitle {
    display: flex;
    flex-direction: column;
}

.panelBody {
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    overflow-y: auto;
}

.supportCenterPanelBody {
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3%;
}

.supportCenterIllustration {
    max-width: 100%;
    max-height: 100%;
    margin-right: 15px;
}

.supportCenter {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    line-height: 18px;
    margin-left: 10px;
}

.activePollIllustration {
    max-width: 100%;
    max-height: 100%;
}

.enrolledCourseIcon {
    max-width: 100%;
    max-height: 100%;
}

.modifiedPollContainer {
    width: 100%;
    flex: 1;
    color: #575757;
    font-size: 15px;
}

.modifiedPollUnit:not(:last-child) {
    margin-bottom: 15px;
}


.panelBody [class*='table-responsive'] {
    height: 100%
}

.tableWrapper {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
}

.recentResultTable thead > tr {
    height: 46px;
}

.recentResultTable tbody > tr {
    height: 46px;
}

.recentResultTable th {
    padding: 0!important;
}

.recentResultTable th:nth-child(1) {
    padding-left: 15px!important;
}

.recentResultTable th:last-child {
    text-align: center;
}

.recentResultTable td {
    padding: 0!important;
}

.recentResultTable td:nth-child(1) {
    padding-left: 15px!important;
}

.recentResultTable td:last-child {
    text-align: center;
}

/*.recentResultTable {*/
/*    table-layout: fixed;*/
/*    border-collapse: separate;*/
/*    box-sizing: border-box;*/
/*    border-spacing: 0;*/
/*    border: 1px solid #d8d8d8;*/
/*    border-top-right-radius: 10px;*/
/*    border-top-left-radius: 10px;*/
/*    font-size: 15px;*/
/*}*/

/*.recentResultTable th {*/
/*    padding-bottom: 0!important;*/
/*    font-weight: 600;*/
/*    color:  #000000;*/
/*    background-color: transparent!important;*/
/*}*/


/*.recentResultTable td {*/
/*    border-top: 1px solid #d8d8d8;*/
/*}*/

/*.recentResultTable th, .recentResultTable td {*/
/*    padding: 0!important;*/
/*    border-bottom: none;*/
/*    border-left: none;*/
/*    border-right: none;*/
/*    vertical-align: middle;*/
/*    height: 40px;*/
/*}*/

/*.recentResultTable th:first-child, .recentResultTable td:first-child {*/
/*    padding: 0 15px!important;*/
/*}*/

 .omitableText {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

[class*='panelSpin'] {
    width: 100%;
    height: 100%;
}

[class*='panelSpin'] [class*='ant-spin-container'] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.gradientText{
    color: black;
}

.gradientText:hover {
    background-image: -webkit-linear-gradient(154.85deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline;
}


.linkText{
    text-decoration: underline;
}

.linkText:hover {
    text-decoration: underline;
}

.contactLine {
    display: flex;
    flex-direction: column;
    line-height: 18px;
    margin-bottom: 10px;
}
