.container {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.unchecked {
    background-color: transparent;
    border: 1px solid #858687;
}


.checked {
    background-color: 
    #0B6F85;
    border: 1px solid 
    #0B6F85;
}

.disabled {
    opacity: .5;
    background-color: #E6E6E6;
    cursor: not-allowed;
    border-color: #E6E6E6;
}


.label {
    flex: 1;
    margin-left: 8px;
    font-style: normal;
    font-weight: inherit;
    line-height: normal;
}

.disabledLabel {
    opacity: .5;
}