.container, .attendancesContainer {
    display: flex;
    padding: 30px 5% 30px 5%;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    align-content: flex-start;
    background-color: #FCFBFF;
    min-height: 100%;
    overflow-y: auto;
    flex-direction: column;
    z-index: 1;
}

/* .attendancesContainer {
    display: flex;
    padding: 30px 5% 30px 5%;
    flex-wrap: wrap;
    align-content: flex-start;
    background-color: #FCFBFF;
    min-height: 100%;
    overflow-y: auto;
    flex-direction: column;
} */

.filterRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 5%; */
    padding-bottom: 30px;
    border-bottom: 0.87px solid #D4D4DE;
    margin-bottom: 30px;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-width: max(450px, 25%);
}

@media only screen and (max-width: 1200px) {
    .filterRow {
        flex-direction: column;
        gap: 10px;
    }

    .filterContainer {
        /* flex-direction: column; */
        justify-content: center;
        min-width: 0 !important;
        width: 100% !important;
    }

    .select {
        /* display: flex; */
        min-width: 0 !important;
        /* max-width: 50% !important; */
        width: 250px !important;
    }

    .attendanceSelectWrapper {
        min-width: 0 !important;
        width: 250px !important;
    }
}

.c2 {
    padding: 30px 5% 30px 5%;
    justify-content: center;
    width: 100%;
}

.titleBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    margin-bottom: 22px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/links/manage_polls_icon.svg");
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;

}

.searchBox {
    font-family: "SanFranciscoPro";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-bottom: 30px;
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
}

.searchBoxForm {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.searchBoxKeywordInput {
    height: 45px;
    width: 40%;
    padding-left: 37px;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxDateInput {
    height: 35px;
    width: 130px;
    margin-right: 15px;
    border: 0.083rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus, .searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxSearchBtn {
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

/*===================table===========================*/
.tableWrapper {
    width: 100%;
    height: fit-content;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
}

.tableWrapper thead tr {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
}


.tableContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
}

.tableWrapper table {
    table-layout: fixed;
}


/*=================responsive====================*/
@media only screen and (min-width: 250px) and (max-width: 950px) {
    .searchBoxKeywordInput {
        margin-left: 5px;
        padding-left: 27px;
        background: url("../../images/zoom.svg") no-repeat left;
        background-position-x: 5px;
        border: 0.066rem solid rgba(208, 208, 208, 1) !important;
    }

    .searchBoxDateInput {
        width: 80px;
        min-width: 50px;
    }

    .searchBoxSearchBtn {
        margin-right: 5px;
        padding: 0.5rem 0.6rem;
        border: 0.125rem solid rgba(0, 107, 155, 1);
        color: rgba(0, 107, 155, 1);
        font-size: 0.625rem;
        line-height: 0.625rem;
    }

    .tableContainer table {
        font-size: 1rem;
    }
}

.createButton{
    border-radius: 0.211rem;
    padding: 1.07rem 2.188rem 1.07rem 2.188rem;
    border: 0.253rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
    height: 15%;
}

.createButton:hover {
    background-color: rgba(2, 119, 173, 1);
    color: rgba(255, 255, 255, 1);
}

.center{
    margin: 5%;
    justify-content: center;
    text-align: center;
}

.text{
    justify-content: center;
    margin: 5%;
    text-align: center;
}

.clickableAnswer {
    cursor: pointer;
    text-decoration: underline;
}

.clickableAnswer:hover {
    color: rgba(0, 107, 155, 1)!important;
}

.attendanceSelectWrapper {
    min-width: min(350px, 15%);
}

.select {
    min-width: max(350px, 20%);
    z-index: 10;
    margin-right: 15px;
}

.courseSelect {
    z-index: 11 !important;
}

.pollTitleTitle {
    display: inline-flex;
    font-size: 20px;
    line-height: 22px;
        font-weight: 700;
    margin: 15px 5px 25px 0px;
}

.pollTitleText {
    display: inline-flex;
    font-size: 20px;
    line-height: 22px;
        font-weight: 400;
    margin: 15px 0px 25px 0px;
}

.viewAnswerBtn {
    display: inline-block;
    border-radius:0.25rem;
    padding: 0.625rem 0.75rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    line-height: 20px;
}

.viewAnswerBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.answer {

}

.attendanceLoadingContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.attendanceResultContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;
    margin-bottom: 25px;
    border-top: 2px solid #42296E;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.attendanceResultText {
        font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.035em;
    text-align: center;
    margin-top: 25px;
}
