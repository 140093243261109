body {
  margin: 0;
  font-family: 'SanFranciscoPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%; 
  display: flex;
  flex-direction: column;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

.globalSpin {
  height: 100%;
}

.globalSpin .ant-spin-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}



::-webkit-scrollbar {
  width:8px;
  height:8px;
}

::-webkit-scrollbar-thumb {
  background-color: #E8E8E8;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #BABABA;
}

::-webkit-scrollbar-thumb:active  {
  background-color: #7C7C7C;
}

/*hide arrow button on number input box*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* table */
table {
  table-layout: fixed;
  margin-top: 0;
  margin-bottom: 0!important;
  margin-left: 0;
  margin-right: 0;
  font-size: 15px;
}


thead > tr {
  height: 62px;
  color: #000000;
  vertical-align: middle;

}


tbody > tr  {
  height: 60px;
  color: #575757;
  font-weight: 400;
  vertical-align: middle;
}

th {
  font-weight: 500!important;
  background-color: #F7F6FB!important;
}

td {
  background-color: #FFFFFF!important;
}

/*@media only screen and (min-width: 1301px) and (max-width: 1700px) {*/
/*  table {*/
/*    font-size: 14px;*/
/*  }*/
/*}*/

@media only screen and (max-width: 900px) {
  table {
    font-size: 13px;
  }
}
