.container {
    display: flex;
    flex-direction: column;
    padding: 30px 5% 30px 5%;
    align-items: center;
    background-color: #FCFBFF;
    gap: 30px;
    flex: 1;
}

.focusIcon:focus {
    outline: auto;
}

.isTabbed {
    outline: auto !important; 
}

.c2 {
    padding: 30px 5% 30px 5%;
    justify-content: center;
    width: 100%;
}

.titleBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/links/active_manage_polls_icon.svg");
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 15px;
}

.card {
    display: flex !important;
    justify-content: center !important;
    flex: 1;
    padding: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 7px;
    box-shadow: 1.7px 1.7px 9.2px 0.8px #00000014;
}

.cardNumber {
    font-size: 30px;
    font-weight: 400;
        line-height: 38px;
    text-align: center;
    color: #42296E;
}

.cardNumberSkeleton {
    display: flex !important;
    margin: auto auto 0 auto !important;
}

.cardNumberSkeletonWrapper {
    display: block;
    line-height: 1;
    height: 38px;
    padding: 4px 0px;
}

.cardCaption {
    font-size: 13px;
    font-weight: 400;
        line-height: 16px;
    text-align: center;
    color: #666666;
}

.searchBox {
    font-family: SanFranciscoPro;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    width: 100% !important;
    height: 60px;
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
    /* padding: 20px; */
    justify-content: center;
}

.searchBoxForm {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 10px;
    width: 50vw;
    align-items: center;
}

.searchFeedbackForm {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 20px;
    width: 90%;
    align-items: center;
    justify-content: center;
}

.searchBoxKeywordInput {
    /* height: 45px; */
    /* width: 260px; */
    padding-left: 37px;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxDateInput {
    height: 35px;
    width: 130px;
    margin-right: 15px;
    border: 0.083rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus, .searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxSearchBtn {
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

/*===================table===========================*/
.tableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    flex: 1;
}

.tableWrapper {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}


.firstCell {
    padding-left: 10px !important;
}

.dateCell {
    padding-left: 10px;
}

.tableCell {

}


.paginationContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}


/*=================responsive====================*/
@media only screen and (min-width: 250px) and (max-width: 950px) {
    .searchBoxKeywordInput {
        margin-left: 5px;
        padding-left: 27px;
        background: url("../../images/zoom.svg") no-repeat left;
        background-position-x: 5px;
        border: 0.066rem solid rgba(208, 208, 208, 1) !important;
    }

    .searchBoxDateInput {
        width: 80px;
        min-width: 50px;
    }

    .searchBoxSearchBtn {
        margin-right: 5px;
        padding: 0.5rem 0.6rem;
        border: 0.125rem solid rgba(0, 107, 155, 1);
        color: rgba(0, 107, 155, 1);
        font-size: 0.625rem;
        line-height: 0.625rem;
    }

}

.createButton{
    border-radius: 0.211rem;
    padding: 1.07rem 2.188rem 1.07rem 2.188rem;
    background-color: rgba(255, 255, 255, 1);
}

.createButton:hover {
    background-color: rgba(2, 119, 173, 1);
    color: rgba(255, 255, 255, 1);
}

.center{
    margin: 5%;
    justify-content: center;
    text-align: center;
}

.text{
    justify-content: center;
    margin: 0 0 5%;
    text-align: center;
    font-size: 15px;
}

.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    height: 62px;

    display: grid;             
    grid-template-columns: 12.6% 28.7% 12.6% 11.5% 13.8% 10.3% 10.3%;
    align-items: center;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
    background-color:  #F7F6FB!important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonRecycleHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    height: 62px;
    display: grid;
    grid-template-columns: 16.67% 37.9% 16.67% 15.15% 13.6%;
    align-items: center;
    border-radius: 10px 10px 0 0;
    background-color: #F7F6FB;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
}

.skeletonRecycleBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 16.67% 37.9% 16.67% 15.15% 13.6%;
    align-items: center;
    border: 1px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
        font-weight: 600;
    vertical-align: middle !important;
    margin: auto auto auto 0 !important;
    justify-content: left;
}

.skeletonActionHeaderCell {
    display: flex !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
    margin: auto auto !important;
}

.skeletonBodyContainer {
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 12.6% 28.7% 12.6% 11.5% 13.8% 10.3% 10.3%;
    align-items: center;
    border: 1px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px;
    padding: 10px 0px;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonActionBodyCell {
    display: flex !important;
    margin: auto auto 0 auto !important;
}

.noData {
    text-align: center !important;
}
