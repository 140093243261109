.description {
        font-size: 14px;
    user-select: none;
    flex: 1;
}

.divideLine {
    height: 1px;
    background-color: #c8c8c7;
}

.tableParentContainer {
    width: 100%;
    flex: 1;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; 
    max-height: 100vh;
    overflow-y: auto;
    background-color: #fcfbff;
}

.tableParentContainer table {
        font-size: 14px;
    table-layout: fixed;
    letter-spacing: 0;
    text-align: center !important;
    vertical-align: middle !important;
    background-color: #fcfbff;
    overflow-y: auto;
    margin-bottom: 0!important;
}

.tableParentContainer table tr {
    height: fit-content!important;
}

.tableParentContainer table th,.tableParentContainer table td  {
    padding: 0.8rem !important;
}

.tableParentContainer .pollTitleLink {
    text-decoration: underline;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    display: inline-block;
}

.controllerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.normalContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.animationContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background-color: #fcfbff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.splashContainer {
    /*display: "flex", flex: 1, width: "100%", alignItems: "center", justifyContent: "center"*/
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
}

.splashTitle {
        font-size: 28px;
    font-weight: 800;
}


.sortColumnLayout {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    height: 100%;
}

.tableContainer table {
        table-layout: fixed;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 25px; */
    letter-spacing: 0;
    text-align: left !important;
    vertical-align: middle !important;
    background-color: white;
    border-spacing: 0 !important;
    border-collapse: separate !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
    /* overflow: hidden !important; */
}

.tableContainer table th {
    /* padding: 20px !important; */
    vertical-align: middle !important;
    height: 48px;
}

.tableContainer tr > th {
    background-color: #F7F6FB!important;
    padding: 0px !important;
}

.tableContainer tr > td {
    padding: 0px !important;
}

.tableContainer tbody>tr {
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
}

.tableContainer table thead, tbody, tfoot {
    border-top: 0 !important;
}
.tableContainer table thead > tr > th:nth-child(1) {
    border-radius: 10px 0px 0px 0px;
}
.tableContainer table thead > tr > th:nth-last-child(1) {
    border-radius: 0px 10px 0px 0px;
} 

.tableContainer table > thead > tr > th{
    font-weight: 600 !important;
}

.tableContainer .tableBtnUnit {
    line-height: 0 !important;
    padding: 0 !important;
}

.tableContainer .tableBtn {
    display: inline-block;
    width: 25px;
    height: 25px;
}

.tableContainer .tableBtn:hover {
    cursor: pointer;
}


.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    display: grid;             
    grid-template-columns: 6.67% 26.67% 16% 13.33% 10.67% 16% 10.67%;
    height: 48px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
    text-align: center;
    background-color:  #F7F6FB!important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
        font-weight: 600;
    vertical-align: middle !important;
    justify-content: center;
    text-align: center;
}

.skeletonActionHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    justify-content: center;
    line-height: 14px;
    /* height: 48px !important; */
    margin: auto auto !important;
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    display: grid;
    grid-template-columns: 6.67% 26.67% 16% 13.33% 10.67% 16% 10.67%;
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
    text-align: center;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px;
    padding: 10px 0px;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonActionBodyCell {
    display: flex !important;
    margin: auto auto 0 auto !important;
}