.container {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: SanFranciscoPro;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.toggleContainer {
    position: relative;
    display: inline-block;
    width: 49px!important;
    height: 28px!important;
}

.actualCheckBox {
    display: none;
    width: 0;
    height: 0;
}

.backgroundWrapper {
    position: absolute;
    width: 100%;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #E6E6E6;
    transition: 0.5s;
    border-radius: 14px;
}

.backgroundWrapper:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
    box-shadow: 1px 0 5px 1px #00000030;
}

.actualCheckBox:checked + .backgroundWrapper {
    background-color: #0B6F85;
}

.actualCheckBox:focus + .backgroundWrapper {
    background-color: #0B6F85;
}

.actualCheckBox:checked + .backgroundWrapper:before {
    transform: translateX(21px);
}

.actualCheckBox:disabled + .backgroundWrapper {
    cursor: not-allowed;
    background-color: #B0CBD1;
}

.label {
    flex: 1;
    font-size: 13px;
    cursor: default;
}