.container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    background-color: #f6f4fa;
}

.title {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    color: #000000;
}

.description {
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.reStartButton {
    padding: 5px 10px !important;
    background-color: #FFFFFF;
    color: #42296e;
    font-weight: 600;
    height: 40px;
    border: 2px solid #42296e;
    border-radius: 3px;
    font-style: normal;
    font-size: 15px;
    margin-top: 10px;
}

.reStartButton:hover {
    background: #42296e;
    color: #FFFFFF;
}

