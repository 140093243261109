.container {
    width: 100%;
    flex: 1;
    overflow-y: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1% 2% 1% 2% !important;
    padding-bottom: 100px!important;
}

.containerBranding {
    width: 100%;
    height: 100% !important;
    overflow-y: auto !important;
    display: flex;
    justify-content: center;
    padding: 1% 2% 1% 2% !important;
}

.brandingTitle {
    color: #000;
        font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.titleBox {
    border-radius: 6.68px 6.68px 0px 0px;
    border: 0.903px solid #EFEFEF;
    background: #F4F5F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/settings_blue.svg");
    margin-right: 10px;
}

.titleBoxTitle {
    color: #000;
        font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}

.container [class*='nav-tabs'] {
    display: flex;
    justify-content: center;
    align-self: center;
    border: none !important;
    border-bottom: 1px solid #ccc !important;
}

.container [class*='nav-link'] {
    font-size: 15px;
    font-weight: normal;
        cursor: pointer;
    color: #000000;
    border: none !important;
    /*width: 200px;*/
}

.container [class*='nav-link']:hover {
    color: #000000;
}

.container [class*='nav-link'][class*='active']:focus-visible {
    border: 2px solid #000000 !important;
}

.container [class*='nav-link'][class*='active'] {
    font-weight: bold;
    border: none !important;
    cursor: auto;
    border-bottom: 3px solid #6750A4 !important;
    background-color: transparent !important;
}


.PlatformSettingContainer {
        font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0.035rem;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 1.7px 1.7px 9.2px 0.8px #00000014;
    margin-top: 5vh;
    margin-left: 0;
    width: 95%;
}

.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background: #FFFFFF;
}

.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 20px;
    text-align: center;
}

.tab button:hover {
    background-color: #ddd;
}

.activebutton {
    border-bottom: 2px solid darkblue !important;
}

.panelTitle {
    display: flex;
    padding: 0 20px;
    align-items: center;
    height: 65px;
    border-radius: 7px 7px 0 0;
    border: 1px solid #EFEFEF;
    background: #fcfcfc;
}

.panelBody {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    border: 1px solid #EFEFEF;
    background: #fcfcfc;
    gap: 10px;
    min-height: calc(100% - 65px);
}

.panelRow {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    /* min-height: 50px; */
}

.panelSubTitle {
    display: flex;
    flex-direction: column;
}

.settingitems {
    padding: 2%;
}

.settingContainer {
    display: grid;
    gap: 20px; 
    grid-template-columns: 50% 50%;
    padding: 20px 0;
}

.dropdownLabel {
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    margin: 0px 15px 10.5px 0px;
    align-self: end;
}

.closedDropdownContainer {
    display: flex;
    white-space: nowrap;
    padding: 5px 10px;
    cursor: pointer;
    align-items: center;
    border-radius: 2px;
    border: 2px solid #ccc;
}

.closedDropdownContainer::after {
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #6C6C6C;
    margin-left: 5px;
}

.openedDropdownContainer {
    display: flex;
    white-space: nowrap;
    padding: 5px 10px;
    cursor: pointer;
    align-items: center;
    border-radius: 2px;
    border: 2px solid #0277ad;
}

.openedDropdownContainer::after {
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #6C6C6C;
    margin-left: 5px;
}

@media only screen and (max-width: 900px) {
    .settingContainer {
        display: grid;
        grid-template-columns: 100%;
    }
    .closedDropdownContainer {
        font-size: 14px;
    }
    .openedDropdownContainer {
        font-size: 14px;
    }

    .closedDropdownContainer::after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #6C6C6C;
    }

    .openedDropdownContainer::after {
        border-left: 4px solid transparent!important;
        border-right: 4px solid transparent!important;;
        border-bottom: 4px solid #6C6C6C!important;;
    }
}

.mainContainer {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FCFBFF;
    padding: 0% 2% 1% 2% !important;
}

.btnCenterAlign {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.body {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%;
    background-color: white;
    overflow-y: auto;
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
}

.bodyBranding {
    margin-top: 25px;
    height: 100%;
    width: 70%;
    background-color: white;
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
}

.titleBoxIconBranding {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/branding_color.svg");
    margin-right: 10px;
}

.preview {
    max-width: 100%;
    max-height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 1350px) {
    .preview {
        max-height: 280px;
    }
}

@media only screen and (max-width: 850px) {
    .preview {
        max-height: 180px;
    }
}

.cardHeader {
    margin-left: 0.5em;
    color: #000;
        font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
}

.cardText {
    color: #000;
        font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
}

.cardRow {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100% !important;
    /* height: 40px !important; */

}

.smallBox {
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
    margin-top: 20px;
    height: fit-content;
    flex: 1;
}

.smallBoxUser {
    border-radius: 6.68px;
    background: #FFF;
    box-shadow: 1.66994px 1.66994px 10px 2px rgba(0, 0, 0, 0.08);
    margin-top: 20px;
    height: fit-content;
    width: 50%;
}

@media screen and (max-width: 1200px) {
    .smallBoxUser {
        width: 100%;
    }
}

.titleText {
    color: #000000;
    font-size: 15px;
    font-weight: 700;
}

.descriptionText {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
}

/*.toastMsgIcon {*/
/*    position: absolute;*/
/*    margin-left: 3px;*/
/*    width: 25px;*/
/*    height: 25px;*/
/*    background: url("../../images/invalidIcon.svg");*/
/*    background-size: 25px 25px;*/
/*}*/

/*.toastMsgIcon:hover {*/
/*    background: url("../../images/invalidIcon_hover.svg");*/
/*}*/
