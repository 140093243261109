:root {
    --yuja-primary-color: #42296E;
    --yuja-primary-disabled-color: #54476B;
    --yuja-primary-disabled-bg-color: #C2BBCF;
    --yuja-secondary-color: #0B6F85;
    --yuja-secondary-disabled-color: #305963;
    --yuja-secondary-disabled-bg-color: #B0CBD1;
    --yuja-delete-color: #BF0033;
    --yuja-delete-disabled-color: #BF0033;
    --yuja-delete-image-color: #B40030;
    --yuja-delete-disabled-image-color: #B40030;
    --yuja-delete-disabled-bg-color: #E8AEBE;
}

.container {
    padding: 8px 10px;
    width: fit-content;
    height: fit-content;
    font-size: max(0.8vw, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconWrapper {

}

@media only screen and (max-width: 900px) {
    .wrapper:not(.iconWrapper) svg {
        display: none;
    }
}


.primary {
    color: var(--yuja-primary-color);
    border: 2px solid var(--yuja-primary-color);
    background: #FFF;
}

.primary path {
    stroke: var(--yuja-primary-color);
}

.primary circle {
    fill: var(--yuja-primary-color);
}

.primary[disabled] {
    background: var(--yuja-primary-disabled-bg-color);
    border: 2px solid var(--yuja-primary-disabled-bg-color);
    color: var(--yuja-primary-disabled-color);
}

.primary[disabled] path {
    stroke: var(--yuja-primary-disabled-color);
}
.primary[disabled] circle {
    fill: var(--yuja-primary-disabled-color);
}

.primary:not([disabled]):focus {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-primary-color);
}

.primary:not([disabled]):focus path{
    stroke: #FFF;
}

.primary:not([disabled]):focus circle{
    fill: #FFF;
}

.primary:not([disabled]):hover {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-primary-color);
}

.primary:not([disabled]):hover path{
    stroke: #FFF;
}

.primary:not([disabled]):hover circle{
    fill: #FFF;
}

.primary[disabled]:hover {
    cursor: not-allowed;
}

.secondary {
    color: var(--yuja-secondary-color);
    border: 2px solid var(--yuja-secondary-color);
    background: #FFF;
}

.secondary path {
    stroke: var(--yuja-secondary-color);
}
.secondary circle {
    fill: var(--yuja-secondary-color);
}

.secondary[disabled] {
    background: var(--yuja-secondary-disabled-bg-color);
    border: 2px solid var(--yuja-secondary-disabled-bg-color);
    color: var(--yuja-secondary-disabled-color);
}

.secondary[disabled] path {
    stroke: var(--yuja-secondary-disabled-color);
}
.secondary[disabled] circle {
    fill: var(--yuja-secondary-disabled-color);
}

.secondary:not([disabled]):focus {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-secondary-color);
}

.secondary:not([disabled]):focus path{
    stroke: #FFF;
}
.secondary:not([disabled]):focus circle{
    fill: #FFF;
}

.secondary:not([disabled]):hover {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-secondary-color);
}

.secondary:not([disabled]):hover path{
    stroke: #FFF;
}
.secondary:not([disabled]):hover circle{
    fill: #FFF;
}

.secondary[disabled]:hover {
    cursor: not-allowed;
}

.delete {
    color: var(--yuja-delete-color);
    border: 2px solid var(--yuja-delete-color);
    background: #FFF;
}

.delete path {
    stroke: var(--yuja-delete-color);
}
.delete circle {
    fill: var(--yuja-delete-color);
}

.delete[disabled] {
    background: var(--yuja-delete-disabled-bg-color);
    border: 2px solid var(--yuja-delete-disabled-bg-color);
    color: var(--yuja-delete-disabled-color);
}

.delete[disabled] path {
    stroke: var(--yuja-delete-disabled-color);
}
.delete[disabled] circle {
    fill: var(--yuja-delete-disabled-color);
}

.delete:not([disabled]):focus {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-delete-color);
}

.delete:not([disabled]):focus path{
    stroke: #FFF;
}
.delete:not([disabled]):focus circle{
    fill: #FFF;
}

.delete:not([disabled]):hover {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-delete-color);
}

.delete:not([disabled]):hover path{
    stroke: #FFF;
}
.delete:not([disabled]):hover circle{
    fill: #FFF;
}

.delete[disabled]:hover {
    cursor: not-allowed;
}

.deleteImage {
    color: var(--yuja-delete-color);
    border: 2px solid var(--yuja-delete-color);
    background: #FFF;
}


.deleteImage[disabled] {
    background: var(--yuja-delete-disabled-image-color);
    border: 2px solid var(--yuja-delete-disabled-image-color);
    color: var(--yuja-delete-disabled-color);
}

.deleteImage:not([disabled]):focus {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-delete-image-color);
}

.deleteImage:not([disabled]):focus path{
    stroke: #FFF;
}
.deleteImage:not([disabled]):focus circle{
    fill: #FFF;
}

.deleteImage:not([disabled]):hover {
    cursor: pointer;
    color: #FFF;
    background-color: var(--yuja-delete-color);
}

.deleteImage:not([disabled]):hover path{
    stroke: #FFF;
}
.deleteImage:not([disabled]):hover circle{
    fill: #FFF;
}

.deleteImage[disabled]:hover {
    cursor: not-allowed;
}