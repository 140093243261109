.parentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    height: 90%;
    max-width: 1100px;
    background: #FFF;
    display: flex;
    flex-direction: column;
}

.titleBox {
    border-radius: 6px 6px 0 0;
    border: 1px solid #EFEFEF;
    background: #F4F5F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.bodyContainer {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 20px;
}


.title {
        font-style: normal;
    font-size: 20px;
    color: #000000;
}

.description {
        font-size: 16px;
}

.stepContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.step {
    display: flex;
    flex-direction: column;
    padding: 19px 41px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
    background: #F4F4F4;
    color: #000;
    font-family: SanFranciscoPro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
    gap: 10px;
}

.stepNo {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: #42296E;
    border-radius: 50%;
    color: #FFF;
    font-family: SanFranciscoPro;
    font-size: 24.832px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.869px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepTitle {
    margin-left: 15px;
    font-weight: 700;
}

.stepLine {
    height: 1px;
    background: #909090;
    margin-left: 15px;
    display: flex;
    flex: auto;
}


