@import "@fontsource/roboto";

.container {
  height: 100%;
  padding: 1% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E6F2F7;
}

.titleContainer {
  margin: 3% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  width: 100%;
    font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #000000;
  text-align: center;
}

.resultBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 3%;
  box-shadow: 5px 9px 11px 0px #00000040;

}

.resultTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #000000;
  margin: 20px 0;
}

.description {
    font-size: 24px;
  font-weight: 400;
  color: #000000;
  text-align: center;
}
