.container {
    display: flex;
    flex-direction: column;
    padding: 30px 5% 30px 5%;
    align-items: center;
    flex: 1;
    background-color: #FCFBFF;
    overflow-y: auto;
}

.c2 {
    padding: 30px 5% 30px 5%;
    justify-content: center;
    width: 100%;
}

.titleBox {
        display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/links/active_manage_courses_icon.svg");
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;

}

.searchBox {
        display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: 30px 0;
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
}

.searchBoxForm {
    display: flex;
    width: 50vw;
    height: 60px;
    justify-content: center;
    align-items: center;
}

.searchBoxKeywordInput {
    /* height: 45px; */
    width: 100%;
    padding-left: 37px;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxDateInput {
    height: 35px;
    width: 130px;
    margin-right: 15px;
    border: 0.083rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus, .searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxSearchBtn {
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

/*===================table===========================*/
.tableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    gap: 20px;
}

.tableWrapper {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}

/*.tableContainer table {*/
/*        table-layout: fixed;*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*    line-height: 25px;*/
/*    letter-spacing: 0;*/
/*    text-align: left !important;*/
/*    vertical-align: middle !important;*/
/*    background-color: white;*/
/*    border-spacing: 0 !important;*/
/*    border-collapse: separate !important;*/
/*    border: 0.031rem solid rgba(208, 208, 208, 1) !important;*/
/*    border-radius: 10px 10px 0 0 !important;*/
/*    !* overflow: hidden !important *!*/
/*}*/

/*.tableContainer table th {*/
/*    !* padding: 20px !important; *!*/
/*    vertical-align: middle !important;*/
/*}*/

/*.tableContainer tr > th {*/
/*    background-color: #F7F6FB!important;*/
/*    padding: 0 !important;*/
/*}*/

/*.tableContainer table thead > tr > th:nth-child(1) {*/
/*    border-radius: 10px 0px 0px 0px;*/
/*}*/
/*.tableContainer table thead > tr > th:nth-last-child(1) {*/
/*    border-radius: 0px 10px 0px 0px;*/
/*} */

/*.tableContainer tr > td {*/
/*    padding: 0 !important;*/
/*}*/

/*.tableContainer thead>tr {*/
/*    !* border: 0.031rem solid rgba(208, 208, 208, 1) !important;*/
/*    border-radius: 10px 10px 0 0 !important;*/
/*    overflow: hidden !important; *!*/
/*}*/

/*.tableContainer tbody>tr {*/
/*    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;*/
/*}*/

/*.tableContainer tr {*/
/*    border: 0.031rem solid rgba(208, 208, 208, 1) !important;*/
/*}*/

/*.tableContainer table thead, tbody, tfoot {*/
/*    border-top: 0 !important;*/
/*}*/

/*.tableContainer table > thead > tr > th{*/
/*    font-weight: 600 !important;*/
/*}*/

/*.tableContainer .tableBtnUnit {*/
/*    line-height: 0 !important;*/
/*    padding: 0 !important;*/
/*}*/

/*.tableContainer .tableBtn {*/
/*    display: inline-block;*/
/*    width: 25px;*/
/*    height: 25px;*/
/*}*/

/*.tableContainer .tableBtn:hover {*/
/*    cursor: pointer;*/
/*}*/

/*.tableContainer .tableEditBtn {*/
/*    margin-right: 10px;*/
/*    background-image: url("../../images/pencil.svg");*/
/*}*/

/*.tableContainer .tableDeleteBtn {*/
/*    background-image: url("../../images/trash.svg");*/
/*}*/

/*.tableContainer .tablePublishBtn {*/
/*    width: 60px;*/
/*    height: 30px;*/
/*    display: inline-block;*/
/*    border-radius: 0.25rem;*/
/*    padding: 0.25rem 0.3rem;*/
/*    border: 0.125rem solid #0277AD;*/
/*    color: #0277AD;*/
/*    line-height: 20px;*/
/*}*/

/*.tableContainer .tablePublishBtn:hover {*/
/*    cursor: pointer;*/
/*    color: rgba(255, 255, 255, 1);*/
/*    background-color: #0277AD;*/
/*}*/

/*!*customize bootstrap pagination*!*/
/*.tableContainer [class*='page-item'][class*='active'] {*/
/*    border: none;*/
/*}*/

/*.tableContainer [class*='page-item'][class*='active'] [class='page-link'] {*/
/*    background-color: rgba(0, 107, 155, 1);*/
/*    color: rgba(255, 255, 255, 1);*/
/*    border-color: rgba(0, 107, 155, 1);*/

/*}*/

/*.tableContainer [class*='page-link'] {*/
/*    color: rgba(0, 107, 155, 1);*/
/*}*/

/*.tableContainer [class*='page-link']:hover {*/
/*    color: rgba(0, 107, 155, 1);*/
/*}*/

.actionCell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nameCell {
    padding-left: 10px !important;
}

.tableCell {

}

.paginationContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

/*=================responsive====================*/
@media only screen and (min-width: 250px) and (max-width: 950px) {
    .searchBoxKeywordInput {
        margin-left: 5px;
        padding-left: 27px;
        background: url("../../images/zoom.svg") no-repeat left;
        background-position-x: 5px;
        border: 0.066rem solid rgba(208, 208, 208, 1) !important;
    }

    .searchBoxDateInput {
        width: 80px;
        min-width: 50px;
    }

    .searchBoxSearchBtn {
        margin-right: 5px;
        padding: 0.5rem 0.6rem;
        border: 0.125rem solid rgba(0, 107, 155, 1);
        color: rgba(0, 107, 155, 1);
        font-size: 0.625rem;
        line-height: 0.625rem;
    }
}

.createButton{
    border-radius: 0.211rem;
    padding: 1.07rem 2.188rem 1.07rem 2.188rem;
    border: 0.253rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
    height: 15%;
}

.createButton:hover {
    background-color: rgba(2, 119, 173, 1);
    color: rgba(255, 255, 255, 1);
}

.center{
    margin: 5%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.text{
    justify-content: center;
    margin: 5%;
    text-align: center;
}

.createCourseButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    margin-left: 35px;
    margin-top: 15px;
    width: 170px;
    height: 50px;

    background: #FFFFFF;
    border: 1.73px solid #0277AD;
    border-radius: 4.68287px;
    /* background: url("../../images/plus\ 1.svg") no-repeat left; */

        font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.035em;

    color: #0277AD;
    white-space: nowrap;
}

.createCourseButton:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderContainer {
    height: 62px;
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    align-items: center;
    padding: 0.031rem;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
    background-color:  #F7F6FB!important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
    vertical-align: middle !important;
    margin: auto auto auto 0 !important;
    justify-content: left;
}

.skeletonActionHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
    margin: auto auto !important;
}

.skeletonBodyContainer {
    /* margin: 0px !important; */
    /* display: grid;
    grid-template-columns: 40% 40% 20%; */
    /* border: 0.031rem solid rgba(208, 208, 208, 1) !important; */
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    align-items: center;
    border: 1px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px !important;
    padding: 10px 0px !important;
}

.skeletonBodyCell {

}

.skeletonActionBodyCell {
    display: flex !important;
    margin: auto auto 0 auto !important;
}

.noData {
    text-align: center;
}
