:root {
    --un-selected-color: #E6E6E6;
    --correct: #A1D2BA;
    --correct-color: #DBE7E1;
    --correct-border-color: #A1D2BA33;
    --incorrect: #E3A7A5;
    --incorrect-color: #F8DCDB;
    --incorrect-border-color: #E3A7A533;
    --non-gradable-color: #E4E3F6;
    --un-graded-color: #D3DDF2;
    --un-answered-color: #E6E6E6;
    --not-posted-color: #F9E4F5;

}

.select {
    /* min-width: min(300px, 20%); */
    z-index: 10;
}

.row {
    margin-bottom: 20px;
    display: block;
}

.left {
    display: inline-block;
    padding-right: 10px;
    text-align: right;
    width: 30%;
    color: #495057;
    font-size: 14px;
        position: relative;
    vertical-align: top;
    padding-top: 4.5px;
}

.right {
    display: inline-block;
    width: 70%;
    color: #000000;
    font-size: 18px;
    }

.radioContainer {
    display: flex;
    gap: 30px;
    align-items: center;
    padding: 15px 0 0 0 !important;
}

.radio {
    margin-right: 10px;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-top: 6px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.buttonContainer {

}

.button {
    display: inline-block;
        margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.button:hover {
    cursor: pointer;
}

.close {
    margin-right: 10px;
    color: #495057;
    border: 0.125rem solid #495057;
}

.close:hover {
    background-color: #495057;
    color: #FFFFFF;
}

.save {
    color: #0277AD;
    border: 0.125rem solid #0277AD;
}

.save:hover {
    color: #FFFFFF;
    background-color: #0277AD;
}

.saveDisabled {
    color: #0277AD;
    border: 0.125rem solid #0277AD;
    opacity: 0.5;
}

.saveDisabled:hover {
    cursor: default !important;
}

.questionImgContainer {
    display: flex;
    max-width: 200px;
    max-height: 150px;
}

.questionImg {
    max-width: min(48vw, 456px) !important;
    max-height: min(27vw, 256.5px) !important;
}

.saImg {
    max-width: 90% !important;
    max-height: min(27vw, 256.5px) !important;
}

.mask {
    position: fixed;
    top: 52px;
    left: var(--collapsed-side-bar-width);
    width: calc(100% - var(--collapsed-side-bar-width));
    height: calc(100% - 52px);
    padding: 20px !important;
    z-index: 999;
    background-color: #FCFBFF;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

@media only screen and (max-width: 900px) {
    .mask {
        left: 0;
        width: 100%;
    }
}

.parentContainer {
    width: 100%;
    flex: 1;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 #00000040;
}

.containerRow {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EAEAEA;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.goBack {
    min-width: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.body {
    flex: 1;
    min-height: 0;
    display: flex;
}

.questionList {
    width: 240px;
    height: 100%;
    padding: 10px;
    overflow-y: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-right: 1px solid #D4D4D4;
}

.questionCard {
    flex-shrink: 0;
    width: 210px;
    height: 75px;
    padding: 0 7px;
    border-radius: 9px;
    border: 2px solid #DADADA;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.questionCard.active {
    border: 2px solid #0B6F85;
}

.questionNoLabel {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.generalBadge {
    width: 85px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--un-answered-color);

    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    color: #344054;
}

.correctColor {
    background-color: var(--correct-color);
}

.inCorrectColor {
    background-color: var(--incorrect-color);
}

.nonGradableColor {
    background-color: var(--non-gradable-color);
}

.ungradedColor {
    background-color: var(--un-graded-color);
}

.unansweredColor {
    background-color: var(--un-answered-color);
}

.notPostedColor {
    background-color: var(--not-posted-color);
}



.questionType {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #6B6B6B;

}

.questionMainContainer {
    flex: 1;
    min-height: 0;
    padding: 15px 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.questionMainContainerRow {
    width: 100%;
    display: flex;
    align-items: center;
}

.regularLabel {
    color: #42296e;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
}


.regularText {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #000;
}

.questionMainContainerFooter {
    border-top: 1px solid #D4D4D4;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
}

.optionBar {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    padding: 10px 20px;

    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.optionBar.selected {
    background-color: var(--un-selected-color);
    border: 1px solid var(--correct-border-color);
}


.optionBar.correct {
    background-color: var(--correct-color);
    border: 1px solid var(--correct-border-color);
}

.optionBar.incorrect {
    background-color: var(--incorrect-color);
    border: 1px solid var(--incorrect-border-color);
}


.indexIconContainer{
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--un-selected-color);
}

.indexIconContainer.selected{
    background-color: #FFF;
}

.indexIconContainer.correct{
    background-color: var(--correct);
}
.indexIconContainer.incorrect{
    background-color: var(--incorrect);
}



