.container {
    display: flex;
    padding: 30px 5% 30px 5%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    min-height: 100%;
    background-color: #FCFBFF;
    overflow-y: auto;
}

.filterRowWrapper {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterRow {
    /* width: fit-content !important; */
    width: 100%;
    /* min-height: 70px; */
    display: flex;
    justify-content: center;
    align-items: center;

    /* border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1); */
}

.filterContainer {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-width: max(450px, 25%) !important;
}

@media only screen and (max-width: 1350px) {
    .filterRow {
        flex-direction: column;
        width: 100% !important;
        gap: 10px;
    }

    .filterContainer {
        width: 100% !important;
        justify-content: center;
        /* justify-content: center; */
    }
    
    .attendanceSelectWrapper {
        min-width: 0 !important;
        width: 250px !important;
    }

    .attendanceSelect {
        min-width: 0 !important;
        width: 250px !important;
    }
}

.searchBox {
        display: flex;
    /* min-height: 50px; */
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    width: 100% !important;
    padding-bottom: 30px;
    border-bottom: 0.87px solid #D4D4DE;
    margin-bottom: 30px;
    /* border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1); */
    justify-content: center;
}

.searchBoxAttendance {
    display: flex;
    min-height: 70px;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    width: 100% !important;
    border-radius: 0.461rem;
    border: 1px solid rgba(240, 240, 240, 1);
    box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 1);
    justify-content: center;
}

.searchBoxForm {
    display: flex;
    gap: 20px;
    width: max(60%, 400px);
    align-items: center;
}

.searchBoxSearchBtn {
    margin: 0 10px;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.emptyTitle {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.emptyDesc {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.syncGradebookContainer, .exportGradebookContainer {
    position: relative;
}

/* .syncGradebookBtn {
    width: 125px !important;
    margin: 0 10px;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
    display: flex;
    justify-content: center;
}

.syncGradebookBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
} */

.syncGradebookOptions, .exportGradebookOptions {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px 0;
    width: 315px;
}

/* .syncGradebookOption {
    margin-left: 10px;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.syncGradebookOptions {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
} */


.attendanceSelectWrapper {
    /* justify-content: stretch; */
    /* width: fit-content !important; */
    min-width: min(350px, 15%);
}

.attendanceSelect {
    min-width: max(350px, 20%);
}

.select {
    min-width: max(350px, 20%);
    z-index: 10;
}

.container [class*='nav-link'] {
    font-size: 1rem;
    color: #495057;
    border: none!important;
}

.container [class*='nav-link'][class*='active'] {
    font-size: 1rem;
    font-weight: 700;
    border-bottom: 3px solid rgba(0, 107, 155, 1)!important;
}

.container [class*='tab-content'] {
    width: 100%;
}

.tableContainer {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
    cursor: default;
}

.tableWrapper {
    width: 100%;
    height: fit-content;
    border: 1px solid #E0E0E0;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
}

/*.tableContainer table {*/
/*        table-layout: fixed;*/
/*    font-size: 15px;*/
/*    font-weight: 400;*/
/*    line-height: 20px;*/
/*    letter-spacing: 0;*/
/*    text-align: left !important;*/
/*    vertical-align: middle !important;*/
/*}*/

/*.tableContainer td{*/
/*    background-color: #FFFFFF!important;*/
/*}*/

/*.tableContainer td {*/
/*    padding-left: 0.5rem !important;*/
/*    padding-right: 0.5rem !important;*/
/*}*/

/*.tableContainer thead th{*/
/*    vertical-align: middle;*/
/*    border-top: none !important;*/
/*    box-shadow: inset 0 0.031rem 0 rgba(208, 208, 208, 1) !important;*/
/*    background-color:  #F7F6FB!important;*/
/*}*/


/*.tableContainer thead tr {*/
/*    border-top: 0px !important;*/
/*    border-right: 0px !important;*/
/*}*/

/*!* .tableContainer tr > th {*/
/*    background-color: transparent !important;*/
/*} *!*/

/*.tableContainer tr {*/
/*    border-top: 0.031rem solid rgba(208, 208, 208, 1) !important;*/
/*    border-right: 0.031rem solid rgba(208, 208, 208, 1) !important;*/
/*    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;*/
/*    border-left: none !important;*/
/*}*/

.tableContainer thead tr {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 199;
}

.tableContainer tr td:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
}

.tableContainer tr th:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
}

/*.gradeBookTableContainerAttendanceWoCheckbox tbody tr td:nth-child(1){*/
/*    border-left: 0.031rem solid rgba(208, 208, 208, 1) !important*/
/*}*/

/*.hoveredRow {*/
/*    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)!important*/
/*}*/



/*.tableContainer thead th:nth-child(1) {*/
/*    border-radius: 10px 0px 0px 0px;*/
/*    box-shadow: inset 0.031rem 0.031rem 0 rgba(208, 208, 208, 1) !important;*/
/*}*/

/*.tableContainer thead th:last-child {*/
/*    border-radius: 0px 10px 0px 0px;*/
/*    box-shadow: inset -0.031rem 0.031rem 0 rgba(208, 208, 208, 1) !important;*/
/*}*/


.tableContainer tr td:last-child{
    position: sticky;
    position: -webkit-sticky;
    right: 0;
}

.tableContainer tr th:last-child{
    position: sticky;
    position: -webkit-sticky;
    right: 0;
    background-color: #FFFFFF;
}

/*.tableContainer [class*='pagination'] {*/
/*    width: 100%;*/
/*    justify-content: center;*/
/*}*/

.gradeBookSurvey {
    display: inline-block;
    width: min(80%, 100px);
    font-size: 16px;
    border-radius: 5px !important;
    background-color: #FFFFFF;
    text-align: center;
    color: #000000;
    border: 1px solid #000000;
}

.gradeBookSurveyPointer {
    display: inline-block;
    width: 100px;
    font-size: 16px;
    border-radius: 5px !important;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
}

.gradeBookSurveyPointer:hover {
    cursor: pointer;
}

.gradeBookView{
color: #42296E;
}

.gradeBookView, .gradeBookViewDisabled {
    display: inline-block;
    width: 100px;
    font-size: 16px;
    text-decoration: underline;
}

.gradeBookView:hover {
    cursor: pointer;
    color: rgba(0, 107, 155, 1)!important;
}

.gradeBookCorrect {
    display:inline-block;
    width: min(80%, 100px);
    font-size: 14px;
    border-radius: 16px !important;
    color:  #344054;
    background-color: #6DA18640;
    padding: 2px 10px;
    text-align: center !important;
}

/* .gradeBookCorrectDropdown {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px 5px 0px 0px !important;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    opacity: 0.5;
} */

.gradeBookIncorrect {
    display:inline-block;
    width: min(80%, 100px);
    font-size: 14px;
    border-radius: 16px !important;
    text-align: center !important;
    color: #344054;
    background-color: #E2726E40;
    padding: 2px 10px;
}

.gradeBookUngraded {
    display:inline-block;
    width: 88px;
    font-size: 14px;
    border-radius: 16px !important;
    text-align: center !important;
    color: #344054;
    background-color: #E6E6E6;
    padding: 2px 10px;
}

/* .gradeBookIncorrectDropdown {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px 5px 0px 0px !important;
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    opacity: 0.5;
} */

/* .gradebookCorrectDropdownOption {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 0px 0px 5px 5px !important;
    background-color: #D3FFDC;
    color: #0A820E;
    border: 1px solid #0A820E;
    margin-top: 1px;
}

.gradebookIncorrectDropdownOption {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 0px 0px 5px 5px !important;
    background-color: #FFF3EB;
    color: #C93E3E;
    border: 1px solid #C93E3E;
    margin-top: 1px;
} */

.gradeBookNotGraded {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px !important;
    background-color: #FFFFFF;
    text-align: center;
    color: #000000;
    border: 1px solid #42296E;
}

.gradeBookNotGraded:hover {
    cursor: pointer;
    background-color: #E5E5E5;
}

.gradeBookNotGradedDisabled {
    display:inline-block;
    width: 75px;
    font-size: 13px;
    border-radius: 5px !important;
    background-color: #DADADA;
    color: #000000;
    border: 1px solid #000000;
}

.gradeBookAttendanceStatus {
    height: 50px;
    padding: 0 !important;
}

.questionButton {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0;
    cursor: pointer;
}

.titleBox {
    border-radius: 6.68px 6.68px 0px 0px;
    border: 0.903px solid #EFEFEF;
    background: #F4F5F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.titleBoxIcon {
    width: 26px;
    height: 27px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/GradebookColor.svg");
    margin-right: 10px;
}

.titleBoxTitle {
    color: #000;
    font-family: SanFranciscoPro;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}

.customScoreBox {
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box;
    border-radius: 3px !important;
    text-align: left;
}

.customScoreInput {
    width: 75px !important;
    height: 25px !important;
        font-style: normal;
    font-size: 13px;
    line-height: 13px;
    text-align: left;
    /* margin-top: 15px !important; */
    background: transparent !important;
}

.customScoreInput:focus {
    border: none !important;
}

.pollTitleTitle {
    font-size: 20px;
    line-height: 22px;
        font-weight: 700;
    margin: 15px 5px 45px 0px;
}

.pollTitleText {
    font-size: 20px;
    line-height: 22px;
        font-weight: 400;
    margin: 15px 0px 45px 0px;
}

.surveyMCSSOption {
    height: 50px;
        font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
    margin: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    word-break: break-word;
}

/* .checkBox {
    z-index: 9;
} */

.expandCollapse {
    width: 15px;
    height: 15px;
    margin-right: 15px;
}

.subRow {
    /* background-color: #FF0000 !important; */
    font-size: 13px;
    line-height: 13px;
    text-align: end !important;
}

.mainRow {
    /* background-color: #00FF00 !important; */
    /*font-size: 15px;*/
    /*line-height: 15px;*/
}

.gradebookTableRow {
    cursor: default !important;
    /* pointer-events: none !important; */
}

/* .gradebookTableRowExpandable {
    
} */

.mainContainer {
    position: relative;
    width: 100%;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FCFBFF;
    padding: 20px !important;
    z-index: 1;
    gap: 10px;
}

.mainAttendanceContainer {
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% !important;
}

.searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background-color: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 #00000040;
}

.searchContainerTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.searchContainerDesc {
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #6B6B6B;
}

.attendanceSearchRow {
    display: grid;
    align-items: center;
    row-gap: 15px;
    column-gap: 10px;
    grid-template-columns: auto minmax(0, 1fr);

}

.searchRow {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 10px;
    align-items: center;
}

.searchLabel {
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.attendanceBodyContainer {
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.attendanceStatusContainer {
    display: flex;
    align-items: start;
    flex-direction: column;
}

.body {
    width: 100%;
    min-height: 0;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background: #FFF;
    border: 1px solid #EFEFEF;
    box-shadow: 0 0 4px 0 #00000040;
    gap: 10px;
    padding: 15px;
}

.attendanceContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.08);
}

.attendanceBody {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    border-radius: 6px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 30px;
}

.mainDiv {
    padding: 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
}

@media only screen and (min-width: 1460px) {
    .syncGradebookOptions {
        width: 315px;
    }
}


.Filtercontainer {
    position: relative;
    font-family: SanFranciscoPro;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    background-color: #FFFFFF;
    cursor: default;
}

.FilterMenuContainer {
    position: absolute;
    min-width: 110px;
    max-width: 183px;
    top: calc(100% + 5px);
    z-index: 10000;
}


.menuList {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: 0 0 0 1px #B9B9B9;
    border-radius: 7px;
    box-shadow: 0px 0.5px 5px 0px #0000001F;
    overflow: auto;
    padding: 7px 7px 4px 0px; 
}

.FilterMenuContainer:after{
    content: '';
    border-top: 1px solid #D0D0D0;
    border-left: 1px solid #D0D0D0;
    background-color:white;
    position: absolute;
    left: 18px;
    top: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    z-index: 1000;
}

.menuItem {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 10px;
    background-color: #FFFFFF;
    z-index: 10000;

}

.menuItemText {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menuItem:last-child {
    border-width: 0;
}

.gradeModalFooterContainer {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 0.75rem !important;
    background-color: #FFFFFF;
    border-top: 1px solid #D0D0D0;
}

.gradeModalFooterText {
    /* display: flex; */
    /* text-align: left; */
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    color: #000000;
}

.exportDropdown {
    margin: 0 10px;
    min-width: 225px;
}

.syncDropdown {
    margin: 0 10px;
    min-width: 200px;
}

.detailDropdown {
    border-radius: 4.2px;
    border: 2px solid #42296E;
    display: flex;
    gap: 8.122px;
    padding: 5.723px 9.656px;
    align-items: center;
}

.detailDropdown *{
    stroke: #42296E !important;
}

.detailDropdownSelected {
    border-radius: 4.2px;
    border: 2px solid #42296E;
    display: flex;
    gap: 8.122px;
    padding: 7.723px 9.656px;
    align-items: center;
}

.detailDropdownSelected *{
    stroke: #FFF!important;
}

.subrow {
    animation: expandAnimation 0.5s ease-in-out;
}

.subrow:hover {
    box-shadow: none !important;
}

@keyframes expandAnimation {
    from {
        height: 0;
        transform: scaleY(0);
        transform-origin: top;
      }
      to {
        height: 100%;
        transform: scaleY(1);
        transform-origin: top;
      }
  }
  
  @keyframes collapseAnimation {
    from {
      height: 100%;
      transform: scaleY(1);
      transform-origin: top;
    }
    to {
      height: 0;
      transform: scaleY(0);
      transform-origin: top;
    }
  }
  
  .subrowClosing {
    animation: collapseAnimation 0.5s ease-in-out;
  }

  .attemptNoContainer {
      width: 34px;
      height: 22px;
      border-radius: 10px ;
      border: 1px solid #D4D4D5;
      color: #344054;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      vertical-align: middle;
      flex-shrink: 0;
      cursor: default;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }



@media only screen and (min-width:  901px) {
    .gradeBookTableContainerWCheckbox tr td:nth-child(2){
        position: sticky;
        position: -webkit-sticky;
        left: 230px;
    }

    .gradeBookTableContainerWCheckbox tr th:nth-child(2){
        position: sticky;
        position: -webkit-sticky;
        left: 230px;
        background-color: #FFFFFF;
    }


    .gradeBookTableContainerWCheckbox tr td:nth-child(3){
        position: sticky;
        position: -webkit-sticky;
        left: 360px;
    }

    .gradeBookTableContainerWCheckbox tr th:nth-child(3){
        position: sticky;
        position: -webkit-sticky;
        left: 360px;
        background-color: #FFFFFF;
    }

    .attendanceSearchRow {
        grid-template-columns: auto 230px auto minmax(0, 1fr) auto auto;
    }
}


.generalBadge {
    width: 110px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--un-answered-color);
    gap: 5px;

    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    color: #344054;
}







.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    height: 62px;

    display: grid;
    grid-template-columns: 12.6% 28.7% 12.6% 11.5% 13.8% 10.3% 10.3%;
    align-items: center;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
    background-color:  #F7F6FB!important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonRecycleHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    height: 62px;
    display: grid;
    grid-template-columns: 16.67% 37.9% 16.67% 15.15% 13.6%;
    align-items: center;
    border-radius: 10px 10px 0 0;
    background-color: #F7F6FB;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
}

.skeletonRecycleBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 16.67% 37.9% 16.67% 15.15% 13.6%;
    align-items: center;
    border: 1px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    vertical-align: middle !important;
    margin: auto auto auto 0 !important;
    justify-content: left;
}

.skeletonActionHeaderCell {
    display: flex !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
    margin: auto auto !important;
}

.skeletonBodyContainer {
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    height: 60px;
    display: grid;
    grid-template-columns: 12.6% 28.7% 12.6% 11.5% 13.8% 10.3% 10.3%;
    align-items: center;
    border: 1px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px;
    padding: 10px 0px;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonActionBodyCell {
    display: flex !important;
    margin: auto auto 0 auto !important;
}


.actionTd {
    padding: 0!important;
}
