.centerLayout {
    width: 100%;
    height: 100% !important;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    align-self: center;
    background-color: #FDFDFD;
}

.menubarBtnLayout {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (min-width: 250px) and (max-width: 1100px) {
    .menubarCourseTitle {
        width: 100% !important;
    }
    .menubarBtnBar {
        width: 100% !important;
        margin-bottom: 1rem;
    }
}

/*too narrow that can not put btns into one row*/
@media only screen and (max-width: 530px) {
    .menubarBtnLayout {
        display: flex;
        flex-direction: column;
        align-items: self-end;
    }
}

@media only screen and (max-height: 1000px) {
    .blankContent {
        /* margin-bottom: 3rem !important; */
    }
}

.menuBarWrapper {
    width: 100%;
}

.bodyWrapper {
    width: 90%;
    margin: 0px auto;
    /* padding: 0px auto; */
}

.menubar {
    width: calc(100% - 75px);
    margin: 40px 37.5px 30px 37.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 0rem;

    background: #FFFFFF;
    border: 0.548033px solid #E6E6E6;
    box-shadow: 0px 2.19213px 16.441px rgba(0, 0, 0, 0.08);
    border-radius: 2.74016px;
}

.menubarText {
        font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    text-align: center;
    letter-spacing: 0.035em;
    color: #000000;
}

.subMenubar {
    display: flex;
    /* width: min(500px, 80vw) !important; */
    height: 50px;
    justify-content: center;
    margin: 1rem auto 0.5rem auto;
}

.subMenuOption {
    display: flex;
    width: min(250px, 40vw) !important;
    justify-content: center;
        font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.25rem;
    align-items: center;
    letter-spacing: 0.035em;
    color: #000000;
    border-bottom: 1.5px solid #717171;
}

.activeSubMenuOption {
    display: flex;
    width: min(250px, 40vw) !important;
    justify-content: center;
        font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.25rem;
    align-items: center;
    letter-spacing: 0.035em;
    color: #495057;
    border-bottom: 3px solid #6750A4;
}

.menubarBtnBar {
    width: 50%;
}

.menubarBtn {
    margin: 0.2rem 0.978rem 0.2rem 0;
}

.blankContentLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.blankContent {
    width: 100%;
    /* height: 100%; */
    padding: calc(5vw) 0 calc(6vw) 0;
}

.blankContentIllustration {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: rgba(0,0,0,0);
    height: 175px;
    padding: 0;
    width: 335px;
    border-bottom: 0;
    background-image: url('../../images/NoActivityFound.svg');
}

.blankContentBlankTitle {
    width: 100%;
    margin: 2.5rem 0 0.625rem 0;
}

.blankContentDescription {
    /* width: 100%; */
    margin: 0 0 2rem 0;
}

.memberTableContainer {
    width: 100%;
    /* padding: 10px 20px; */
}

.container {
    display: flex;
    /* padding: 30px 5% 30px 5%; */
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    background-color: #FCFBFF;
}

.titleBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    margin-bottom: 22px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1);
}

.titleBoxTitle {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;

}

.searchBox {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    width: 100%;
    height: 60px;
    margin: 20px 0px;
    /* padding: 0px 30px; */
    border-radius: 0.461rem;
    border: 0.063rem solid rgba(240, 240, 240, 1);
    /* box-shadow: 0.264rem 0.063rem 0.688rem 0 rgba(0, 0, 0, 0.08); */
    background-color: rgba(255, 255, 255, 1);
    justify-content: center;
}

.searchBoxForm {
    display: flex;
    width: 50vw;
    justify-content: center;
    align-items: center;
}

.searchBoxKeywordInput {
    /* height: 45px; */
    /* width: 100% !important; */
    padding-left: 33px !important;
    background: url("./images/zoom.svg") no-repeat left;
    background-position-x: 10px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
}

.searchBoxKeywordInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem #0277AD;
}

.searchBoxKeywordInput:focus-within {
    box-shadow: 0 0 0 2px #42296E!important;
}

.searchBtn {
    width: 70px !important;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid #0277AD;
    color: #0277AD;
    margin: 0px 30px;
        font-weight: 400;
    font-size: 14px;
    /* line-height: 1rem; */
    text-align: center;
}

.searchBtn:hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #0277AD;
}

.addMembersBtn {
    min-width: 185px !important;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid #0277AD;
    color: #0277AD;
        font-weight: 400;
    font-size: 14px;
    /* line-height: 1rem; */
    text-align: center;
}

.addMembersBtn:hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #0277AD;
}
/*===================table===========================*/
.tableContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
}

.tableContainer table {
        table-layout: fixed;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 20px; */
    letter-spacing: 0;
    text-align: left !important;
    vertical-align: middle !important;
    border-spacing: 0 !important;
    border-collapse: separate !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.tableContainer table th {
    /* padding: 20px !important; */
    vertical-align: middle !important;
}

.tableContainer tr > th {
    background-color: transparent!important;
    padding: 0px !important;
}

.tableContainer tr > td {
    background-color: transparent!important;
    padding: 0px !important;
}

.tableContainer thead>tr {
    /* border: 0.031rem solid rgba(208, 208, 208, 1) !important; */
}

.tableContainer tbody>tr {
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
}

.tableContainer table thead, tbody, tfoot {
    border-top: 0 !important;
}

.tableContainer table > thead > tr > th{
    font-weight: 600 !important;
}

.tableContainer .tableBtnUnit {
    line-height: 0 !important;
    padding: 0 !important;
}

.tableContainer .tableBtn {
    display: inline-block;
    width: 33px;
    height: 32px;
}

.tableContainer .tableBtn:hover {
    cursor: pointer;
}

.tableContainer .tablePublishBtn {
    display: inline-block;
    border-radius:0.25rem;
    padding: 0.625rem 0.75rem;
    border: 0.125rem solid rgba(10, 130, 14, 1);
    color: rgba(10, 130, 14, 1);
    line-height: 20px;
}

.tableContainer .tablePublishBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(10, 130, 14, 1);
}

/*customize bootstrap pagination*/
.tableContainer [class*='page-item'][class*='active'] {
    border: none;
}

.tableHeader {
    display: flex;
    /* justify-content: center; */
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* line-height: 20px; */
    letter-spacing: 0.035em;
    /* min-height: 4rem; */
}

.tableBody {
    /* width: 100% !important; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
        font-style: normal;
    font-weight: 400;
    /* font-size: 13px; */
    /* line-height: 20px; */
    letter-spacing: 0.035em;
    /* min-height: 3rem; */
    /* overflow-x: hidden; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
}

.userNameCell {
    padding-left: 10px !important;
}

.tableCell {

}

/*===================table===========================*/

.c2 {
    padding: 30px 5% 30px 5%;
    justify-content: center;
    width: 100%;
}

.center{
    margin: 5%;
    justify-content: center;
    text-align: center;
}

.text{
    justify-content: center;
    margin: 5%;
    text-align: center;
}

.createButton{
    border-radius: 0.211rem;
    padding: 1.07rem 2.188rem 1.07rem 2.188rem;
    border: 0.253rem solid rgba(2, 119, 173, 1);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(2, 119, 173, 1);
    height: 15%;
}

.createButton:hover {
    background-color: rgba(2, 119, 173, 1);
    color: rgba(255, 255, 255, 1);
}

.paginationWrapper {
    margin: 20px 0px 10px !important;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: centerz;
    width: 90%;
    margin: 10px auto 0 !important;
}

.card {
    display: flex !important;
    justify-content: center !important;
    width: 24%;
    margin: 0 0.5%;
    padding: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 7px;
    box-shadow: 1.7px 1.7px 9.2px 0.8px #00000014;
}

.cardNumber {
    font-size: 30px;
    font-weight: 400;
        line-height: 38px;
    text-align: center;
    color: #42296E;
}

.cardNumberSkeleton {
    display: flex !important;
    margin: auto auto 0 auto !important;
}

.cardNumberSkeletonWrapper {
    display: block;
    line-height: 1;
    height: 38px;
    padding: 4px 0px;
}

.cardCaption {
    font-size: 13px;
    font-weight: 400;
        line-height: 16px;
    text-align: center;
    color: #666666;
}

.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.skeletonHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    display: grid;
    grid-template-columns: 17.14% 17.14% 20% 28.57% 17.14%;
    height: 48px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
        font-weight: 600;
    vertical-align: middle !important;
    margin: auto auto auto 0 !important;
}

.skeletonActionHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
    margin: auto auto !important;
}

.skeletonBodyContainer {
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    display: grid;
    grid-template-columns: 17.14% 17.14% 20% 28.57% 17.14%;
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 40px;
    padding: 10px 0px;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonActionBodyCell {
    display: flex !important;
    margin: auto auto 0 auto !important;
}

.noData {
    text-align: center !important;
}
