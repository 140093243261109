@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Abril+Fatface|Lato");
@import url("https://fonts.googleapis.com/css2?family=Enriqueta&family=Lato:wght@400;700&family=Open+Sans:wght@700;800&family=Source+Sans+Pro:wght@600&display=swap");

#add-question-modal {
  position: absolute;
  width: 10rem;
  height: 10rem;
  left: 10%;
  bottom: 44%;
}

.publish-modal-subtext-row {
  margin-top: 0.875rem !important;
  margin-left: 15% !important;
  margin-right: 15% !important;
  margin-bottom: 1.813rem !important;
}

@media only screen and (max-width: 1000px) {
  .publish-modal-subtext-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.col-3-5 {
  flex: 0 0 auto !important;
  width: 26.333333% !important;
}

.question-type-box-text {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #0a0a0a;
  height: 5rem !important;
  margin-top: 5.5rem !important;
}

.question-type-icon {
  margin-top: 2.313rem !important;
  margin-bottom: 5.5rem !important;
}

@media only screen and (max-width: 1200px) {
  .question-type-icon {
    margin: 0px !important;
  }

  .question-type-box-text {
    margin-top: 0px !important;
    font-size: 1rem !important;
  }
}

.publish-modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
}

.modal-link {
  color: #0277ad;
  font-weight: 600;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.modal-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0277ad;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.modal-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.join-link-text {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #000000;
}

@media only screen and (max-width: 950px) {
  .join-link-text {
    font-size: 20px;
  }
  .publish-modal-subtitle{
    font-size: 15px !important;
  }
  .poll-code-text{
    font-size: 20px !important;
  }
  .all-poll-navigator{
    font-size: 15px !important;
  }
}

.publish-modal-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;

  color: #464646;
}

.text-container-with-counter {
  background: #FFFFFF;
  border: 0.5px solid #909090 !important;
  box-sizing: border-box;
  border-radius: 3px !important;
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.text-container-with-counter-main {
  background: #FFFFFF;
  border: 0.5px solid #909090 !important;
  box-sizing: border-box;
  border-radius: 3px !important;
  padding: 10px;
  text-align: center;
  margin: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.text-container-without-counter {
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px !important;
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.save-pub-buttons {
  
}

.poll-desc {
  background: #FFFFFF;
  border: 0.5px solid #909090 !important;
  box-sizing: border-box;
  border-radius: 3px !important;
  text-align: center;
  padding: 0px 0px 0px 0px !important;
  margin: 1% !important;
  width: 98% !important;
  justify-content: center;
}

.poll-header-text {
    font-style: normal;
  font-weight: 700;
  font-size: 35.4667px;
  line-height: 35px;
  color: #434343;
  text-align: center;
  background-color: #FFFFFF;
  margin-left: 9px;
}

.no-margin-right {
  margin-right: 0px !important;
}

.quiz-title-col {
  flex: 0 0 auto !important;
  width: 100% !important;
}

.quiz-title-input {
  width: 100%;
}

.quiz-desc{
  width: 50% !important;
}


@media only screen and (max-width: 1470px) {
  .quiz-title-col {
    width: 100% !important;
  }

  .quiz-title-input {
    width: 100%;
  }
}

.poll-sub-text {
    font-style: normal;
  font-weight: 400;
  font-size: max(1.25vw, 15px);
  line-height: max(1.5vw, 20px);
  text-align: left;
  background-color: #FFFFFF;
  color: #6c6c6c;
  margin-left: 9px;
  box-shadow: none;
}

.poll-sub-text-builder {
    font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  background-color: #FFFFFF;
  color: #6c6c6c;
  margin-left: 9px;
  box-shadow: none;
}

.poll-sub-text:focus, .poll-sub-text-builder:focus {
  box-shadow: 0 0 0.3rem 0.006rem rgb(0 107 155);
}

.fitb-input {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: left !important;
  color: #6c6c6c !important;
}

#border-before-questions {
  border: 1px solid #686868;
}

.focus-outline-none:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.autosize-text-area::placeholder {
  color: #9197a3 !important;
}

.question-type-box {
  background: rgba(2, 119, 173, 0.05);
  border: 2px solid #080341;
  box-sizing: border-box;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.25rem;
  margin-right: 1.4065rem;
  margin-left: 1.4065rem;
  margin-top: 2.938rem !important;
}

.question-type-box:hover {
  background: linear-gradient(
    171.85deg,
    #0277ad 81.09%,
    rgba(2, 119, 173, 0) 153%
  );
  outline: 4px solid #005780;
  outline-offset: -6px;
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  cursor: pointer;
}

.question-type {
  margin-bottom: 5px;
  width: 100%;
  border-radius: 0.25rem !important;
  height: 3rem;
  text-align: left !important;
  background-color: #fff !important;
  border: none;
}

.question-type:hover {
  background-color: rgb(242, 242, 242) !important;
}

.top {
  margin-top: 30px;
}

/*text styling*/
.quiz-pin {
  font-family: "SanFranciscoPro";
  line-height: 1.25;

  font-size: 40px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.quiz-pin-text {
  font-family: "SanFranciscoPro";
  font-size: 2em;
  text-transform: uppercase;
  text-align: center;
  color: black;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: 0px;
}

.pin-section {
  width: 60%;
  float: left;
  height: 150px;
}

.qr-section {
  width: 40%;
  float: left;
}

.modal-container {
  margin: auto;
  background-color: #fff;
  border-radius: 6px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.25);
}

.modal-container-header {
  padding: 16px 32px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-container-title {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  font-weight: 700;
  font-size: 1.125em;
}
.modal-container-title svg {
  width: 32px;
  height: 32px;
  color: #750550;
}

@media only screen and (min-width: 950px) {
  .modal-container-body {
    width: 486px;
    padding: 64px 32px 51px;
    overflow-y: auto;
  }
}

.rtf h1,
.rtf h2,
.rtf h3,
.rtf h4,
.rtf h5,
.rtf h6 {
  font-weight: 700;
}
.rtf h1 {
  font-size: 3.5rem;
  line-height: 1.125;
}
.rtf h2 {
  font-size: 1.25rem;
  line-height: 1.25;
}
.rtf h3 {
  font-size: 1rem;
  line-height: 1.5;
}
.rtf > * + * {
  margin-top: 1em;
}
.rtf self > * + :is(h1, h2, h3) {
  margin-top: 2em;
}
.rtf self > :is(h1, h2, h3) + * {
  margin-top: 0.75em;
}
.rtf ul,
.rtf ol {
  margin-left: 20px;
  list-style-position: inside;
}
.rtf ol {
  list-style: numeric;
}
.rtf ul {
  list-style: disc;
}
.modal-container-footer {
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  gap: 12px;
  position: relative;
}
.modal-container-footer:after {
  content: "";
  display: block;
  position: absolute;
  top: -51px;
  left: 24px;
  right: 24px;
  height: 50px;
  flex-shrink: 0;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.75),
    transparent
  );
  pointer-events: none;
}
.button {
  padding: 7px 11px;
  border-radius: 8px;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  cursor: pointer;
  transition: 0.15s ease;
}

.button.is-ghost {
  color: black !important;
}
.button.is-ghost:hover,
.button.is-ghost:focus {
  background-color: #dfdad7;
}
.button.is-primary {
  background-color: #750550;
  color: #fff;
}
.button.is-primary:hover,
.button.is-primary:focus {
  background-color: #4a0433;
}
.icon-button {
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.15s ease;
}
.icon-button svg {
  width: 24px;
  height: 24px;
}
.icon-button:hover,
.icon-button:focus {
  background-color: #dfdad7;
}

.createButton {
  padding-left: 10px;
}

#sidebar-title {
  padding: 2vh;
  padding-left: 1.5vh;
  padding-top: 6vh !important;
  font-family: 'SanFranciscoPro';
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 1.35vw;
  color: #000000;
  line-height: 17px;
  text-align: left;
}

#poll-type-title {
  padding: 2vh;
  padding-left: 1.5vh;
  font-family: 'SanFranciscoPro';
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 1.35vw;
  color: #000000;
  line-height: 17px;
  text-align: left;
}

.align-me {
  display: flex;
  align-items: center;
}

.sidebar-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 3.625rem;
  background: #ffffff;
  border-left: 0.5px solid #dfe1e1;
  border-bottom: 0.5px solid #dfe1e1;
  box-sizing: border-box;
}

.question-bar-container {
  display: flex;
  align-items: center;
  cursor: pointer!important;
  border: none!important;
  padding: 5px 15px;
  padding-left: 2px;
}
.question-bar-container:hover {
}

.is-cur {
  border-left: 2px solid #6750A4 !important;
  border-radius: 10px !important;
  background: linear-gradient(90deg, #F7EFF6 0.08%, rgba(240, 234, 249, 0) 100.08%, #F7EFF6 100.08%);

}

.first-question-border-top {
  border-top: 0.5px solid #dfe1e1;
}

.max-height {
  height: 100% !important;
}

.margin-auto {
  margin: auto !important;
}

.margin-vertical-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.scan-qr-text {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  color: #000000;
}

.all-poll-navigator {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  text-align: center;

  color: #464646;
}

.poll-code-text {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  color: #000000;
}

#create-poll-text {
  font-style: normal;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  width: 100%;
}

#create-poll-subtext {
  font-style: normal;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
}

#empty-sidebar-message {
  font-style: normal;
  color: #6c6c6c;
  text-align: center;
  font-size: 1.042rem;
  margin: auto;
  font-weight: 400;
}

.sidebar-question {
  margin: 2px 0px;
  color: #000000;
  text-align: left;
    font-style: normal;
  font-weight: 400;
  font-size: 15px;
  word-break: break-word;
}

@media only screen and (max-width: 1815px) { 
  .sidebar-question {
    max-width: 150px !important;
  }
}


@media only screen and (max-width: 850px) {
  .sidebar-question {
    word-break: keep-all;
    font-size: 2vw;
  }
  .no-padding.max-height {
    padding : 0;
  }
}

.sidebar-question-type {
  color: #535353;
  margin: 2px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

#create-first-question-container {
  margin-top: 4.562rem;
}

.mobile-menu {
  padding-top: 2vh;
}

.mobile-menu-modified {
  padding-top: 2vh;
}

.detailSidebarWrapper {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.detailSidebarTitle {
  display: inline-flex;
    font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 17px;
  color: #000000;
  padding-left: 12px;
}

.dropIcon {
  display: flex;
  margin-left: auto;
  cursor: "pointer";
}

.questionDetailDesc {
    font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #535353;
}

.pollDetailTitle {
  padding-top: 2vh;
  font-family: 'SanFranciscoPro';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.questionDetailTitle {
  padding-top: 2vh;
  font-family: 'SanFranciscoPro';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 0;
}

@media only screen and (max-width: 950px) {
  .mobile-menu {
    padding-top: 9vh;
  }

  .mobile-menu-modified {
    padding-top: 0;
  }

  .modal-container {
    width: 90%;
  }

  .modal-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 300px) {
  #empty-sidebar-message {
    font-size: 0.7rem;
    padding: 0px;
  }

  #sidebar-title {
    font-size: 0.7rem;
    margin-top: 0.625rem;
    padding: 0px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  #empty-sidebar-message {
    font-size: 0.8rem;
  }

  #sidebar-title {
    font-size: 0.8rem;
    margin-top: 0.625rem;
  }
}

@media only screen and (max-width: 600px) {
  .openToggle {
    margin-left: 80%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 950px) {
  .openToggle {
    margin-left: 40%;
  }
}

@media only screen and (min-width: 950px) and (max-width: 1670px) {
  .openToggle {
    margin-left: 25%;
  }
}

.question-detail-panel {
  /* max-height: 450px; */
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #858687;
  background: #FFFFFF;
  border-radius: 8px;
  /* animation-name: panelAnimation;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
}

.animated-drop-down {
  max-height: 55px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin: 10px 0px 10px;
}

.animated-drop-down.open {
  max-height: 300px;
}

.question-detail-collapsed-panel {
  /* max-height: 150px; */
  display: flex;
  padding:  8px 14.8px 12px 6.4px;
  height: 45px;
  border: 1px solid #858687;
  background: #FFFFFF;
  border-radius: 8px;
  /* animation-name: panelAnimation2;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); */
}

/* @keyframes panelAnimation {
  from {max-height: 150px;}
  to {max-height: 450px;}
}

@keyframes panelAnimation2 {
  from {max-height: 450px;}
  to {max-height: 150px;}
} */

.question-detail-collapsed-panel-icon-wrapper {
  display: flex;
    width: 70.2px;
    height: 30.6px;
    padding: 5.063px;
    justify-content: center;
    align-items: center;
  border-radius: 3.6px;
  border: 0.852px solid #42296E;
  background: linear-gradient(239deg, rgba(242, 140, 185, 0.08) -23.35%, rgba(239, 137, 184, 0.08) -21.87%, rgba(186, 84, 161, 0.08) 2.25%, rgba(153, 51, 147, 0.08) 20.37%, rgba(141, 39, 142, 0.08) 30.32%, rgba(123, 41, 143, 0.08) 44.92%, rgba(77, 46, 144, 0.08) 74.01%, rgba(46, 49, 145, 0.08) 91.51%);
}

.radio-button-col {
  flex: 0 0 auto !important;
  width: 15% !important;
  padding: 0px !important;
}

.gradebook-option-radio-col {
  /* flex: 0 0 auto !important; */
  display: flex;
  width: 10% !important;
  padding: 0px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.q-type-btn, .gradebook-option-btn {
  vertical-align: middle;
  width: 24px;
  height: 24px;
}

.question-icon-col {
  flex: 0 0 auto !important;
  width: 10% !important;
  padding: 0px !important;
}

.question-icon-collapsed-col {
  flex: 0 0 auto !important;
  width: 10% !important;
  padding: 0px !important;
  justify-content: center;
  margin-left: auto;
}

.question-type-col {
  flex: 0 0 auto !important;
  width: 75% !important;
}

.gradebook-option-col {
  flex: 0 0 auto !important;
  width: 90% !important;
  padding: 0px !important
}

@media only screen and (max-width: 850px) {
  .detailSidebarTitle {
    font-size: 12px;
    line-height: 12px;
    padding-left: 5px !important;
  }
  .question-icon-col {
    display: none;
  }
  .question-icon-collapsed-col {
    display: none;
  }
  .question-type-col {
    flex-basis: 65% !important;
    margin: 0 !important;
    margin-left: 10% !important;
    text-align: center;
  }
  .radio-button-col {
    flex-basis: 25% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .cursor-pointer.row {
    align-items: center;
  }
}

.question-type-text, .gradebook-option-text {
    font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  height: 13px;
  color: #000000;
}

.options-row-outer {
  margin-right: 20px;
}

.options-row {
  padding: 7.5px 0px 7.5px 31px;
  display: flex;
}

.option-button {
  width: 62px !important;
  height: 29px;
  box-sizing: border-box;
  padding: 7.919px 0px;
  text-align: center;
  border: 0.5px solid #6C6C6C;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 850px) {
  .d-flex.no-padding.options-row-outer.justify-content-start.col {
    width: 50% !important;
  }

  .d-flex.no-padding.options-row-outer.justify-content-center.col {
    width: 50% !important;
  }
}

.option-button-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: normal;
  color: #000;
}

.custom-icon {
  display: inline-flex;
  width: 32px !important;
  /* height: 60px !important; */
  align-items: center;
  /* margin-top: 14px !important; */
  /* margin-bottom: 14px !important; */
  padding: 0px !important;
}

.custom-text {
  /* width: 45px !important; */
  /* height: 32px !important; */
  display: inline-flex;
    font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 13px; */
  align-items: center;
  padding: 0px !important;
  margin: 0px 5px 0px 5px !important;
}

.custom-box {
  /* width: calc(100% - 100px) !important; */
  /* width: 150px !important; */
  height: 60px !important;
  border: 0.5px solid #6c6c6c !important;
  box-sizing: border-box;
  border-radius: 3px !important;
  text-align: center;
  margin-left: auto !important;
  margin-right: 20px;
}

.collapsed-custom-box {
  /* width: calc(100% - 100px) !important; */
  /* width: 150px !important; */
  height: 50px !important;
  border: 0.5px solid #6c6c6c !important;
  box-sizing: border-box;
  border-radius: 3px !important;
  text-align: center;
  margin: 0px auto;
}

.custom-input {
  width: 50px !important;
    font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  margin-top: 15px !important;
}

.custom-input-setting {
  margin-top: 0px ;
    font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  width: 120px;
  height:40px; 
  border: 0.931px solid #6E757C;
  border-radius: 2.793px;
}

.custom-input-setting:focus-within {
  box-shadow: 0 0 0 1px #42296E!important;
}

.custom-rank-setting {
  margin-top: 0px ;
    font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  width: 120px;
  height:40px; 
  border: 0.931px solid #6E757C;
  border-radius: 2.793px;
}

.custom-rank-setting::-webkit-inner-spin-button, 
.custom-rank-setting::-webkit-outer-spin-button {
  opacity: 1;
}

.collapsed-custom-text {
  width: 50px !important;
    font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  margin: 13px auto 0px auto !important;
}

.custom-caption {
    font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
}

.max-points, .max-entries {
    font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  margin-top: 2.5px;
  margin-right: 20px;
}

@media only screen and (max-width: 1750px) { 
  .options-row {
    padding: 7.5px 0px 7.5px 11px;
  } 

}

@media only screen and (max-width: 1680px) {
  .options-row-outer {
    margin-right: 5px;
  }

  .custom-box {
    margin-right: 5px;
  }

  .max-points, .max-entries {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1360px) {
  .options-row {
    padding: 7.5px 0px 7.5px 21px;
  }

  .options-row-outer {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1250px) {
  .options-row {
    padding: 7.5px 0px 7.5px 31px;
  }
}

@media only screen and (max-width: 1320px) {
  .custom-box {
    margin-right: 0px;
  }

  .max-points, .max-entries {
    margin-right: 0px;
  }
}

.sidebar-with-scroll {
  overflow-y: auto;
  height: calc(100vh - 52px) !important;
  background-color: #f4f4f4;
}

.mobile-menu-modified-question {
  display: none;
}

@media only screen and (max-width: 950px) { 
  div.Graded.col  {
    flex-basis: 40px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 850px) {
  div.Graded.col  {
    flex-basis: 10% !important;
  }
  div.drag-icon-column.margin-vertical-auto.col {
    flex-basis: 5% !important;
  }

  div.radio-button-col.margin-auto.center.col {
    flex-basis: 10% !important;
  }

  div.question-input-col.margin-vertical-auto.col {
    flex-basis: 60% !important;
    padding-left: 12px !important;
  }

  div.margin-vertical-auto.col {
    padding: 0 !important;
  }
}

.YuJa-switch {
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
}

.YuJa-switch .form-check-input{
  float: none !important;
  margin: 0 5px 0 0 !important;
}

.YuJa-switch .form-check-input:checked {
  background-color: rgb(2, 119, 173)!important;
  border-color: rgb(2, 119, 173)!important;
}

.recycleform-check-input :checked {
  background-color: #0B6F85 !important;
  border-color: #0B6F85 !important;
}

.YuJa-switch .form-check-input:focus {
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

.YuJa-switch .form-check-input:not(:checked):focus {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  background-image: url("../../images/toggleOffIcon.svg")!important;
}

.YuJa-switch .form-check-label{
    font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #535353;
}

.question-dashboard-list {
  overflow-y: auto;
  max-height: 65vh; 
}

@media screen and (max-height: 870px) {
  .question-dashboard-list {
    max-height: 55vh; 
  }
}

@media screen and (max-height: 780px) {
  .question-dashboard-list {
    max-height: 40vh; 
  }

  .question-detail-side-bar-height {
    max-height: 60vh !important;
  }
}

@media screen and (max-height: 575px) {
  .question-dashboard-list {
    max-height: 30vh; 
  }

  .question-detail-side-bar-height {
    max-height: 40vh !important;
  }
}

.PollTitleInput::placeholder {
  /* color: #006DC7; */
  font-weight: 400;
}

.question-edit-view-parent-container {
  display: flex;
  flex-direction: column;
  gap: min(5%, 30px);
  background-color: #FCFBFF;
  padding: min(2%, 30px);
  height: 100%;
  width: 100%;
}