div.DraftEditor-root {
    font-style: normal !important;
    font-weight: 400 !important;
    /* font-size: 20px !important;
    line-height: 30px !important; */
    text-align: left !important;
    color: #6c6c6c !important;
    outline:none !important;
    border: none !important;
    box-shadow: none !important;
    font-style: normal;
    padding: 0;
}

.DraftEditor-editorContainer {
  height: 100px !important;
  overflow-y: auto !important;
}

.custom-editor-container .public-DraftEditorPlaceholder-root {
  color: #535353;
  text-align: center;
    /* font-size: 18px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



.FITBAnswerContainer {

}

.clickable-image-container {
  position: relative;
  text-align: end;
  justify-content: end;
  max-width: 90%;
  align-self: flex-start;
  margin-left: 10%;
  max-height: 80%;
}



@media only screen and (max-height: 765px) {
  
  .FITBAnswerContainer {
    height: auto; 
    overflow-y: visible;
  }

  .FITB-main-container {
    max-height: 80%; 
    overflow-y: auto;
  }

}

@media only screen and (max-height: 650px) {

  .FITB-main-container {
    max-height:60%; 
  }

  .clickable-image-container {
    max-height: 80%;
  }

}