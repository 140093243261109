.box {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    border: 1px solid #6F6F6F!important;
    border-radius: 4px;
    background-color: #FFFFFF;

    font-family: SanFranciscoPro;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 1rem;
}

.box:not([class*='disabled']):hover,.box:not([class*='disabled']) input:hover  {
    cursor: text;
}

.box:hover{
    background: #F5F5F5;
    box-shadow: 0 0 4px 0 #00000040;
}

.box input:hover {
    background: #F5F5F5!important;
}

.box:focus-within {
    border: 1px solid #0B6F85!important;
}


.box input {
    max-width: 100%;
    width: inherit;
    background-color: inherit;
    padding: 0;
    margin: 0;
    flex: 1;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.box input:focus {
    box-shadow: none;
}

.box input:-webkit-autofill,
.box input:-webkit-autofill:focus,
.box input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: inherit;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
}

.box input:-webkit-autofill:hover {
    box-shadow: inset 0 0 20px 20px #F5F5F5;
}


.circle {
    background-color: #0B6F85;
}

.disabled{
    opacity: .5;
    background-color: #E6E6E6;
}
.disabled input {
    background-color: #E6E6E6;
}

.invalid {
    border: 1px solid #BF0033!important;
}

.iconContainer {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: default;
}
.invalidIcon {
    position: absolute;
    margin-left: 3px;
    width: 25px;
    height: 25px;
    background: url("../../images/invalidIcon.svg");
    background-size: 25px 25px;
}

.invalidIcon:hover {
    background: url("../../images/invalidIcon_hover.svg");
}

input[placeholder], input[placeholder]:focus {
    text-overflow:ellipsis!important;
    white-space: nowrap!important;
    overflow: hidden!important;
}
input::-moz-placeholder, input::-moz-placeholder:focus {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
input:-moz-placeholder, input:-moz-placeholder:focus {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
input:-ms-input-placeholder, input:-ms-input-placeholder:focus {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.charCount {
    /*width: 20px;*/
    height: 15px;
    line-height: 15px;
    font-size: 12px;
    color: #000 !important;
    opacity: 0.5;
}
