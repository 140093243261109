:root {
  --question-box-padding: 2.5vw;
}

.h5 {
  font-size: 1.45rem !important;
}

.letters {
  height: 1.7rem;
  margin: auto;
  position: absolute;
  left: 3%;
  margin-left: 5px;
  margin-right: 5px;
  color: black;
}

.customized-navbar {
  top: 3vh !important;
  height: 5vh !important;
}

@media only screen and (min-width: 370px) and (max-width: 420px) {
  .letters {
    height: 1rem;
    top: 10%;
    left: 3%;
  }
}

.get-ready-background {
  text-align: center;
  background-size: 100% 50%;
}

.get-ready-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.get-ready-timer-value {
  font-style: normal;
  font-weight: 600;
  font-size: 146.147px;
  line-height: 219px;
  text-align: center;
  color: #000000;
}

.get-ready-title {
    font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 68px;
  color: #0277AD;
}

.get-ready-all-title {
    font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  color: #0277AD;
}

.get-ready-sub-title {
  color: #494949;
  
    text-align: center;
        font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.get-ready-footer {
    font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #464646;
  padding-top: clamp(10px, 20%, 100px);
}

.gr-style {
  position: absolute;
  height: 100%; 
  overflow-y: auto;
  width: 100%;
  /* min-height: calc(100vh - 52px); */
}

.chart-x-axis {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.main-component-padding-top {
  padding-top: 30% !important;
}

#root {
  background-size: cover;
  background-position: contain;
}

.waiting-room-subtitle {
    font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: #494949;
}

.waiting-room-bottom {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: normal;
  text-align: center;
  color: #494949;
}

.waiting-room-bottom-bottom {
    font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  text-align: center;
  color: #494949;
}

.waiting-room-schedule{
    font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: normal;
  text-align: center;
  color: #494949;
}

.waiting-room-animation-container {
  margin-top: 1.5% !important;
  z-index: -1 !important;
}

.graphs-row {
  margin-right: 40px !important;
}

.animation-row-blank {
  border: 20.875px solid #000000;
  border-radius: 10px;
  position: absolute;
  width: 100%;
}

.animation-row-filled {
  border: 20.875px solid #f1b843;
  border-radius: 10px;
  position: absolute;
  z-index: 10;
  width: 100%;
  background: #f1b843;
}

.animation-width {
  width: 28rem !important;
}

@media only screen and (max-width: 500px) {
  .animation-width {
    width: 100% !important;
  }
}

.animation-surface {
  height: 24.875px;
  background-color: #f1b843;
  border-radius: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.waiting-room-title {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: normal;
  color: #42296E;
}

.no-pointer-cursor {
  cursor: auto !important;
}

.disactive-button {
  cursor: auto !important;
  opacity: 0.4;
}

.disactive-button:hover {
  background-color: #fff !important;
  color: #6c757d !important;
}

.top-fixed {
  top: 0 !important;
}

.hover {
  cursor: pointer;
}

.hover:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

.card-title {
  margin-bottom: 0rem !important;
}

.spinner-container {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
}

.username_text {
  text-align: left;
  padding-left: 20%;
}

label.radio {
  cursor: pointer;
}

label.radio input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}

label.radio span {
  padding: 4px 0px;
  border: 1px solid red;
  display: inline-block;
  color: red;
  width: 100px;
  text-align: center;
  border-radius: 3px;
  margin-top: 7px;
  text-transform: uppercase;
}

.cursor {
  cursor: pointer;
}

label.radio input:checked+span {
  border-color: red;
  background-color: red;
  color: #fff;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn:active {
  outline: 0 !important;
  box-shadow: none !important;
}

.show-ans {
  flex: 1 1;
  text-align: left;
  padding-bottom: 7px;
  margin: auto;
}

html {
  height: 100%;
}

#main {
  background-color: #eee !important;
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
  font-family: "SanFranciscoPro";
  color: #888;
  margin: 0;
}

.fof h1 {
  color: #888;
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

.loading {
  position: absolute;
  top: 0;
  bottom: 20%;
  left: 0;
  right: 0;
  margin: auto;
}

.loading svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#infinity-outline {
  fill: transparent;
  stroke-width: 4;
  stroke: #582a6b;
  stroke-dasharray: 3px, 300px;
  stroke-dashoffset: 0;
  animation: anim 3000ms linear infinite;
}

#infinity-bg {
  fill: transparent;
  stroke-width: 4;
  stroke: #424242;
  opacity: 0.2;
}

@keyframes anim {
  12.5% {
    stroke-dasharray: 42px, 300px;
    stroke-dashoffset: -33px;
  }

  43.75% {
    stroke-dasharray: 105px, 300px;
    stroke-dashoffset: -105px;
  }

  100% {
    stroke-dasharray: 3px, 300px;
    stroke-dashoffset: -297px;
  }
}

h3 {
  font-family: "SanFranciscoPro";
  text-align: center;
  margin-bottom: 40px;
}

.waiting-room-background {
  background-color: #FCFBFF;
}

.timer-wrapper {
  margin-top: 4.55%;
  display: flex;
  justify-content: center;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.flexed-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flexed-container .child {
  width: 100%;
  padding: 5px;
}

.strike {
  margin: 2%;
}

.strike>span {
  position: relative;
  display: inline-block;
}

.strike>span:before,
.strike>span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 90%;
  height: 1px;
  background: #000;
}

/* Styling for the correct and wrong modals */
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

.response-container {
  width: 380px;
  height: 450px;
  border-radius: 0px;
  margin: 0 auto;
  display: table;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
}

.modalOverlay {
  z-index: 0;
  -webkit-transform: translateY(25%);
  -moz-transform: translateY(25%);
  -ms-transform: translateY(25%);
  -o-transform: translateY(25%);
  transform: translateY(25%);
  background: transparent;
}

.container-message {
  background-color: white;
  border-radius: 18px;
  padding: 5%;
}

.container-message2 {
  transform: translateY(20%);
  padding: 0% 8% !important;
}

@media only screen and (max-height: 700px) and (min-width: 550px) {
  .container-message {
    padding: 1.5%;
  }
}

@media only screen and (max-height: 400px) {
  .container-message2 {
    transform: translateY(5%);
  }
}

.sa-container-message {
  background-color: white;
  border-radius: 18px;
  padding: 5%;
}

.sa-container-message2 {
  transform: translate(0%, 20%);
  padding: 0% 8% !important;
}

@media only screen and (max-height: 700px) and (min-width: 550px) {
  .sa-container-message {
    padding: 1.5%;
  }
}

@media only screen and (max-height: 400px) {
  .sa-container-message2 {
    transform: translateY(5%);
  }
}

.result-text {
  font-size: 0.9em;
  font-weight: 100;
  letter-spacing: 3px;
  padding-top: 5px;
  color: #fcfcfc;
  padding-bottom: 5px;
  text-transform: uppercase;
  margin: 0px !important;
}

.green {
  color: #0A820E;
}

.red {
  color: #e96075;
}

.alert {
  font-weight: 700;
  letter-spacing: 5px;
}

.response-description {
  margin-top: -5px;
  font-size: 0.5em;
  font-weight: 100;
  color: #5e5e5e;
  letter-spacing: 1px;
}

button,
.dot {
  cursor: pointer;
}

#success-box {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, #b0db7d 40%, #99dbb4 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(203, 205, 211, 10);
  perspective: 40px;
}

#error-box {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(203, 205, 211, 10);
}

.dot {
  width: 8px;
  height: 8px;
  background: #fcfcfc;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  right: 6%;
}

.dot:hover {
  background: #c9c9c9;
}

.two {
  right: 12%;
  opacity: 0.5;
}

.face {
  position: absolute;
  width: 22%;
  height: 15%;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777;
  top: 26%;
  left: 37.5%;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}

.face2 {
  position: absolute;
  width: 22%;
  height: 15%;
  background: #fcfcfc;
  border-radius: 50%;
  border: 1px solid #777;
  top: 26%;
  left: 37.5%;
  z-index: 2;
  animation: roll 3s ease-in-out infinite;
}

.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right-eye {
  left: 68%;
}

.mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777 #777 transparent;
  transform: rotate(45deg);
}

.sad {
  top: 49%;
  border: 2px solid;
  border-color: #777 transparent transparent #777;
  transform: rotate(45deg);
}

.shadow {
  position: absolute;
  width: 21%;
  height: 3%;
  opacity: 0.5;
  background: #777;
  left: 40%;
  top: 43%;
  border-radius: 50%;
  z-index: 1;
}

.scale {
  animation: scale 1s ease-in infinite;
}

.move {
  animation: move 3s ease-in-out infinite;
}

.message {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 40%;
  top: 47%;
}

.button-box {
  position: absolute;
  background: #fcfcfc;
  width: 50%;
  height: 15%;
  border-radius: 20px;
  top: 73%;
  left: 25%;
  outline: 0;
  border: none;
  box-shadow: 2px 2px 10px rgba(119, 119, 119, 0.5);
  transition: all 0.5s ease-in-out;
}

.button-box:hover {
  background: #efefef;
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
    left: 25%;
  }

  50% {
    left: 60%;
    transform: rotate(168deg);
  }

  100% {
    transform: rotate(0deg);
    left: 25%;
  }
}

@keyframes move {
  0% {
    left: 25%;
  }

  50% {
    left: 60%;
  }

  100% {
    left: 25%;
  }
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.class-res {
    font-style: normal;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.035em;
  width: 80%;
  text-align: center;
  color: #6C6C6C;
  margin-top: 2%;
  margin-bottom: 2%;
}

/*.question-share-text {
  padding: 8px;
  color: #6C6C6C;
}*/

.questionShareCountContainer {
  display: flex;
  flex-wrap: wrap;
  width: 195px;
  height: 45px;
  border-radius: 47px;
  background: linear-gradient(1.55deg, #031434 -12.08%, #2A4E83 57.68%);
  position: relative;
}

.questionShareCountTextCont {
  text-align: center;
  white-space: nowrap;
  color: #000000;
  margin: 2px;
  width: 195px;
  height: 40px;
  border-radius: 47px;
  background-color: #eef0f5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.questionShareCountBorder {
    font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.035em;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

@media only screen and (max-width: 1050px) {
  .questionShareCountTextCont {
    width: 140px;
  }
}

.header-board {

}

.header-board-question {
  display: flex;
  justify-content: space-between;
}

.ques-title {
    font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 25px 0;
  word-break: break-word;
  margin-bottom: 0px !important;
  letter-spacing: 0.035em;
	color: #6C6C6C;
}

.timer-outer {
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
}

.timer-holder {
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
  border-radius: 20px;
  width: 170px;
  border: 2px solid #056808;
  height: 40px;
  padding: 9px;
  background-color: #FFFFFF;
}

.timer-row-card {
  margin: 2%;
  padding: 0px;
  width: 150px;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-items: center;
  vertical-align: middle;
}

.timer-row {
  margin: 2%;
  padding: 0px;
  vertical-align: middle;
  border-radius: 100px;
  width: 150px;
}

.timer-logo-col {
  margin: 0px;
  padding: 0px;
  vertical-align: middle;
}

.img-wrap img {
  float: left;
}

.img-wrap p {
  float: left;
}

.img-wrap span {
  float: right;
}

.timer-logo {
  height: 30px;
  width: 30px;
}

.timer-text-col {
  margin: 0px;
  padding: 0px;
  align-content: center;
  vertical-align: middle;
}

.timer-text {
    font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #464646;
  padding: 10px 0px 0px 0px;
  margin: 0%;
}

.timer-count {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 24.306px;
  line-height: normal;
  color: #000000;
  padding: 5px;
  margin-right: 0px;
}

@media only screen and (max-width: 480px) {
  .timer-holder {
    padding: 5px;
    height: 40px;
    width: 135px; 
  }
  .timer-count {
    font-size: 14px;
  }
}

.count-box {
  padding: 10px 0px 0px 0px;
}

.pop-up-card {
  align-items: center;
  background: #FFFFFF;
  border-radius: 17.2881px;
  border: none;
}

.pop-up-header {
    font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  letter-spacing: 0.035em;
}

.pop-up-body {
    font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  letter-spacing: 0.035em;
}

.sa-pop-up-card {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 17.2881px;
  border: none;
}

.sa-pop-up-header {
    font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  letter-spacing: 0.035em;
  margin: 30px 0px 25px 0px;
}

.sa-pop-up-body {
    font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  letter-spacing: 0.035em;
}

.option-text {
    font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #6C6C6C;
  vertical-align: middle !important;
  position: absolute;
  top: 20%;
  margin: 0px;
  padding: 0px;
}

.option-letter {
    font-style: normal;
  font-weight: 400;
  font-size: 27.7027px;
  color: #464646;
  vertical-align: middle !important;
  margin-left: 10px;
  padding: 0px;
}

.option-btn {
  background: #FFFFFF;
  color: #0A820E;
  border: 1.69238px solid #838383;
  vertical-align: middle !important;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20%;
  margin: 0px;
  padding: 0px;
}

.ans-row {
  width: 90%;
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

.loading-text-back{
  height: fit-content;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
}

.loading-text{
    font-style: normal;
  font-weight: 600;
  font-size: 22.5585px;
}

.main-body {
  flex : 1
}

.car {
  margin-bottom: 0px;
  padding: 0px;
  border: 0px;
}

#sub-btn {
  display: none;
}

.hide{
  visibility: hidden;
  display: none;
}

.disabled-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 60px;
    font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  background-color: #E6E6E6;
  color: #CACACA;
  border-radius: 10px;
  margin: 0px;
  cursor: not-allowed!important;
}

.submit-btn {
  width: 100%;
  justify-content: center;
  align-items: center;
    font-style: normal;
  font-weight: 600;
  font-size: 22.5585px;
  line-height: 34px;
  background-color: #FFD786;
  color: #000;
  border: none;
  height: 90px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.submit-btnr {
  width: 100%;
  justify-content: center;
  align-items: center;
    font-style: normal;
  font-weight: 600;
  font-size: 22.5585px;
  line-height: 34px;
  background-color: #FFD786;
  color: #000;
  border: none;
  height: 90px;
  position: relative;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 480px) and (max-height: 480px) {
  .submit-btnr-new {
    width: 175px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    background-color: #FFFFFF;
    color: #004A6D;
    border: 2.25px solid #004A6D;
    border-radius: 10px;
    margin: 0px;
  }

  .submit-btnr-new:hover {
    cursor: pointer;
    color: #FFFFFF !important;
    background-color: #004A6D !important;
  }
}

.previous-btn {
  position: fixed;
  bottom: 7.5vw;
  left: 7.5vw;
}

.next-btn {
  position: fixed;
  bottom: 7.5vw;
  right: 7.5vw;
}

.sa-ans {
  padding: 10px;
  height: 30vh;
  max-height: 30vh;
  background: #FFFFFF;
  box-shadow: 0 0 0 1px #B9B9B9!important;
  border-radius: 12px;
  margin-top: 30px;
  resize: none !important;
}

.oe-ans {
  padding: 10px;
  height: 20vh;
  max-height: 20vh;
  background: #FFFFFF;
  box-shadow: 0 0 0 1px #B9B9B9!important;
  border-radius: 12px;
  margin-top: 30px;
  resize: none !important;
}

@media only screen and (max-width: 480px) {
  .oe-ans {
    height: 96px;
    margin-top: 5px;
  }
}

.sa-an:focus {
  box-shadow: 0 0 0 5px #0277AD!important;
}

.sa-ans:disabled {
  opacity: 0.5;
  background-color: #E6E6E6;
}

.sa-ans:disabled:hover {
  cursor: not-allowed;
}

.wc-ans-container {
  display: flex;
  padding-top: 30px !important;
  align-items: center;
  justify-content: center;
}

.wc-ans {
  /* margin-left: 35px; if delete icon is shown */
  margin-left: 15px;
  padding: 10px;
  height: 50px;
  max-height: 50px;
  background: #FFFFFF;
  border: 1px solid #D0D4D9;
  border-radius: 12px;
  resize: none !important;
  /* text-align: center; */
}

.wc-ans-odd {
  margin-left: 40px;
  padding: 10px;
  height: 50px;
  max-height: 50px;
  background: #FFFFFF;
  border: 1px solid #D0D4D9;
  border-radius: 12px;
  resize: none !important;
  /* text-align: center; */
}

.wc-ans:disabled, .wc-ans-odd:disabled {
  border: 1px solid darkgray!important;
}

.wc-ans:disabled:hover, .wc-ans-odd:disabled:hover {
  cursor: not-allowed;
}

.wc-char-count {
  width: 20px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  opacity: 0.5;
}

.delete-entry-icon {
  width: 20px;
  height: 20px;
  line-height: 15px;
  font-size: 12px;
}

.delete-entry-icon:hover {
  cursor: pointer;
}

.sa-center {
  align-items: center !important;
  align-content: center !important;
}

.wc-center {
  display: grid;
  grid-template-columns: 45% 45%;
  padding-top: 15px !important;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.wc-center-narrow {
  display: flex !important;
  flex-direction: column !important;
  padding-top: 15px !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
}

.wc-center-odd {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
}

.sa-ans:focus, .wc-ans:focus, .wc-ans-odd:focus {
  border: 2px solid #42296E;
}

.FITB-input:disabled {
  cursor: not-allowed;
}

@media only screen and (min-width: 235px) {
  .FITB-input {
    border-bottom: 0.5px solid #606060;
        font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    width: 40%;
    border: 1px solid #D0D4D9;
    border-radius: 10px;
  }
}


.TF-label {
  margin-top: 30px;
}

.MCSS-letter {
  display: inline;
    font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  color: #42296E;
}

.MCSS-letter-container {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #FFFFFF;
  flex-shrink: 0;
}

.MCSS-opt-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: #C8C7C7;
  border-radius: 8px;
  flex-shrink: 0;
  z-index: 1;
}

@media only screen and (max-width: 480px) {
  .MCSS-letter-container {
    width: 25px;
    height: 25px;
    border-radius: 5px;
  }

  .MCSS-opt-image-container {
    width: 35px;
    height: 35px;
    border-radius: 5px;
  }

  .MCSS-letter {
    font-size: 14px;
  }
}

.MCSS-option {
  display: inline;
  font-weight: 400;
  font-size: 18px;
  word-wrap: break-word;
  line-height: normal;
  letter-spacing: 0.84px;
  vertical-align: middle;
  display: inline;
  margin-bottom: 0px;
}

.waiting-room-network-container {
  position: absolute;
  right: 5%; 
}

.TF-option {
  display: inline;
    font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.035em;
  line-height: 18px;
  vertical-align: middle;
  display: inline;
  margin-bottom: 0px;
  margin-top: 20px;
}

input[type='radio']:checked:before {
  background: #0277AD;
}

input[type='radio']:disabled:checked:before {
  background: darkgray;
}

.MCSS-btn {
  display: none !important;
}

.TF-btn {
  vertical-align: middle;
  height: 30px;
  width: 30px;
}

input[type='radio']:checked:before {
  background: #0277AD;
}

input[type='radio'] {
  -webkit-appearance: none;
  border: 2px solid darkgray;
  border-radius: 50%;
  outline: none;
}

input[type='radio']:hover {
  cursor: pointer;
}

input[type='radio']:disabled:hover {
  cursor: not-allowed;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.MCSS-label {
  min-height: 60px;
  color: #42296E;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 2px solid #909090;
  cursor: pointer;
}

.MCSS-label.selected {
  color: #FFFFFF;
  background-color: #42296E;
  border: 2px solid #42296E;
}

.MCSS-label:focus-visible {
  color: #FFF;
  background-color: #42296E;
}

.MCSS-label:focus-visible .MCSS-option{
  color: #FFF !important;
}

.MCSS-label:focus-visible .MCSS-letter-container {
  border: 1.5px solid #FFF !important;
}

.MCSS-label.disabled {
  cursor: not-allowed;
  border: 2.25px solid #939393;
}

.MCSS-label.disabled.selected {
  background-color: #939393;
}

.chart-txt {
  /* display: inline; */
	width: 25px;
	height: 18px;
		font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.035em;
  /* color: #000000 !important; */
}

.bar-title {
  position: absolute;
    font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85% !important;
}

.bar-title-c {
  font-weight: 600;
  font-size: 16px;
  color: #0A820E;
}

.bar-title-i {
	  font-weight: 400;
  font-size: 18px;
  color: black;
}

#red-clock {
  visibility: hidden;
  left: 0px;
}

.conf {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999 !important;
  align-items: center;
}

.waiting-room-background {
  background-position-x: -310px;
  background-position-y: 80px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%; 
  align-items: center;
  overflow-y: auto;
  position: relative;
}
/* Results Page */

.results-wrap {
  align-items: center;
  position: relative;
  min-height: 100%;
  padding-bottom: 0rem;
}

.results-title {
    font-style: normal;
  font-weight: 700;
  font-size: 30.6667px;
  line-height: 46px;
  text-align: center;
  color: #464646;
  padding-top: 10%;
}

.results-sub-title {
    font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #6C6C6C;
  padding: 20px 0% 20px 0%;
  margin: 0% 0% 0% 0%;
}

.results-box-title {
  background-color: #FFD786;
  text-align: center;
  padding: 2% 10% 2% 10%;
  margin: 0% 4% 0% 4%;
}

.results-box-title-img {
    font-style: normal;
  font-weight: 600;
  font-size: 20.353px;
  text-align: center;
  flex-basis: 50%;
  display: inline;
}

.results-box-title-text {
    font-style: normal;
  font-weight: 600;
  font-size: 20.353px;
  text-align: center;
  flex-basis: 50%;
  display: inline;
}

.results-box-body {
  background-color: #FEF9EF;
  text-align: left;
  padding: 20px 3% 20px 3%;
  margin: 0% 4% 0% 4%;
}

.results-box-body-head {
  text-align: left;
  margin: 2% 0% 2% 0%;
}

.results-prop-name {
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: inline;
  flex-basis: 50%;
  color: #000000;
}

.results-prop-val {
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #6C6C6C;
  display: inline;
  flex-basis: 50%;
  margin-left: 0px;
}

.results-btn-tether {
  position: absolute;
  bottom: 0;
  padding: 0px;
  margin: 0px;
}

.results-btn-wrap {
  padding: 20px 10% 20px 10%;
  margin: 0% 2% 0% 2%;
}

.results-home-btn {
  padding: 0px !important;
  height: 50px;
  border-radius: 3.144px;
  font-weight: 400;
  background: #FFFFFF;
  border: 1.10154px solid #464646;
  border-radius: 3.47393px;
  box-sizing: border-box;
}

.results-home-btn-text {
    font-style: normal;
  font-size: 20.96px;
  line-height: 31px;
  text-align: center;
}

.set-middle {
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  display: flex;
}

.big-lock{
  height: 200px;
  width: 200px;
}

.img-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.img-container-question {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.lottie-container {
  /* width: 50%; */
  /* height: 50%; */
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
}

.lottie-container-w-shadow {
  filter: drop-shadow(0px 7px 8px rgba(0, 0, 0, 0.13));
  border-radius: 30px;
}

.lottie-container:hover {
  cursor: default;
}

.left-wrapper {
  width: 32% !important;
  height: 100% !important;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FAFAFA;
}

.result-header {
    font-weight: 700;
  font-size: 28px;
  justify-content: center;
  letter-spacing: 0.035em;
  margin-bottom: 25px;
  color: #6C6C6C;
}

.result-body-row {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 50px;
}

.result-body-title {
    font-weight: 600;
  font-size: 20px;
  /* justify-content: center; */
  line-height: 30px;
  display: inline;
  padding: 0px !important;
}

.result-body {
    font-weight: 500;
  font-size: 20px;
  /* justify-content: center; */
  line-height: 30px;
  display: inline;
  padding: 0px !important;
}

.footer-container {
  display: flex;
  align-items: center;
    font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.035em;
  margin: 10px 0 10px 0;
  padding: 0 calc(var(--question-box-padding));
}

.right-footer-title {
  display: inline;
	width: 110px;
	height: 18px;
		font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.035em;
	color: #000000;
}

.right-footer-spacing {
	display: inline;
	margin-left: 75px;
}

.right-footer-body {
  display: inline;
	height: 18px;
		font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.035em;
	color: #000000;
}

.verticalLine {
  width: 2px;
  height: 250px;
  padding: 0px;
  background: linear-gradient(180deg, transparent, #6C6C6C, transparent);
  background-position: 50%;
  background-repeat: repeat-y;
  background-size: 2px auto;
}

.finished-title {
  margin-top: 15px !important;
}

.modal-content {
  display: flex;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.modal-backdrop.show {
  opacity: 0.35 !important;
}

.modal-header {
  padding: 1.2rem 1.2rem !important;
}

.schedule-modal-header {
  background-color: #F7F8FA;
  border-radius: 15px 15px 0 0 !important;
  border: none !important;
  padding: 0 !important;
  justify-content: center !important;
  align-items: center !important;
  height: 40px !important;
}

.reattempt-row {
  position: absolute;
  width: 100% !important;
  bottom: 10px !important;
  left: 0px !important;
  padding: 0px !important;
  margin-bottom: 20px;
}

.reattempt-text {
    font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2rem;
}

.reattempt-button {
  margin: 0px 0px 0px 10px !important;
  border-radius: 0.198rem;
  padding: 0.563rem 0.625rem;
  border: 0.125rem solid rgba(0, 107, 155, 1);
  color: rgba(0, 107, 155, 1);
  font-size: 0.857rem;
  line-height: 0.857rem;
}

.reattempt-button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 107, 155, 1);
}

.reattempt-button-disabled {
  margin: 0px 0px 0px 10px !important;
  border-radius: 0.198rem;
  padding: 0.563rem 0.625rem;
  background-color: #DADADA;
  color: #5A5959;
  font-size: 0.857rem;
  line-height: 0.857rem;
}

.word-cloud-wrapper {
  width: 100%;
  height: 100%;
  /* flex-grow: 1; */
}

.reattempt-row {
  position: absolute;
  width: 100% !important;
  bottom: 10px !important;
  left: 0px !important;
  padding: 0px !important;
  margin-bottom: 20px;
}

.reattempt-text {
    font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2rem;
}

.reattempt-button {
  margin: 0px 0px 0px 10px !important;
  border-radius: 0.198rem;
  padding: 0.563rem 0.625rem;
  border: 0.125rem solid rgba(0, 107, 155, 1);
  color: rgba(0, 107, 155, 1);
  font-size: 0.857rem;
  line-height: 0.857rem;
}

.reattempt-button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 107, 155, 1);
}

.reattempt-button-disabled {
  margin: 0px 0px 0px 10px !important;
  border-radius: 0.198rem;
  padding: 0.563rem 0.625rem;
  background-color: #DADADA;
  color: #5A5959;
  font-size: 0.857rem;
  line-height: 0.857rem;
}

.reattempt-row {
  position: absolute;
  width: 100% !important;
  bottom: 10px !important;
  left: 0px !important;
  padding: 0px !important;
  margin-bottom: 20px;
}

.reattempt-text {
    font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2rem;
}

.reattempt-button {
  margin: 0px 0px 0px 10px !important;
  border-radius: 0.198rem;
  padding: 0.563rem 0.625rem;
  border: 0.125rem solid rgba(0, 107, 155, 1);
  color: rgba(0, 107, 155, 1);
  font-size: 0.857rem;
  line-height: 0.857rem;
}

.reattempt-button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 107, 155, 1);
}

.reattempt-button-disabled {
  margin: 0px 0px 0px 10px !important;
  border-radius: 0.198rem;
  padding: 0.563rem 0.625rem;
  background-color: #DADADA;
  color: #5A5959;
  font-size: 0.857rem;
  line-height: 0.857rem;
}

.thumbupicon {
  width: 20.66px;
  height: 20.05px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../images/thumbuphovered.svg");
}

.thumbupicon-activated {
  width: 19.74px;
  height: 19.16px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../images/thumbupnormal.svg");
}

.thumbdownicon {
  width: 20.66px;
  height: 20.05px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../images/thumbdownhovered.svg");
}

.thumbdownicon-activated {
  width: 19.74px;
  height: 19.16px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../images/thumbdownnormal.svg");
}

@media only screen and (max-width: 480px) {
  .thumbupicon {
    width: 16px; 
    height:16px; 
  }
  
  .thumbupicon-activated {
    width: 16px;
    height: 16px; 
  }
  
  .thumbdownicon {
    width: 16px; 
    height: 16px; 
  }
  
  .thumbdownicon-activated {
    width: 16px;
    height: 16px; 
  }
}

.reaction-background:focus {      
  background-color: #42296E;
  border: 2px solid #42296E;
}

.reaction-background:focus .thumbupicon {
  width: 19.74px;
  height: 19.16px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../images/thumbupnormal.svg");
}

.reaction-background:focus .thumbdownicon {
    width: 19.74px;
    height: 19.16px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../images/thumbdownnormal.svg");
}

.reaction-activated-background {
  background-color: #42296E;
  border: 2px solid #42296E;
}

.reaction-container {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #42296E;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.reaction-container:hover, .reaction-container-activated {
  background-color: #42296E;
}

.reaction-container:focus {
  background-color: #42296E;
}

.reaction-container:hover path, .reaction-container-activated path {
  fill: #FFFFFF;
}

.reaction-container:focus path {
  fill: #FFFFFF;
}

.matchingPremise {
border: 2.5px solid #909090;;
background: var(--color-guide-darkneutralcolord-50, #E6E6E6);
}

.waiting-room-title-container {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 1.66994px 1.66994px 22.58523px 0.83497px rgba(0, 0, 0, 0.05);
  margin-left: 26px;
  margin-right: 26px;
  width: calc(100% - 52px);
  display: flex;
  justify-content: center;
  min-height: 90px;
  align-items: center;
}

.img-question {
  max-width: 600px;
  max-height: 400px;
}

.class-result-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%; 
  margin-top: 30px ;
  margin-right: 18px;
  row-gap: 20px;
}

.class-result-survey-container, .class-result-poll-container {
  display: flex;
  justify-content: center;
  gap: 36px; 
  margin-top: 30px;
}

@media only screen and (max-width: 1480px) {
  .class-result-container {
    grid-template-columns: 25% 25% 25% 25%; 
  }

}

@media only screen and (max-width: 1200px) {
  .class-result-container {
    grid-template-columns: 33% 33% 33%; 
  }

}

@media only screen and (max-width: 950px) {
  .class-result-survey-container {
    display: grid !important;
    margin-top: 30px !important;
    margin-right: 18px !important;
    row-gap: 20px !important;
    grid-template-columns: 50% 50% !important; 
  }

  .class-result-container {
    grid-template-columns: 50% 50%;
  }

}


@media only screen and (max-width: 480px) {
  .fast-style {
    min-height: calc(100vh - 75px);
    width: 100%;
    position: absolute;
    top: 15px;
  }

  .waiting-room-background {
    background-position-x: 0px;
    background-position-y: 0px;
  }

  .poll-title {
    max-width: 75%;
        font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.035px;
    color: #000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0%;
    vertical-align: middle;
    word-break: break-word;
    display: flex;
    align-items: center;
  }

  .qTitle {
    height: 100%;
        font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.035em;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0%;
  }

  .cur-question-title {
    height: 100%;
        font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin: 25px 0 0 0; */
  }

  .questionShareCountContainer {
    width: 140px;
  }

  .ques-name {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.035em;
    text-align: left;
    padding: 0%;
    margin: 0%;
    display: inline;
    color: #000000;
  }

  .ques-pts {
        font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.035em;
    text-align: left;
    padding: 0%;
    margin: 0%;
    display: inline;
    color: #000000;
  }

  .ques-desc {
    min-height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 10px;
    word-break: break-word;
    margin-bottom: 0px !important;
    letter-spacing: 0.035em;
  }

  .ques-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
  }

  .pop-up-header {
    font-size: 16px;
    line-height: 16px;
  }

  .pop-up-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  .submit-btn-new {
    padding: 10px;
    width: 150px;
    height: 60px;
        font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    background-color: #FFFFFF;
    color: #004A6D;
    border: 2.25px solid #004A6D;
    border-radius: 10px;
  }

  .submit-btn-new:hover {
    color: #FFFFFF !important;
    background-color: #004A6D !important;
  }

  .submit-btnr-new {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    background-color: #FFFFFF;
    color: #004A6D;
    border: 2.25px solid #004A6D;
    border-radius: 10px;
    margin: 0px;
  }

  .submit-btnr-new:hover {
    cursor: pointer;
    color: #FFFFFF !important;
    background-color: #004A6D !important;
  }

  .MCSS-row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .MCSS-label {
    width: 100%;
    display: inline-flex;
    align-items: center;
  }

  .img {
    max-width: 80vw !important;
    max-height: 45vw !important;
  }

  .right-header {
        font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.035em;
    text-align: center;
    color: #494949;
    margin-bottom: 40px;
  }

  .result-box {
    background-color: white;
    padding: 0%;
    width: 90vw !important;
    border: 2.02px solid #0277AD;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 240px;
  }

  .question-box {
    padding: var(--question-box-padding);
    width: 90vw !important;
    border: 2px solid #0277AD;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .your-answer {
    width: 90vw !important;
  }

  .result-header {
    padding-left: 5%;
    margin-bottom: 15px;
    font-size: 16px;
    color: #000000;
    justify-content: flex-start;
  }

  .result-body-row {
    text-align: left !important;
  }

  .result-body-title {
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 40px;
  }

  .result-body {
    font-size: 15px;
    line-height: 40px;
  }

  .result-home-button {
    min-width: 140px;
    height: 65px;
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
    background-color: #004A6D;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 0px;
    padding: 10px;
  }

  .result-home-button:hover {
    background-color: #FFFFFF;
    color: #004A6D;
  }

  .result-gradebook-button {
    min-width: 140px;
    height: 65px;
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    background-color: #FFFFFF;
    color: #004A6D;
    border: 2.25px solid #004A6D;
    border-radius: 10px;
    margin-bottom: 0px;
  }

  .result-gradebook-button:hover {
    color: #FFFFFF !important;
    background-color: #004A6D !important;
  }
}

@media only screen and (min-width: 480px) {
  .fast-style {
    position: absolute;
    width: 100%;
    top: 15px;
    min-height: calc(100vh - 75px);
  }

  .qTitle {
    height: 100%;
        font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.035em;
    color: #6C6C6C;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0%;
  }

  .ques-name {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.035em;
    text-align: left;
    padding: 0%;
    margin: 0%;
    display: inline;
    color: #6C6C6C;
  }

  .ques-pts {
        font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.035em;
    text-align: left;
    padding: 0%;
    margin: 0%;
    display: inline;
    color: #6C6C6C;
  }

  .ques-desc {
    min-height: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #6C6C6C;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 10px;
    word-break: break-word;
    margin-bottom: 0px !important;
    letter-spacing: 0.035em;
  }

  .submit-btn-new {
    padding: 10px;
    width: 150px;
    height: 60px;
        font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    background-color: #FFFFFF;
    color: #004A6D;
    border: 2.25px solid #004A6D;
    border-radius: 10px;
    margin: 0px;
  }

  .submit-btn-new:hover {
    color: #FFFFFF !important;
    background-color: #004A6D !important;
  }

  .submit-btnr-new {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    background-color: #FFFFFF;
    color: #004A6D;
    border: 2.25px solid #004A6D;
    border-radius: 10px;
    margin: 0px;
  }

  .submit-btnr-new:hover {
    cursor: pointer;
    color: #FFFFFF !important;
    background-color: #004A6D !important;
  }

  .MCSS-row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .MCSS-label {
    width: 45%;
    display: inline-flex;
    align-items: center;
  }

  .right-header {
        font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0.035em;
    width: 80%;
    text-align: center;
    color: #6C6C6C;
    margin-bottom: 40px;
  }

  .result-home-button {
    min-width: 190px;
    height: 65px;
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    color: #FFFFFF;
    background-color: #004A6D;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 0px;
  }

  .result-home-button:hover {
    background-color: #FFFFFF;
    color: #004A6D;
  }

  .result-gradebook-button {
    min-width: 190px;
    height: 65px;
    align-items: center;
        font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    background-color: #FFFFFF;
    color: #004A6D;
    border: 2.25px solid #004A6D;
    border-radius: 10px;
    margin-bottom: 0px;
  }

  .result-gradebook-button:hover {
    color: #FFFFFF !important;
    background-color: #004A6D !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1200px) {
  .poll-title {
    max-width: 75%;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    color: #42296E;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
    vertical-align: middle;
    word-break: break-word;
    display: flex;
    align-items: center;
  }


  .matchingPremise { 
    max-width: 100%;
  }

  .img-question {
    max-width: 323px;
    max-height: 400px;
    min-height: 150px;
    min-width: 300px;
  }

  .cur-question-title {
    height: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0%;
  }

  .question-box {
    padding: var(--question-box-padding);
    width: 90vw !important;
    border: 2px solid #656565;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .your-answer {
    width: 90vw !important;
  }

  .result-box {
    background-color: white;
    padding: 0%;
    width: 60vw !important;
    border: 2.02px solid #0277AD;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 270px;
  }

  .img {
    max-width: 80vw !important;
    max-height: 45vw !important;
  }
}

@media only screen and (max-width: 1200px) {
  .sa-pop-up-header {
    font-size: 16px;
    line-height: 16px;
    margin: 10px 0px 10px 0px;
  }

  .waiting-room-network-container {
    right: 15%; 
  }

  .sa-pop-up-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  .sa-ans {
    width: 100%;
    max-width: 100%;
  }

  .oe-ans {
    width: 100%;
    max-width: 100%;
  }

  .wc-ans, .wc-ans-odd {
    width: 75vw;
    max-width: 75vw;
  }

  .wc-char-count {
    transform: translate(-25px, 0px);
  }

  .delete-entry-icon {
    transform: translate(-15px, 0px);
  }

  .get-ready-timer-value {
    font-size: 90px;
    line-height: 115px;
  }

  .get-ready-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
  }

  .get-ready-sub-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }

  .get-ready-all-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
  }



  .waiting-room-bottom-bottom {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .waiting-room-schedule {
    font-size: 16px;
    line-height: 16px;
  }

  .waiting-room-subtitle {
        font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  .username_text {
    text-align: center;
    padding-left: 0px;
  }

  .loading {
    bottom: 50% !important;
  }

  .username_text {
    text-align: center;
    padding-left: 0px;
  }

  .loading {
    bottom: 50% !important;
  }

  .img-class-result {
    max-width: 53.3vw !important;
    max-height: 30vw !important;
  }

  .results-container {
    width: 100% !important;
    margin: 0px;
    /* background-size: cover; */
    /* background-image: url("./images/class\ results\ bg\ old.svg"); */
  }

  .left-header {
    width: 100%;
        font-style: normal;
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 0.035em;
    text-align: center;
    color: #0277AD;
    margin-top: 10px;
  }

  .right-wrapper {
    height: 100%;
    width: 100% !important;
    overflow-y: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-image: url("./images/class_results_half_bg.svg");
  }

  .right-footer {
		right: 0;
		margin: 10px 0 40px 0;
		text-align: right !important;
		width: 90vw;
		padding-right: calc(var(--question-box-padding));
	}

  .footer-container {
    width: 90vw;
  }
}

@media only screen and (min-width: 1200px) {
  .sa-container-message {
    padding: 50px 90px !important;
  }

  .poll-title {
    height: 100%;
    max-width: calc(100% - 340px);
    font-weight: 700;
    font-size: 20px;
    line-height: normal;
    letter-spacing: 0.98px;
    color: #42296E;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0%;
    vertical-align: middle;
    word-break: break-word;
    display: flex;
    align-items: center;
  }

  .cur-question-title {
    height: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    color: #000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0%;
    align-self: center;
  }

  .sa-ans {
    width: 100%;
    max-width: 100%;
  }

  .oe-ans {
    width: 100%;
    max-width: 100%;
  }

  .wc-ans {
    width: 25vw;
    max-width: 25vw;
  }

  .wc-ans-odd {
    width: 40vw;
    max-width: 40vw;
  }

  .wc-char-count {
    transform: translate(-25px, 0px);
  }

  .delete-entry-icon {
    transform: translate(-15px, 0px);
  }

  .FITB-input {
    border-bottom: 0.5px solid #606060;
        font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 50px;
    color: #000000;
    width: 300px;
    border: 1px solid #D0D4D9;
    border-radius: 12px;
    margin: 0px 5px 10px 5px;
  }

  .img {
    max-width: 760px !important;
    max-height: 427.5px !important;
  }

  .img-class-result {
    max-width: 29vw !important;
    max-height: 16vw !important;
  }

  .results-container {
    width: 100% !important;
    margin: 0px;
    /* background-size: cover; */
    /* background-image: url("./images/class\ results\ bg\ old.svg"); */
  }

  .left-header {
    width: 100%;
        font-style: normal;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: 0.035em;
    text-align: center;
    color: #6C6C6C;
    margin-bottom: 5%;
  }

  .right-wrapper {
    height: 100%;
    width: 68% !important;
    overflow-y: auto;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
    background-image: url("./images/class_results_bg.svg");
  }

  .result-box {
    background-color: white;
    border: 2px solid #656565;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 9.38267px;
    padding: 0%;
    width: 40vw !important; /* Change back to 55vw when Class Results are displayed */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    float: center !important;
    height: 270px;
  }

  .question-box {
    padding: var(--question-box-padding);
    width: 55vw !important;
    border: 2px solid #656565;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .your-answer {
    width: 55vw !important;
  }

  .right-footer {
		right: 0;
		margin: 10px 0 10px 0;
		text-align: right !important;
		width: 55vw;
		padding-right: calc(var(--question-box-padding));
	}

  .footer-container {
    width: 55vw;
  }
}

.ClassResultDropDown {
  overflow: hidden;
  color: #000;
  font-family: SanFranciscoPro;
  font-size: 18px;
  letter-spacing: 0.63px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  height: 20px;
  max-width: 100%;
}

@media only screen and (max-width: 1200px) {
  /*.attendanceAttentionContainer {*/
  /*  flex-wrap: wrap;*/
  /*}*/
}

.attendanceInsideGeofenceContainer {
  height: 45px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  padding: 8px 15px;
  border-radius: 4px;
  gap: 8px;
  background: #E6F3E6;
  color: #006557;
  border: 2px solid #006557;
}

.attendanceOutsideGeofenceContainer {
  height: 45px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  padding: 8px 15px;
  border-radius: 4px;
  gap: 8px;
  background: #FBEAEB;
  color: #851B22;
  border: 2px solid #851B22;
}

.attendance-description {
  min-height: 18px;
    font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  flex: none;
  flex-grow: 0;
  word-break: break-word;
  letter-spacing: 0;
  order: initial;
  margin-bottom: 10vh !important;
}

.attendance-thank-you {
  font-family: SanFranciscoPro;
  font-size: 30px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #42296E;
  margin-top: 2vh;
}

/* .attendanceBodyWoFooter {
  height: calc(100% - 90px - 15vh) !important;
} */

.attendance-countdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: -3vh;
  margin-bottom: 5vh; */

  position: absolute;
  top: 45px;
  right: 30px;
}

.attendance-check-in-icon {
  text-align: center;
  margin-bottom: 6vh;
}

@media only screen and (max-width: 1200px) {
  .attendance-description {
    margin-bottom: 20px !important;
  }

  .attendance-thank-you {
    font-size: 24px;
  }

  .attendance-lms-geofence-icon {
    margin-top: 5vh !important;
  }

  .attendance-lms-geofence-text {
    font-size: 16px !important;
  }

  /* .attendanceBodyWoFooter {
    height: calc(100% - 150px - 15vh) !important;
  } */

  .attendance-countdown-wrapper {
    top: 20px;
    right: 15px;
  }

  .attendance-check-in-icon {
    margin-bottom: 4vh;
  }
}

.attendance-check-in-text {
    font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 4vh;
}

.attendance-lms-geofence-icon {
  text-align: center;
  margin: 10vh 0 4vh;
}

.attendance-lms-geofence-text {
    font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 4vh;
}

.attendance-ques-title {
  min-height: 18px;
  width: 90%;
    font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  flex: none;
  flex-grow: 0;
  word-break: break-word;
  align-self: center;
  letter-spacing: 0;
  order: initial;
  margin-bottom: 3vh !important;
}

.attendancePrivacyPolicyText {
    font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #464646;
  text-decoration: underline;
  cursor: pointer;
}

.attendancePrivacyPolicyText:hover {
  text-decoration: none;
}

.attendance-thank-you-status {
  font-family: SanFranciscoPro;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  /* color: #42296E; */
  margin: 3vh 0;
}

.href {
  display: inline;
  color: #0277AD;
  text-decoration: underline;
  cursor: pointer;
}

.href:hover {
  text-decoration: none;
}