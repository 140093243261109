.navBarTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pollPanelContainer {
    position: fixed;
    top: 48px;
    left: 10px;
    width: calc(100% - 20px);
    padding: 20px;
    border: 1px solid #D0D0D0;
    border-radius: 7px;
    box-shadow: 0 1px 5px 0 #0000001F;
    background: #FFFFFF;

    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
}

.cardTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #000000;
}



.parentContainer {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.parentContainer.mobile {
    padding: 0;
}

.container {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.pollTitleContainer {
    width: 100%;
    min-height: 60px;
    border-radius: 11px;
    border: 1px solid #EAEAEA;
    background: #FFF;
    box-shadow: 0 0 4px 0 #00000040;
    display: flex;
    align-items: center;
    padding: 15px;
}

.pollTitleContainerTitle{
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: #000000;
    word-break: break-word;
}



.card {
    flex: 1;
    min-height: 0;
    width: 100%;
    display: flex;
    overflow: hidden;

    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 #00000040;
}

.card.mobile {
    border: none;
    border-radius: 0;
    box-shadow: none;
}


.questionListContainer {
    flex-shrink: 0;
    width: 152px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #EAEAEA;
    overflow-y: auto;
}

.questionListContainer::-webkit-scrollbar {
    border-left: 1px solid #D4D4D4;
}

.questionListContainer.tablet {
    width: 88px;
}


.questionListContainer.mobile {
    position: absolute;
    top: 0;
    right: 0;
    width: 88px;
    height: 100%;
    transition: .5s ease;
    border-left: none;
    border-right: none;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #00000040;
    z-index: 2;
    overflow-x: hidden;
}

.questionListContainer.mobile.opened {
    border-left: 1px solid #EAEAEA;
}

.questionListItem {
    flex-shrink: 0;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border-bottom: 1px solid #D4D4D4;
    padding: 0 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.questionListItem.active {
    background: #E7EFF0;
}

.questionListItem.mobile {
    width: 88px;
}

.questionListItem:not(.active):hover {
    background: #EFEFEF;
}

.questionListQuestionTypeCircle {
    position: relative;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #57575780;
    background-color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;
}

.questionListItemText {
    flex: 1;
    min-width: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mainContainer {
    flex: 1;
    height: 100%;
    min-width: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
}


.mainContainerRow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;


    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}




.badge {
    width: 110px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;


    white-space: nowrap;
    color: #344054;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.expandIcon {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 #00000040;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.FITBInputBox {
    width: 170px;
    padding: 6px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000040;

    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    cursor: not-allowed;
}

.label {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #000000;
}

.yourResponseContainer {
    flex-shrink: 0;
    width: 100%;
    min-height: 60px;
    padding: 5px 20px;
    background: #F4F1FF;
    border: 1px solid #DAD0EA;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 #00000040;


    display: flex;
    align-items: center;
    gap: 10px;

    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;

}

.yourResponseContainer.incorrect{
    background: #F8DCDB;
    border: 1px solid #E3A7A533;
    box-shadow: 0 0 1px 0 #00000033;
}

.yourResponseContainer.correct{
    background: #DBE7E1;
    border: 1px solid #A1D2BA33;
    box-shadow: 0 0 1px 0 #00000033;
}


.optionIndex {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #DAD0EA;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 0 1px 0 #00000040;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    z-index: 1;
}


.indicator {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
}



.indicator.correct{
    background-color: var(--correct);
}
.indicator.incorrect{
    background-color: var(--incorrect);
}

.MHGridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    align-items: center;
    column-gap: 20px;
}

.MHVerticalContainer {
    width: 100%;
    display: grid;
    align-items: center;
    row-gap: 10px;
}



.MHPremise {
    width: 100%;
    min-height: 47px;
    padding: 5px 20px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000033;
    display: flex;
    align-items: center;

    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.MHDivider {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #D4D4D4;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chartContainer {
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: 0.5s ease;
}

.chartContainerBody {
    flex: 1 1;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.countBarContainer {
    flex-shrink: 0;
    position: relative;
    width: 100%;
    min-height: 60px;
    padding: 5px 20px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #00000033;

    display: flex;
    align-items: center;
    gap: 10px;

    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
}


.countBar {
    top: 0;
    left: 0;
    position: absolute;
    width: 0;
    height: 100%;
    background-color: #F3F3F3;
    border-radius: 4px;
    /*border-left: 1px solid #E6E6E6;*/
    /*border-top-left-radius: 4px;*/
    /*border-bottom-left-radius: 4px;*/
    transition: 1s ease;
    transition-delay: 0.5s;
}

.countBadge {
    flex-shrink: 0;
    width: 45px;
    height: 22px;
    border: 1px solid #BEBCD8;
    border-radius: 16px;
    background: #E4E3F6;
    box-shadow: 0 0 1px 0 #0000001A;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}

.correctBadge {
    flex-shrink: 0;
    width: 45px;
    height: 22px;
    border: 1px solid #68877880;
    border-radius: 16px;
    background: #DBE7E1;
    box-shadow: 0 0 1px 0 #0000001A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.totalCountBadge {
    flex-shrink: 0;
    width: fit-content;
    min-width: 34px;
    height: 22px;
    padding: 0 8px;
    border: 1px solid #D4D4D5;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}


.premiseChartContainer {
    width: 100%;
    padding: 15px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    transition: 0.5s ease;

    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.premiseChartContainerHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.premiseChartContainerBody {
    flex: 1;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.allResponseCard {
    flex-shrink: 0;
    width: 100%;
    min-height: 60px;
    padding: 15px;
    background: #F6F6F6E6;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 #00000040;


    display: flex;
    align-items: center;
    gap: 10px;

    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;

}