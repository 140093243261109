.tableContainer {
    justify-content: center;
    width: 100%;
    height: 50vh; 
    overflow-y: auto;
    border: none !important;
}

.tableContainer table {
        table-layout: fixed;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center !important;
    vertical-align: middle !important;
    border-spacing: 0 !important;
    border-collapse: separate !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
}

.tableContainer table th {
    vertical-align: middle !important;
    padding: 0 0 0 10px !important;
}

.tableContainer tr > th {
    background-color: transparent!important;
}


.tableContainer tr {
    /* border: 0.031rem solid rgba(208, 208, 208, 1)!important; */
}
.tableContainer table thead, tbody, tfoot {
    /* border-top: 0 !important; */
}

.tableContainer table > thead > tr > th{
    font-weight: 600 !important;
    font-size: 14px;
        padding: 0px;
    color: #000000;
}

.tableContainer .tableBtnUnit {
    line-height: 0 !important;
    padding: 0 !important;
}

.tableContainer .tableBtn {
    display: inline-block;
    width: 25px;
    height: 25px;
}

.tableContainer .tableBtn:hover {
    cursor: pointer;
}

.tableContainer .tableEditBtn {
    margin-right: 10px;
    background-image: url("../../images/pencil.svg");
}

.tableContainer .tableDeleteBtn {
    margin-right: 10px;
    background-image: url("../../images/trash.svg");
}

.tableContainer .tableMenuBtn {
    background-image: url("../../images/Menu.svg");
}


  .modalActions {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 75px; 
    width: 111px;
    z-index: 500;
    justify-content: center;
    color: #000;
  }
  
  .modalActionItem {
    cursor: pointer;
    height:40%; 
    display: flex; 
    font-size: 14px;
        color: #000000;
    text-align: left;
    align-items: center;
    padding-left: 10%;
  }

  .modalActionItem:hover {
    background-color: #f4f4f4;
  }
  
  .dropdownmenuopen {
    display: block;
    position: absolute;
    right: 35%; 
    background-color:  #FFFFFF;
    border-radius: 7px;
    padding: 8px;
    z-index: 500;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 0.5px 5px rgba(0, 0, 0, 0.12); 
  }

  .dropdownmenuopen:after {
        content: ''; 
        border-top: 1px solid #D0D0D0;
        border-right: 1px solid #D0D0D0;
        position: absolute;
        right: 16px;
        top: -8px;
        width: 15px;
        height: 15px;
        transform: rotate(-45deg);
        background-color: #FFFFFF;
  }

  
  .searchBox {
    font-family: SanFranciscoPro;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-bottom: 30px;
}

.searchBoxKeywordInput {
    height: 40px;
    width: 40%;
    padding-left: 37px !important;
    margin-right: 15px;
    background: url("../../images/zoom.svg") no-repeat left;
    background-position-x: 5px;
    border: 0.066rem solid rgba(208, 208, 208, 1) !important;
    font-size: 15px !important;
    background-size: 24px;
}

.searchBoxKeywordInput:focus, .searchBoxDateInput:focus {
    box-shadow: 0 0 0.3rem 0.006rem rgba(0, 107, 155, 1);
}

.searchBoxSearchBtn {
    margin: 0;
    border-radius: 0.198rem;
    padding: 0.563rem 0.625rem;
    border: 0.125rem solid rgba(0, 107, 155, 1);
    color: rgba(0, 107, 155, 1);
    font-size: 0.857rem;
    line-height: 0.857rem;
}

.searchBoxSearchBtn:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 107, 155, 1);
}

.searchBoxForm {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.tableNoPadding {
    padding: 0 !important; 
    background-color: white !important;
    height: 100%;
}

.selectedTimePeriod {
    padding: 6.5px 8.25px 6.5px 9px; 
    box-shadow: 0px 3px 0.75px 0px rgba(0, 0, 0, 0.04), 0px 2.25px 6px 0px rgba(0, 0, 0, 0.12); 
    background: #FFF; 
    border-radius: 5.25px; 
    font-weight: 700;
}

.notSelectedTimePeriod {
    color: #000;
    font-weight: 400; 
    
}

.skeletonTableContainer {
    width: 100% !important;
    /* height: 90% !important; */
    margin: 0px !important;
    border: 0.031rem solid rgba(208, 208, 208, 1) !important;
    border-radius: 10px 10px 0 0 !important;
    margin-top: 20px !important;
}

.skeletonHeaderContainer {
    /* margin: 10px 25px 0px 25px; */
    display: grid;
    grid-template-columns: 50% 25% 25%;
    height: 48px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
}

.skeletonHeaderContainer2 {
    /* margin: 10px 25px 0px 25px; */
    display: grid;
    grid-template-columns: 100%;
    height: 48px;
    border-bottom: 0.031rem solid rgba(208, 208, 208, 1) !important;
    padding-left: 10px;
}

.skeletonHeaderCell {
    display: flex !important;
    margin: auto !important;
    font-size: 14px;
    line-height: 14px;
        font-weight: 600;
    vertical-align: middle !important;
    margin: auto auto auto 0 !important;
}

.skeletonActionHeaderCell {
    display: flex !important;
        font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* height: 48px !important; */
    margin: auto auto !important;
}

.skeletonBodyContainer {
}

.skeletonBodyRow {
    /* margin: 0px !important; */
    display: grid;
    grid-template-columns: 50% 25% 25%;
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyRow2 {
    /* margin: 0px !important; */
    display: grid;
    grid-template-columns: 100%;
    border: 0.16px solid rgba(208, 208, 208, 0.25) !important;
    padding-left: 10px;
}

.skeletonBodyCellWrapper {
    display: block;
    line-height: 1;
    height: 52px;
    padding: 16px 0px;
}

.skeletonBodyCell {
    /* display: flex !important;
    margin: 15px auto 0px; */
}

.skeletonActionBodyCell {
    display: flex !important;
    margin: auto auto 0 auto !important;
}


.importQuestionsPreviewTable {
    display: grid;
    grid-template-columns: 75px 145px 1fr;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e0e0e0;
    border-bottom: none;
    font-size: 13px;
    color: #575757;
    max-height: min(50vh, 450px);
    overflow: auto;
}

.importQuestionsPreviewTable .cell {
    padding: 10px 10px;
    border-bottom: 1px solid #e0e0e0;
}

.importQuestionsPreviewTable .header {
    background-color: #F7F6FB;
    color: #000;
    font-weight: 500;
}