.row {
    margin: 1.0em;
}

.col {
    margin: 1.5em;
}

.containerClass {
    width: -webkit-fill-available;
    margin: 2.5em 2.5em;
    background-color: aliceblue;
}

.platformText {
    padding-top: 1px;
}

.cardHeader {
    margin-left: 0.5em;
    color: #000;
    font-family: SanFranciscoPro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.525px;
}

.labelDesc {
    color: #000;
    font-family: SanFranciscoPro;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    /*width: 150px;*/
    /*border: 1px solid #D0D0D0;*/
    border-radius: 3px;
    height: 37px;
    display: flex;
    align-items: center;
}

.lmsHeader {
    display: block;
    width: fit-content;
    margin: 0 auto;
        color: #00578C;
}

.smallBox {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.08);
    min-width: 400px!important;
    flex: 1;
}

.mediumBox {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.08);
    min-width: 500px!important;
    flex: 1;
}

.desc {
    font-size: 14px;
    padding-bottom: 5px;
}

.toggleRow {
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 40px;
}

.toggleTextRow {
    flex: 1;
    min-width: 0;
    font-size: 15px;
    font-weight: 700;
}

.toggleDescriptionText {
    font-weight: 400;
    color: #5c5c5c;
}

.toggleRow:nth-child(1) {
    margin-top: 0;
}